(function() {
	angular.module('student.test.reset').component('studentTestReset', {
		bindings: {
			student: '<',
			disabled: '<',
			test: '=',
			navGroupId: '@'
		},
		controller: 'StudentTestResetLinkController',
		templateUrl: 'features/growth/student.test.reset/link.template.html'
	});
})();
