(function() {
	angular.module('growth.grade.filter').service('GrowthGradeFilter', service);

	service.$inject = [
		'$q',
		'$cookieStore',
		'CookieNames',
		'DateRangeService',
		'ReportingAPI',
		'CurrentDate',
		'schoolYear',
		'NotificationFactory',
		'ApplicationUserService'
	];

	function service(
		$q,
		$cookieStore,
		CookieNames,
		DateRangeService,
		ReportingAPI,
		CurrentDate,
		schoolYear,
		NotificationFactory,
		ApplicationUserService
	) {
		var vm = this,
			currentUser = ApplicationUserService.getUser(),
			unLexileGrades = ['PreK', 'Kindergarten', 'First'],
			gradeMapper = {
				preK: 'PreK',
				kindergarten: 'Kindergarten',
				first: 'First',
				second: 'Second',
				third: 'Third',
				fourth: 'Fourth',
				fifth: 'Fifth',
				sixth: 'Sixth',
				seventh: 'Seventh',
				eighth: 'Eighth',
				ninth: 'Ninth',
				tenth: 'Tenth',
				eleventh: 'Eleventh',
				twelfth: 'Twelfth',
				other: 'Other'
			};

		vm.contexts = {
			lexile: 'lexile',
			benchmark: 'benchmark'
		};
		vm.types = {
			student: 'student',
			group: 'group',
			organization: 'organization'
		};
		vm.currentGrade = 'All';
		vm.grades = [];
		vm.error = undefined;
		vm.dateRange = undefined;

		vm.setGrades = setGrades;
		vm.getGrades = getGrades;
		vm.getCurrentGrade = getCurrentGrade;
		vm.setCurrentGrade = setCurrentGrade;
		vm.setContext = setContext;

		function getCookieName() {
			return [CookieNames.growth.filterGrade, currentUser.id].join('.');
		}

		function setContext(context) {
			vm.context = context;
		}

		function setGrades(grades) {
			vm.grades = grades;
		}

		function setCurrentGrade(grade) {
			vm.currentGrade = grade;
			$cookieStore.put(getCookieName(), grade);
		}

		function getCurrentGrade() {
			var grade = currentUser ? $cookieStore.get(getCookieName()) : vm.currentGrade || undefined;
			// var grade = vm.currentGrade || currentUser ? $cookieStore.get(getCookieName()) : undefined;
			if (!grade || !grade.length) {
				setCurrentGrade('All');
				grade = vm.currentGrade;
			}
			grade = vm.context === vm.contexts.lexile && grade && unLexileGrades.indexOf(grade) !== -1 ? 'All' : grade;
			setCurrentGrade(grade);
			return vm.currentGrade;
		}

		function getGrades(context, type, organizationId, modelId) {
			var deferred = $q.defer(),
				dataField;
			switch (context) {
				case vm.contexts.lexile:
					dataField = 'lexileGradeAvailability';
					break;
				case vm.contexts.benchmark:
					dataField = 'benchmarkTestsAvailability';
					break;
			}

			if (deferred && type && organizationId && modelId) {
				schoolYear
					.get(organizationId)
					.then(function(dates) {
						vm.dateRange = vm.dateRange || DateRangeService.yearToDate(dates);
						vm.dateRange.end =
							vm.dateRange.end && vm.dateRange.end.getTime() > CurrentDate.get().getTime()
								? CurrentDate.get()
								: vm.dateRange.end;
						var reportApi;
						if (type === vm.types.organization) {
							reportApi = ReportingAPI.organizations({
								search: modelId,
								startDate: vm.dateRange.start,
								endDate: vm.dateRange.end,
								dataFields: [dataField]
							});
						} else if (type === vm.types.group) {
							reportApi = ReportingAPI.groups({
								search: modelId,
								startDate: vm.dateRange.start,
								endDate: vm.dateRange.end,
								dataFields: [dataField]
							});
						}
						return reportApi;
					})
					.then(function(data) {
						var grades = [],
							gradeIndex,
							gradeData =
								data &&
								data.items &&
								data.items.length &&
								data.items[0][dataField] &&
								data.items[0][dataField].studentsAvailable
									? data.items[0][dataField].studentsAvailable
									: undefined,
							foundGrades = gradeData ? Object.keys(gradeData) : undefined;
						if (foundGrades && foundGrades.length) {
							if (foundGrades.length > 1) {
								grades.push('All');
							} else {
								setCurrentGrade(gradeMapper[foundGrades[0]]);
							}
							_.forEach(gradeMapper, function(key, value) {
								gradeIndex = foundGrades.indexOf(value);
								if (gradeIndex !== -1) {
									grades.push(gradeMapper[foundGrades[gradeIndex]]);
								}
							});

							if (vm.context === vm.contexts.lexile) {
								grades = _.filter(grades, function(o) {
									return unLexileGrades.indexOf(o) === -1;
								});
							}
							if (grades.length === 0) {
								deferred.resolve('no_grades_found');
							} else {
								deferred.resolve(grades);
							}
						} else {
							grades.push('All');
							deferred.resolve(grades);
						}
					})
					.catch(function(error) {
						vm.error = NotificationFactory.error(error);
					});
			} else {
				deferred.resolve([]);
			}
			return deferred.promise;
		}
	}
})();
