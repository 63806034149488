(function() {
	angular.module('ilu.link', []).directive('iluLink', directive);

	directive.$inject = ['ENV'];

	function directive(ENV) {
		return {
			restrict: 'EA',
			templateUrl: 'components/ilu.link/template.html',
			replace: true,
			link: function(scope, element, attrs) {
				scope.universityUrl = ENV.imagineLearningUniversityUrl;
			}
		};
	}
})();
