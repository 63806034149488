(function() {
	var root = this;
	var translations = {
		en: {
			progress_common: {
				curriculumArea: 'Curriculum Area',
				curriculumAreas: 'Curriculum Areas',
				literacy: 'Literacy',
				grammar: 'Grammar',
				oralVocabulary: 'Oral Vocabulary',

				progress: 'Progress',
				lessonsPassedByCurriculumArea: 'Lessons Passed By Curriculum Area',
				avgLessonsPassed: 'Avg Lessons Passed',
				avgLessonsCompleted: 'Avg Lessons Completed',
				avgLessonsSkipped: 'Avg Lessons Skipped',
				lessonsPassed: 'Lessons Passed',
				totalLessonsCompleted: 'Total Lessons Completed',
				totalLessonsSkipped: 'Total Lessons Skipped',

				blankStates: {
					programLevelGainsList: {
						title: 'No %s match report criteria',
						description: 'No %s match the report filter criteria. You can try adjusting the filters to update your results.'
					},

					lessonDetails: {
						title: 'No results for the report criteria',
						description:
							'The student has not completed any lessons for the report filter criteria. You can try adjusting the filters to update your results.'
					}
				},

				informationBars: {
					progressByLesson:
						'Progress by Lesson reports where students are in Literacy, Oral Vocabulary, and Grammar curriculum. It shows which lessons students have completed or tested out of, and which lessons students are working on or have not attempted since starting Imagine Language & Literacy.'
				},

				displayNames: {
					Literacy: 'Literacy',
					Reading: 'Literacy',
					Grammar: 'Grammar',
					Vocabulary: 'Oral Vocabulary'
				},

				vocabularyGradeEquivalents: {
					PreK: 'Basic',
					First: 'Beginning Academic',
					Second: 'Intermediate Academic',
					Third: 'Advanced Academic'
				},

				gradesToLevels: {
					Kindergarten: 'Level 1',
					First: 'Level 2',
					Second: 'Level 3',
					Third: 'Level 4'
				}
			}
		}
	};

	if (root.angular) {
		root.angular.module('progress.common.localization', ['utils.localization']).config(config);
	} else if (typeof module !== 'undefined' && module.exports) {
		// Node.js
		module.exports = translations;
	}

	config.$inject = ['LocalizeProvider'];

	function config(LocalizeProvider) {
		LocalizeProvider.translations(translations);
	}
})();
