(function() {
	var root = this;
	var translations = {
		en: {
			growth_common: {
				studentPlacementResetTooltip: 'Reset Student Placement',
				performanceLevels: {
					aboveLevel: 'Above Level',
					onLevel: 'On Level',
					oneBelowLevel: '1 Below Level',
					twoBelowLevel: '2+ Below Level',

					advancedLevel: 'Advanced',
					intermediateLevel: 'Intermediate',
					beginnerLevel: 'Beginner',
					basicLevel: 'Basic'
				},

				blankStates: {
					rollUpScaledScoreKpi: {
						title: 'No scaled score',
						description: 'Students have not encountered this assessment yet.'
					},

					studentScaledScoreKpi: {
						title: 'No scaled score',
						description: 'The student has not encountered this assessment yet.'
					},

					studentLexileKpi: {
						title: 'No lexile measure',
						description: 'The student has not encountered this assessment yet.'
					},

					studentLexileWidget: {
						title: 'No lexile measure',
						description: 'The student has not encountered this assessment yet.'
					},

					studentBenchmarkliteracyKpi: {
						title: 'No literacy score',
						description: 'The student has not encountered this test yet or has tested out of this section.'
					},

					studentBenchmarklanguageKpi: {
						title: 'No language score',
						description: 'The student has not encountered this test yet or has tested out of this section.'
					},

					groupBenchmarkliteracyKpi: {
						title: 'No literacy scores',
						description: 'Students have not encountered this test yet or have tested out of this section.'
					},

					groupBenchmarklanguageKpi: {
						title: 'No language score',
						description: 'The student has not encountered this test yet or has tested out of this section.'
					},

					benchmarkGainsGroupList: {
						title: 'No groups match report criteria',
						description: 'No groups match the report filter criteria, or no groups are directly assigned to this organization.'
					},

					benchmarkGainsGradeList: {
						title: 'No grades match report criteria',
						description: 'No grades match the report filter criteria. You can try adjusting the filters to update your results.'
					},

					benchmarkGainsOrganizationList: {
						title: 'No organizations match report criteria',
						description:
							'No organizations match the report filter criteria. You can try adjusting the filters to update your results.'
					},

					benchmarkPerformanceGradeList: {
						title: 'No grades match report criteria',
						description: 'No grades match the report filter criteria. You can try adjusting the filters to update your results.'
					},

					benchmarkPerformanceGroupList: {
						title: 'No groups match report criteria',
						description: 'No groups match the report filter criteria, or no groups are directly assigned to this organization.'
					},

					benchmarkPerformanceOrganizationList: {
						title: 'No organizations match report criteria',
						description:
							'No organizations match the report filter criteria. You can try adjusting the filters to update your results.'
					},

					benchmarkStatusGradeList: {
						title: 'No grades match report criteria',
						description: 'No grades match the report filter criteria. You can try adjusting the filters to update your results.'
					},

					benchmarkStatusGroupList: {
						title: 'No groups match report criteria',
						description: 'No groups match the report filter criteria, or no groups are directly assigned to this organization.'
					},

					benchmarkStatusOrganizationList: {
						title: 'No organizations match report criteria',
						description:
							'No organizations match the report filter criteria. You can try adjusting the filters to update your results.'
					},

					lexileGainsgradeList: {
						title: 'No grades match report criteria',
						description: 'No grades match the report filter criteria. You can try adjusting the filters to update your results.'
					},

					lexileGainsgroupList: {
						title: 'No groups match report criteria',
						description: 'No groups match the report filter criteria, or no groups are directly assigned to this organization.'
					},

					lexileGainsorganizationList: {
						title: 'No organizations match report criteria',
						description:
							'No organizations match the report filter criteria. You can try adjusting the filters to update your results.'
					},

					lexileStatusgradeList: {
						title: 'No grades match report criteria',
						description: 'No grades match the report filter criteria. You can try adjusting the filters to update your results.'
					},

					lexileStatusgroupList: {
						title: 'No groups match report criteria',
						description: 'No groups match the report filter criteria, or no groups are directly assigned to this organization.'
					},

					lexileStatusorganizationList: {
						title: 'This is the lexile status organization list blank state title',
						description: 'This is the lexile status organization list blank state description'
					},

					lexilePerformancegradeList: {
						title: 'No grades match report criteria',
						description: 'No grades match the report filter criteria. You can try adjusting the filters to update your results.'
					},

					lexilePerformancegroupList: {
						title: 'No groups match report criteria',
						description: 'No groups match the report filter criteria, or no groups are directly assigned to this organization.'
					},

					lexilePerformanceorganizationList: {
						title: 'No organizations match report criteria',
						description:
							'No organizations match the report filter criteria. You can try adjusting the filters to update your results.'
					}
				},

				benchmarkPerformanceDescriptions: {
					above: 'Student is performing above their grade level',
					onGradeLevel: 'Student is performing on grade level',
					oneBelow: 'Student is performing below their grade level',
					farBelow: 'Student is performing two or more grade levels below their grade level',
					noScore: 'Student has no score for this test'
				},

				lexilePerformanceDescriptions: {
					above: 'Student is performing above their grade level',
					onGradeLevel: 'Student is performing on grade level',
					oneBelow: 'Student is performing below their grade level',
					farBelow: 'Student is performing two or more grade levels below their grade level',
					noScore: 'Student has no score for this test'
				},

				lexileStatusDescription: '',
				lexilePerformanceDescription:
					'The Performance report shows which students are performing above, at, or below grade level, as assessed by the Reading Level Assessment. Students may receive the Reading Level Assessment three times a year to assess change in student reading levels.',
				lexileGainsDescription: '',

				benchmarkStatusDescription: '',
				benchmarkPerformanceDescription:
					'The Performance report shows which students are performing above, at, or below grade level, as assessed by the Benchmark Test. The Benchmark Test illustrates students’ growth in Literacy and Oral Vocabulary skills. Students receive the Benchmark Test three times during the school year to assess cumulative reading growth. See <strong>Next Steps</strong> below the data for suggestions on how to help students with lower performance.',
				benchmarkGainsDescription:
					'The Gains report shows student growth in Literacy and Oral Vocabulary skills by grade.  If enabled, students are tested at the beginning, middle and end of the school year. Select the test periods that have been completed to view the results (i.e. Beginning and Middle)',

				benchmarkStudentDescription:
					"This report overview shows which Benchmark tests the student has taken and their overall (YTD increase or decrease) and incremental growth in Literacy and Oral Vocabulary subtests. See <strong>Next Steps</strong> below the data to interpret your student's scores by grade level and see how to help your student based on their test status.",

				notRLAQualified: 'Student has not yet qualified to take the Reading Level Assessment Test',

				statuses: {
					completed: 'Completed',
					started: 'In Progress',
					unknown: '--',
					testedOut: 'Tested Out',
					notStarted: 'Not Started',
					notReady: 'Not Ready',
					timedOut: 'Expired',
					expired: 'Expired',
					inProgress: 'In Progress'
				},

				lexileTests: {
					dates: {
						beginning: 'Aug 1 - Oct 31',
						middle: 'Dec 1 - Feb 28',
						end: 'Apr 1 - Jun 30'
					},
					names: {
						beginning: 'Beginning of Year Test',
						middle: 'Middle of Year Test',
						end: 'End of Year Test'
					}
				},

				growthTests: {
					dates: {
						beginning: 'Aug 1 - Oct 31',
						middle: 'Dec 1 - Feb 28',
						end: 'Apr 1 - Jun 30'
					},
					names: {
						beginning: 'Beginning of Year Test',
						middle: 'Middle of Year Test',
						end: 'End of Year Test'
					}
				},

				label: {
					percentile: 'Percentile',
					growthYtd: 'Growth YTD'
				},
				scaledScoreGrowth: 'Scaled Score',
				beginningScaledScore: 'Beginning Score',
				endingScaledScore: 'Ending Score',
				performanceLevel: 'SCALED SCORE',
				targetRange: 'Target Range',
				change: 'Change',
				ytdChange: 'Change YTD',
				avgChangeYtd: 'Avg Change YTD',
				studentGrowth: 'SCALED SCORE',
				achievements: 'Achievements',
				achievementsLastWeek: 'Achievements - Last Week',
				lexileScore: 'Lexile Measure',
				scaledScore: 'Scaled Score',
				avgStudentGrowth: 'Student Growth',
				score: 'Score',
				growth: 'Scaled Score',
				avgScore: 'Avg Ending Score',
				avgGrowth: 'Avg Change',
				avgYtdGrowth: 'Avg Change YTD',
				gradeEquivalent: 'Grade Equivalent',
				avgBeginning: 'Avg Beginning',
				avgEnding: 'Avg Ending',
				avgBeginningScore: 'Starting Scaled Score',
				avgEndingScore: 'Ending Scaled Score',
				beginningScore: 'Beginning Score',
				endingScore: 'Ending Score',
				gradeComparison: 'Grade Comparison',
				areas: {
					language: 'Oral Vocabulary',
					literacy: 'Literacy'
				},
				annualGrowthBarGraphDescription: 'Overall Benchmark Test score in %s areas',
				growthDescription: 'Change in scaled score since the beginning of the school year',
				lexileDescription: 'Lexile measures are provided by the Reading Level Assessment',
				averageGrowthDescription: 'Average change in scaled score since the beginning of the school year',
				labels: {
					oralVocabularySubTestDetail: 'ORAL VOCABULARY SUB-TEST DETAIL',
					literacySubTestDetail: 'LITERACY SUB-TEST DETAIL',
					benchmarkTestOralVocabulary: 'BENCHMARK TEST ORAL VOCABULARY',
					benchmarkTestLiteracy: 'BENCHMARK TEST LITERACY',

					subTest: 'Sub-test',
					score: 'Score',
					percentile: 'Percentile',
					percentage: 'Percentage',
					percentCorrect: 'Percent Correct'
				},
				assessments: {
					Boy: {
						labels: {
							annualGrowthTest: 'Beginning of Year Benchmark Test',
							testingDates: 'Testing Dates',
							readingLevel: 'Reading Level Assessment'
						},
						descriptions: {
							annualGrowthTest:
								"This test provides a baseline to show student growth in literacy and oral language skills. It is given to returning students, and may automatically adjust students' placement as needed. (The initial placement test serves as the baseline for students starting the program.) It can be used with the Middle of Year test to compare beginning and end of school year scores. This test can be enabled any time, but can only be administered once each year during the testing dates. By default, it is disabled for all students.",
							readingLevel:
								"This test provides a measurement of students' reading comprehension ability using the Lexile Framework for Reading. This test is given at the beginning, middle, and end of the school year to students in Grade 2 and above who have completed the Imagine Learning Beginning Books curriculum. By default, this test is disabled for all students for the current school year."
						},
						dates: {
							range1: 'Aug 1 - Oct 31',
							range2: 'Dec 1 - Feb 28',
							range3: 'Apr 1 - Jun 30'
						}
					},
					Moy: {
						labels: {
							annualGrowthTest: 'Middle of Year Benchmark Test',
							testingDates: 'Testing Dates',
							readingLevel: 'Reading Level Assessment'
						},
						descriptions: {
							annualGrowthTest:
								"This test works with the Beginning of Year Benchmark Test to show growth in students' literacy and oral language skills. The test can be enabled at any time, but can only be administered once each school year during the testing dates. By default, it is disabled for all students.",
							readingLevel:
								"This test provides a measurement of students' reading comprehension ability using the Lexile Framework for Reading. This test is given at the beginning, middle, and end of the school year to students in Grade 2 and above who have completed the Imagine Learning Beginning Books curriculum. By default, this test is disabled for all students for the current school year."
						},
						dates: {
							range1: 'Jan 1 - Jun 30',
							range2: 'Dec 1 - Feb 28',
							range3: 'Apr 1 - Jun 30'
						}
					},
					Eoy: {
						labels: {
							annualGrowthTest: 'End of Year Benchmark Test',
							testingDates: 'Testing Dates',
							readingLevel: 'Reading Level Assessment'
						},
						descriptions: {
							annualGrowthTest: '...... NEEEDDDDDDDDDDDDDDDDDSSSSSSSSSSS TEXT HERE ................................',
							readingLevel:
								"This test provides a measurement of students' reading comprehension ability using the Lexile Framework for Reading. This test is given at the beginning, middle, and end of the school year to students in Grade 2 and above who have completed the Imagine Learning Beginning Books curriculum. By default, this test is disabled for all students for the current school year."
						},
						dates: {
							range1: 'Aug 1 - Oct 31',
							range2: 'Dec 1 - Feb 28',
							range3: 'Apr 1 - Jun 30'
						}
					},
					labels: {
						annualGrowthTest: 'Beginning of Year Benchmark Test',
						testingDates: 'Testing Dates',
						readingLevel: 'Reading Level Assessment',
						benchmark: 'Benchmarks',
						beginning: 'Beginning of Year Test',
						middle: 'Middle of Year Test',
						end: 'End of Year Test',
						//beginning: 'Beginning',
						//middle: 'Middle',
						//end: 'End',
						lit_vocab: 'Literacy | Oral Vocab'
					},
					descriptions: {
						alwaysEnabled: 'This test is always enabled as part of the Imagine Language & Literacy program.',
						annualGrowthTest:
							'This test provides a baseline for comparison to show growth in student literacy and oral language skills. It is given to returning students, and may automatically adjust student placement as needed. The initial placement test serves as the baseline for students starting the program. This test can be enabled any time, but can only be administered once each year during the testing dates.',
						readingLevel:
							'This test provides a measurement of students reading comprehension ability using the Lexile Framework for Reading. This test is given at the beginning, middle, and end of the school year to students in Grade 2 and above who have completed the Imagine Learning Beginning Books curriculum. By default, this test is disabled for all students for the current school year.'
					},
					dates: {
						range1: 'Aug 1 - Oct 31',
						range2: 'Dec 1 - Feb 28',
						range3: 'Apr 1 - Jun 30',
						beginning: 'Aug 1 - Oct 31',
						middle: 'Dec 1 - Feb 28',
						end: 'Apr 1 - Jun 30'
					}
				},
				galileo: {
					learnMoreLink: 'Learn more',
					goToGalileoButton: 'Go to Imagine Assessment: Galileo',
					goToNWEAButton: ' Go to NWEA',
					galileoLink: 'Imagine Assessment: Galileo',
					nweaLink: 'NWEA'
				},
				assessmentProvider: {
					title: '<b>Your assessment provider:<b>',
					ATI: {
						name: 'Imagine Assessment: Galileo',
						text:
							'Your organization is using <b>Imagine Assessment: Galileo</b> to place students in Imagine Language & Literacy. To view growth and assessment data, visit Imagine Assessment: Galileo.'
					},
					NWEA: {
						name: 'NWEA MAP',
						text:
							'Your organization is using <b>NWEA MAP</b> to place students in Imagine Language & Literacy. To view growth and assessment data, visit NWEA.'
					},
					ILE: {
						name: 'Imagine Language & Literacy Embedded Benchmark',
						text:
							'Your organization is using the <b>Imagine Language & Literacy Embedded Benchmark</b> to measure student growth.'
					}
				},
				resetPlacement: {
					buttonTooltip: {
						done:
							'You have already reassigned the Placement Test for this student. Contact Imagine Learning Customer Care if you need to reset the test again.',
						active: 'Reset Placement Test for %1$s, %2$s.'
					},
					modal: {
						title: '<h4>Reassign %1$s for %2$s, %3$s?</h4>',
						content: `<h5>Reassigning the Placement Test will:</h5>
						<ul>
							<li>
								Invalidate the current test score for this test window.
							</li>
							<li>
								Remove related test data from reports.
							</li> 
							<li>
								Give the student the Placement Test the next time they log in.
							</li>
							<li>
								Restart the student’s Imagine Language & Literacy pathway.
							</li>
						</ul>`,
						button: 'Reassign'
					},
					confirmation: {
						title: 'Test Reassignment Successful',
						content:
							'You’ve successfully reassigned the student’s Placement Test. The student can retake the Placement Test the next time they log in. This action may take up to an hour to complete.',
						button: 'Got It'
					}
				},
				reassignBenchmark: {
					buttonTooltip: {
						done:
							'You have already reassigned the Benchmark Test for this student. Contact Imagine Learning Customer Care if you need to reassign the test again.',
						active: 'Reassign %1$s Benchmark for %2$s, %3$s.',
						windowClosed: 'Test window is closed.'
					},
					modal: {
						title: '<h4>Reassign %1$s for %2$s, %3$s?<h4>',
						content: `<h5>Reassigning the Benchmark Test will:</h5>
								<ul>
									<li>
										Invalidate the current test score for this test window.
									</li>
									<li>
										Remove related test data from reports.
									</li> 
									<li>
										Give the student the Benchmark Test the next time they log in.
									</li>
									<li>
										Preserve the student’s progress on their Imagine Language & Literacy pathway.
									</li>
								</ul>`,
						button: 'Reassign'
					},
					confirmation: {
						title: 'Test Reassignment Successful',
						content:
							'You’ve successfully reassigned the student’s Benchmark Test. The student can retake the Benchmark Test the next time they log in. This action may take up to an hour to complete.',
						button: 'Got It'
					}
				},
				resetNoResult: 'There is no test result saved yet for this student.',
				resetError: `Test reassignment can't be completed. Contact <a href="https://help.imaginelearning.com/hc/en-us/requests/new" target="_blank"> Imagine Learning Customer Care for more information. </a>`
			}
		}
	};

	if (root.angular) {
		root.angular.module('growth.common.localization', ['utils.localization']).config(config);
	} else if (typeof module !== 'undefined' && module.exports) {
		// Node.js
		module.exports = translations;
	}

	config.$inject = ['LocalizeProvider'];

	function config(LocalizeProvider) {
		LocalizeProvider.translations(translations);
	}
})();
