(function() {
	angular.module('ui.testStatuses').service('TestStatusService', service);

	service.$inject = ['$filter'];

	function service($filter) {
		var statuses = {
			completed: $filter('translate')('growth_common.statuses.completed'),
			Completed: $filter('translate')('growth_common.statuses.completed'),

			started: $filter('translate')('growth_common.statuses.started'),
			Started: $filter('translate')('growth_common.statuses.started'),

			unknown: $filter('translate')('growth_common.statuses.unknown'),
			Unknown: $filter('translate')('growth_common.statuses.unknown'),

			testedOut: $filter('translate')('growth_common.statuses.testedOut'),
			TestedOut: $filter('translate')('growth_common.statuses.testedOut'),

			notStarted: $filter('translate')('growth_common.statuses.notStarted'),
			NotStarted: $filter('translate')('growth_common.statuses.notStarted'),

			notReady: $filter('translate')('growth_common.statuses.notReady'),
			NotReady: $filter('translate')('growth_common.statuses.notReady'),

			timedOut: $filter('translate')('growth_common.statuses.timedOut'),
			TimedOut: $filter('translate')('growth_common.statuses.timedOut'),

			inProgress: $filter('translate')('growth_common.statuses.inProgress'),
			InProgress: $filter('translate')('growth_common.statuses.inProgress')
		};

		this.getStatuses = function() {
			return statuses;
		};

		this.getStatusText = function(status) {
			return statuses[status];
		};
	}
})();
