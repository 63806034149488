(function() {
	angular
		.module('manager.modal.factory', [
			'ngDialog',
			'forms.common.localization',
			'widgets.organization.settings',
			'widgets.group.settings',
			'widgets.user.settings',
			'widgets.student.settings'
		])
		.constant('ManagerModalFactoryTypes', {
			organization: 'organization',
			group: 'group',
			user: 'user',
			student: 'student'
		})
		.factory('ManagerModalFactory', factory);

	factory.$inject = ['ManagerModalFactoryTypes', 'ngDialog'];

	function factory(ManagerModalFactoryTypes, ngDialog) {
		var createFactory = {},
			flatTypes = _.keys(ManagerModalFactoryTypes);
		createFactory.edit = edit;
		createFactory.create = create;

		function edit(itemType, id, sections, callback) {
			if (itemType && id && itemType.length && id.length && _.indexOf(flatTypes, itemType) !== -1) {
				var templateUrl = 'components/manager.modals/templates/' + itemType + '.html';
				ngDialog.open({
					template: templateUrl,
					className: 'ngdialog-theme-default',
					data: {
						ngDialog: ngDialog,
						params: {
							id: id,
							mode: 'edit',
							callback: callback,
							sections: sections
						}
					}
				});
			}
		}

		function create(itemType, parentId, callback) {
			if (itemType && itemType.length && _.indexOf(flatTypes, itemType) !== -1) {
				var templateUrl = 'components/manager.modals/templates/' + itemType + '.html';
				ngDialog.open({
					template: templateUrl,
					className: 'ngdialog-theme-default',
					data: {
						ngDialog: ngDialog,
						params: {
							parentId: parentId,
							mode: 'create',
							callback: callback
						}
					}
				});
			}
		}

		return createFactory;
	}
})();
