(function() {
	angular
		.module('test.enablement.organization', [
			'ngDialog',
			'api.manager.bulk.operations',
			'ui.notifications',
			'utils.localization',
			'test.ranges',
			'api.manager',
			'paged.list',
			'ui.directives.selectBlur',
			'ui.popup',
			'ui.modal.custom',
			'utils.date',
			'utils.featureFlipper'
		])
		.constant('testTypes', {
			growth: 'growth',
			lexile: 'lexile'
		})
		.constant('testWindows', {
			beginning: 'beginning',
			middle: 'middle',
			end: 'end'
		})
		.constant('testSettingsFields', {
			beginning: 'SettingsBoy',
			middle: 'SettingsMoy',
			end: 'SettingsEoy'
		});
})();
