(function() {
	angular.module('application.header', [
		'portal.service',
		'api.manager',
		'product.names',
		'utils.localization',
		'utils.featureFlipper',
		'ui.notifications',
		'ui.router',
		'route.helpers',
		'terms.common.localization',
		'global.search',
		'user.account.menu',
		'api.application.user',
		'legacy.browser.modal'
	]);
})();
