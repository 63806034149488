(function() {
	angular.module('create.groups.modal').controller('CreateGroupsModalController', controller);

	controller.$inject = [
		'$rootScope',
		'$scope',
		'$q',
		'GUID',
		'ApplicationUserService',
		'Localize',
		'ManagerAPI',
		'ManagerBulkOperations',
		'NotificationFactory'
	];

	function controller(
		$rootScope,
		$scope,
		$q,
		GUID,
		ApplicationUserService,
		Localize,
		ManagerAPI,
		ManagerBulkOperations,
		NotificationFactory
	) {
		var ctrl = this;
		ctrl.user = ApplicationUserService.getUser();
		ctrl.titleText = $scope.ngDialogData.titleText || Localize.translateInstant('create_groups_modal.titleText');
		ctrl.organization = $scope.ngDialogData.organization;
		ctrl.callback = $scope.ngDialogData.callback;

		ctrl.groups = [];

		//
		ctrl.init = init;
		ctrl.isValid = isValid;
		ctrl.addNewGroup = addNewGroup;
		ctrl.deleteGroup = deleteGroup;
		ctrl.updateSchoolDetails = updateSchoolDetails;
		ctrl.createGroups = createGroups;
		ctrl.groupNameUnique = groupNameUnique;

		function groupNameUnique(group) {
			var existing = _.get(group, 'school.existingGroupNames', undefined),
				existingInList = _.filter(_.map(ctrl.groups, 'name'), function(n) {
					return n.length && n === group.name;
				});
			group.error =
				existingInList && existingInList.length > 1
					? Localize.translateInstant('create_groups_modal.groupExistsInListError')
					: existing && _.indexOf(existing, group.name) !== -1
					? Localize.translateInstant('create_groups_modal.groupExistsInOrganizationError')
					: undefined;
			return existingInList && existingInList.length > 1 ? false : existing ? _.indexOf(existing, group.name) === -1 : true;
		}

		function isValid() {
			var valid = true;
			_.forEach(ctrl.groups, function(group) {
				if (!group.name || !group.name.length || !groupNameUnique(group) || !group.school || !group.school.id) {
					valid = false;
				}
			});
			return valid;
		}

		function updateSchoolDetails(group) {
			group.users = [];
			group.userIds = [];
			group.schoolIds = [group.school.id];
			ManagerAPI.Organization.groupList(group.school.id).then(function(groups) {
				group.school.existingGroups = groups && groups.length ? groups : [];
				group.school.existingGroupNames = _(group.school.existingGroups)
					.map('name')
					.sort()
					.value();
			});
		}

		function addNewGroup() {
			var school =
				ctrl.school !== undefined
					? ctrl.school
					: ctrl.groups.length
					? _.get(ctrl.groups[ctrl.groups.length - 1], 'school')
					: undefined;

			var group = {
				name: '',
				school: school,
				users: [],
				userIds:
					ctrl.user.userRole.toLowerCase() === 'teacher' || ctrl.user.userRole.toLowerCase() === 'instructor'
						? [ctrl.user.id]
						: [],
				schoolIds: school ? [school.id] : []
			};
			ctrl.groups.push(group);
		}

		function deleteGroup(group) {
			var index = _.findIndex(ctrl.groups, function(g) {
				return g.name === group.name;
			});
			if (index !== -1) {
				ctrl.groups.splice(index, 1);
			}
		}

		function createGroups() {
			// manager api
			var groupPromises = [];

			_.forEach(ctrl.groups, function(group) {
				if (
					(ctrl.user.userRole.toLowerCase() === 'teacher' || ctrl.user.userRole.toLowerCase() === 'instructor') &&
					_.indexOf(group.userIds, ctrl.user.id) === -1
				) {
					group.userIds.push(ctrl.user.id);
				}
				groupPromises.push(
					ManagerAPI.Group.save({
						name: group.name,
						organizationId: group.school.id,
						userIds: group.userIds
					})
				);
			});

			if (!groupPromises.length) {
				return;
			}

			ctrl.busy = true;
			$q.all(groupPromises)
				.then(function(results) {
					var ids = _.map(results, 'id');
					return ManagerAPI.Group.classesById(ids, { additionalFields: ['users'] });
				})
				.then(function(groups) {
					ctrl.createdGroups = groups && groups.items ? groups.items : [];
					$rootScope.$broadcast('groupCreated', { count: ctrl.createGroups.length });
					var orgIds = _.map(ctrl.createdGroups, 'organizationId');
					return ManagerAPI.Organization.orgsById(orgIds);
				})
				.then(function(organizations) {
					_.forEach(ctrl.createdGroups, function(group) {
						group.organization = _.find(organizations && organizations.items ? organizations.items : [], function(
							organization
						) {
							return organization.id === group.organizationId;
						});
					});
				})
				.catch(function(error) {
					NotificationFactory.error(error);
				})
				.finally(function() {
					ctrl.busy = false;

					$scope.closeThisDialog();

					if (ctrl.callback) {
						var ids = _.map(ctrl.createdGroups, 'id');
						ctrl.callback({ ids: ids });
					}
				});

			// // bulk api
			// var newGroups = [];
			// _.forEach(ctrl.groups, function (group) {
			// 	newGroups.push({
			// 		Id: group.id,
			// 		name: group.name,
			// 		organizationId: group.school.id,
			// 		userIds: group.userIds
			// 	});
			// });
			//
			// ManagerBulkOperations.createOrUpdate.groups(newGroups).then(function (response) {
			// 	var ids = _.map(response.results, 'id');
			// 	return ManagerAPI.Group.classesById(ids, { additionalFields: ['users'] });
			// }).then(function (groups) {
			// 	ctrl.createdGroups = groups && groups.items ? groups.items : [];
			// 	var orgIds = _.map(ctrl.createdGroups, 'organizationId');
			// 	return ManagerAPI.Organization.orgsById(orgIds);
			// }).then(function (organizations) {
			// 	_.forEach(ctrl.createdGroups, function (group) {
			// 		group.organization = _.find(organizations && organizations.items ? organizations.items: [], function (organization) {
			// 			return organization.id === group.organizationId;
			// 		});
			// 	});
			// 	return ManagerBulkOperations.patchOrgsAndGroups.users(newGroups)
			// }).catch(function (error) {
			// 	NotificationFactory.error(error);
			// }).finally(function () {
			// 	ctrl.busy = false;
			// });
		}

		function init() {
			ctrl.busy = true;

			if (ctrl.organization && ctrl.organization.id) {
				if (ctrl.organization && ctrl.organization.organizationType !== 'School') {
					ManagerAPI.Organization.children(ctrl.organization.id, { limit: -1 })
						.then(function(results) {
							ctrl.schools =
								results && results.items && results.items.length
									? _.filter(results.items, function(organization) {
											return organization.organizationType.toLowerCase() === 'school';
									  })
									: [];
							ctrl.schools = _.orderBy(ctrl.schools, ['name', 'asc']);
							ctrl.school = ctrl.schools && ctrl.schools.length === 1 ? ctrl.schools[0] : undefined;
						})
						.finally(function() {
							addNewGroup();
							ctrl.busy = false;
						});
				} else {
					ManagerAPI.Organization.get(ctrl.organization.id)
						.then(function(result) {
							ctrl.school = result;
						})
						.finally(function() {
							addNewGroup();
							ctrl.busy = false;
						});
				}
			} else {
				ManagerAPI.Organization.quickList()
					.then(function(results) {
						ctrl.schools =
							results && results.length
								? _.filter(results, function(organization) {
										return organization.organizationType.toLowerCase() === 'school';
								  })
								: [];
						ctrl.schools = _.orderBy(ctrl.schools, ['name', 'asc']);
						ctrl.school = ctrl.schools && ctrl.schools.length === 1 ? ctrl.schools[0] : undefined;
					})
					.finally(function() {
						addNewGroup();
						ctrl.busy = false;
					});
			}
		}

		init();
	}
})();
