(function() {
	angular
		.module('information.bar')
		.controller('InformationBarController', controller)
		.component('informationBar', {
			bindings: {
				message: '='
			},
			controller: 'InformationBarController',
			templateUrl: 'components/information.bar/component.html'
		});

	controller.$inject = ['$sce'];

	function controller($sce) {
		var ctrl = this;
		ctrl.$onInit = onInit;
		ctrl.$onChanges = onChanges;

		function onChanges(changesObj) {
			if (changesObj.message && changesObj.message.currentValue) {
				onInit();
			}
		}

		function onInit() {
			ctrl.messageHTML = $sce.trustAsHtml(ctrl.message);
		}
	}
})();
