(function() {
	angular.module('create.users.modal').controller('CreateUsersModalController', controller);

	controller.$inject = [
		'$scope',
		'$q',
		'GUID',
		'Localize',
		'ManagerAPI',
		'ManagerBulkOperations',
		'NotificationFactory',
		'ApplicationUserService'
	];

	function controller($scope, $q, GUID, Localize, ManagerAPI, ManagerBulkOperations, NotificationFactory, ApplicationUserService) {
		let ctrl = this;
		uniqueFields = { USER_NAME: 'username' };
		ctrl.titleText = $scope.ngDialogData.titleText || Localize.translateInstant('create_users_modal.titleText');
		ctrl.organization = $scope.ngDialogData.organization;
		ctrl.callback = $scope.ngDialogData.callback;

		ctrl.users = [];

		//
		ctrl.init = init;
		ctrl.addNewUser = addNewUser;
		ctrl.deleteUser = deleteUser;
		ctrl.organizationsChanged = organizationsChanged;
		ctrl.createUsers = createUsers;
		ctrl.validateUsernames = validateUsernames;

		ctrl.isUniqInList = isUniqInList;

		ctrl.isUsernameAvailable = isUsernameAvailable;

		function validateUsernames() {
			var reg = /^[^@]+@[^@]+\.[^@]+$/;

			_.forEach(ctrl.users, function(user, index) {
				if (ctrl['create_user_form'] && ctrl['create_user_form'][`username_${index}`]) {
					delete user['username_error'];
					ctrl['create_user_form'][`username_${index}`].$setValidity('usernameUniq', true);
					if (user && user.username) {
						if (!user.username.match(reg)) {
							user['username_error'] = Localize.translateInstant('create_users_modal.invalidEmail');
							ctrl['create_user_form'][`username_${index}`].$setValidity('usernameUniq', false);
						} else if (!isUniqInList(user)) {
							user['username_error'] = Localize.translateInstant('create_users_modal.usernameExistsInListError');
							ctrl['create_user_form'][`username_${index}`].$setValidity('usernameUniq', false);
						} else {
							ctrl.busy = true;
							isUsernameAvailable(user.username)
								.then(function(result) {
									if (!result) {
										user[`username_error`] = Localize.translateInstant(
											`create_users_modal.usernameExistsInOrganizationError`
										);
										ctrl['create_user_form'][`username_${index}`].$setValidity('usernameUniq', false);
									} else {
										ctrl['create_user_form'][`username_${index}`].$setValidity('usernameUniq', true);
									}
								})
								.catch(function(error) {
									NotificationFactory.error(error);
								})
								.finally(function() {
									ctrl.busy = false;
								});
						}
					}
				}
			});
		}

		function isUniqInList(user) {
			var isUniqueInList = true;
			if (user.username && ctrl.users.length > 1) {
				isUniqueInList = !ctrl.users.some(function(item, i) {
					return (
						user.id &&
						user.id !== item.id &&
						user.username &&
						item.username &&
						user.username.toLowerCase() === item.username.toLowerCase()
					);
				});
			}
			return isUniqueInList;
		}

		function isUsernameAvailable(username) {
			return ManagerAPI.UsernameService.checkUserAvailability(username).then(function(result) {
				return result.usernames.indexOf(username.toLowerCase()) < 0;
			});
		}

		function organizationsChanged(user) {
			user.organizationIds = _.map(user.organizations, 'id') || [];

			if (user.groups && user.groups.length && user.organizationIds && user.organizationIds.length) {
				user.groups = _.filter(user.groups, function(group) {
					return _.indexOf(user.organizationIds, group.organizationId) !== -1;
				});
				user.groupIds = _.map(user.groups, 'id');
			} else {
				user.groups = [];
				user.groupIds = [];
			}
		}

		function addNewUser() {
			if (ctrl.users.length > 199) {
				return;
			}

			let organization =
				ctrl.organization !== undefined
					? ctrl.organization
					: ctrl.users.length
					? _.get(ctrl.users[ctrl.users.length - 1], 'organizations[0]')
					: undefined;
			let id = GUID.generate(),
				user = {
					id: id,
					firstName: '',
					lastName: '',
					username: '',
					userType: '',
					password: id,
					organizationIds: organization ? [organization.id] : [],
					groupIds: []
				};
			ctrl.users.push(user);
		}

		function deleteUser(user) {
			let index = _.findIndex(ctrl.users, function(g) {
				return g.id === user.id;
			});
			if (index !== -1) {
				ctrl.users.splice(index, 1);
			}

			validateUsernames();
		}

		function updateCreatedUserDetails(users) {
			ctrl.busy = true;
			let ids = _.map(users, 'id');
			return ManagerAPI.User.usersById(ids, { additionalFields: ['organizations', 'groups'] })
				.then(function(results) {
					ctrl.createdUsers = results && results.items ? results.items : [];

					let emailUsers = _.filter(
						_.map(angular.copy(ctrl.createdUsers), item => {
							item.organizations = _.filter(ctrl.organizations, organization => {
								return (
									organization.products &&
									organization.products.length &&
									_.indexOf(item.organizationIds, organization.id) !== -1
								);
							});
							return item;
						}),
						user => {
							return user.organizations && user.organizations.length;
						}
					);

					if (emailUsers && emailUsers.length) {
						ManagerAPI.User.sendSetupEmails(_.map(emailUsers, 'username'));
					}
				})
				.finally(function() {
					ctrl.busy = false;
				});
		}

		function updateFailedUserDetails(users) {
			let ids = _.map(users, 'id');

			ctrl.failedUsers = _.filter(ctrl.users, function(user) {
				return _.indexOf(ids, user.id) !== -1;
			});

			ctrl.failedUsers = _.map(ctrl.failedUsers, function(user) {
				let failedUser = _.find(users, function(failed) {
					return failed.id === user.id;
				});
				user.error = failedUser.error;
				user.status = failedUser.status;

				return user;
			});
		}

		function createUsers() {
			ctrl.busy = true;
			let newUsers = [];
			_.forEach(ctrl.users, function(user) {
				newUsers.push({
					Id: user.id,
					firstName: user.firstName,
					lastName: user.lastName,
					password: user.id,
					username: user.username,
					userType: user.userType,
					groupIds: user.groupIds,
					organizationIds: user.organizationIds
				});
			});

			ManagerBulkOperations.createOrUpdate
				.staff(newUsers)
				.then(function(response) {
					return response && response.results && response.results.length ? updateCreatedUserDetails(response.results) : $q.when();
				})
				.catch(function(response) {
					let bulkJobResult = _.get(response, 'data.results', undefined),
						created = [],
						failed = [];

					if (bulkJobResult) {
						_.forEach(bulkJobResult, function(result) {
							let status = _.get(result, 'status', undefined);
							if (status && status.match(/failed/i)) {
								failed.push(result);
							}
							if (status && status.match(/created/i)) {
								created.push(result);
							}
						});

						if (failed.length) {
							updateFailedUserDetails(failed);
						}

						if (created.length) {
							updateCreatedUserDetails(created);
						}
					} else {
						NotificationFactory.error(error);
					}
				})
				.finally(function() {
					ctrl.busy = false;

					if (ctrl.callback) {
						ctrl.callback();
					}
				});
		}

		function init() {
			ctrl.busy = true;

			if (ctrl.organization && ctrl.organization.id) {
				ManagerAPI.Organization.get(ctrl.organization.id, { additionalFields: ['products'] })
					.then(function(result) {
						ctrl.organizations = [result];
						ctrl.organization = ctrl.organizations[0];
					})
					.finally(function() {
						addNewUser();
						ctrl.busy = false;
					});
			} else {
				ManagerAPI.Organization.quickList(null, { additionalFields: ['products'] })
					.then(function(results) {
						ctrl.organizations = results && results.length ? _.orderBy(results, ['name', 'asc']) : [];
						ctrl.organization = ctrl.organizations && ctrl.organizations.length === 1 ? ctrl.organizations[0] : undefined;
					})
					.finally(function() {
						addNewUser();
						ctrl.busy = false;
					});
			}
		}

		init();
	}
})();
