(function() {
	angular.module('students.edit', [
		'ngDialog',
		'api.manager',
		'manager.modal.factory',
		'ui.notifications',
		'utils.gradeLevel',
		'utils.localization'
	]);
})();
