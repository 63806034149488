(function() {
	angular.module('donut.chart').component('donutChart', {
		bindings: {
			percent: '<',
			size: '@'
		},
		transclude: true,
		controller: 'DonutChartController',
		templateUrl: 'components/donut.chart/template.html'
	});
})();
