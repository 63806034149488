(function() {
	'use strict';

	angular.module('web.dropdownMenu').directive('ilDropdownMenuToggle', ilDropdownMenuToggle);

	ilDropdownMenuToggle.$inject = [];
	function ilDropdownMenuToggle() {
		// Usage:
		//
		// Creates:
		//
		var directive = {
			link: link,
			require: '^ilDropdownMenuContainer',
			restrict: 'A',
			scope: {
				id: '@ilDropdownMenuToggle'
			}
		};
		return directive;

		function link(scope, element, attrs, ctrl) {
			element.on('click', onClick);

			function onClick(event) {
				event.stopPropagation();
				if (attrs.hasOwnProperty('disabled') && attrs.disabled === true) {
					return;
				}
				scope.$apply(function() {
					ctrl.toggleVisible(scope.id);
				});
			}
		}
	}
})();
