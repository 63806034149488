(function() {
	var root = this;
	var translations = {
		en: {
			students_edit: {
				gradeLevel: 'Grade Level',
				sessionTime: 'Session Time',
				fields: 'Fields',
				update: 'Update',
				cancel: 'Done',
				selectedStudents: 'Selected Students',
				table: {
					columns: {
						name: 'Name',
						username: 'Username',
						sessionTime: 'Session Time',
						password: 'Password',
						gradeLevel: 'Grade Level',
						groups: 'Groups',
						assignedProducts: 'Assigned Products'
					}
				}
			}
		}
	};

	if (root.angular) {
		root.angular.module('students.edit').config(config);
	} else if (typeof module !== 'undefined' && module.exports) {
		module.exports = translations;
	}

	config.$inject = ['LocalizeProvider'];

	function config(LocalizeProvider) {
		LocalizeProvider.translations(translations);
	}
})();
