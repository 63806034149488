(function() {
	angular.module('internal.reports.modal', [
		'ngDialog',
		'ui.notifications',
		'api.application.user',
		'utils.date',
		'api.schoolYear',
		'api.reporting'
	]);
})();
