(function() {
	angular.module('test.window.notification').component('testWindowNotification', {
		bindings: {
			test: '@',
			entity: '='
		},
		controller: 'TestWindowNotificationCtrl',
		template: '<div class="test_window_notification" ng-class="{ \'active\': $ctrl.active === true }"></div>'
	});
})();
