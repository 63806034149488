(function() {
	var root = this;
	var translations = {
		en: {
			create_users_modal: {
				titleText: 'Create Staff',
				description: 'Create as many users as desired. You will have the chance to review your work before submitting.',
				usernameExistsInListError: 'A user with this name already exists in the list. Please choose another name.',
				usernameExistsInOrganizationError: 'A user with this name already exists. Please choose another name.',
				invalidEmail: 'Please enter a valid email address.',
				requiredField: 'This field is required',
				length1to64: 'This field must be between 3 and 64 characters in length.',
				length3to128: 'This field must be between 3 and 128 characters in length.',
				length4to256: 'This field must be between 4 and 256 characters in length.'
			}
		}
	};

	if (root.angular) {
		root.angular.module('create.users.modal').config(config);
	} else if (typeof module !== 'undefined' && module.exports) {
		module.exports = translations;
	}

	config.$inject = ['LocalizeProvider'];

	function config(LocalizeProvider) {
		LocalizeProvider.translations(translations);
	}
})();
