(function() {
	angular.module('unassigned.students.manager', [
		'utils.localization',
		'ui.notifications',
		'ui.manager.multiselect',
		'api.manager',
		'api.manager.bulk.operations',
		'api.reporting',
		'api.schoolYear',
		'api.application.user',
		'utils.date',
		'utils.timed_cache'
	]);
})();
