(function() {
	var root = this;
	var translations = {
		en: {
			search_common: {
				heading: 'Search Results',
				search: 'Search',
				back: 'Back',
				filter: 'Filter',
				orgs: 'Orgs',
				organizations: 'Organizations',
				groups: 'Groups',
				users: 'Users',
				staff: 'Staff',
				students: 'Students',
				activities: 'Activities',
				management: 'Management',
				portal: 'All Portal',
				allPortal: 'Organizations, Groups, Staff and Students',
				all: 'All',
				noResults: 'No results found.',
				showingNofNResultsFor: 'Showing <strong>%1$s</strong> of <strong>%2$s</strong> results for "%3$s"'
			}
		}
	};

	if (root.angular) {
		root.angular.module('search.common.localization', ['utils.localization']).config(config);
	} else if (typeof module !== 'undefined' && module.exports) {
		// Node.js
		module.exports = translations;
	}

	config.$inject = ['LocalizeProvider'];

	function config(LocalizeProvider) {
		LocalizeProvider.translations(translations);
	}
})();
