(function() {
	var root = this;
	var translations = {
		en: {
			user_account_menu: {
				myAccount: 'My Account',
				logOut: 'Log Out',
				legal: 'Privacy Policy',
				cancel: 'Cancel',
				save: 'Save',
				endImpersonation: 'Exit Assist Mode',

				changePassword: 'Change Password',
				changePasswordForUser: 'Change Password for User:',
				currentPassword: 'Current Password',
				newPassword: 'New Password',
				confirmPassword: 'Confirm New Password',

				resetPasswordSuccessHeader: 'User account updated.',
				resetPasswordSuccessContent: 'Password for user %s has been changed.',
				currentPassIncorrect: 'Current password is incorrect, please try again.',

				error: {
					invalidPassword: 'Your current password is incorrect.  Please try again.',
					currentPasswordRequired: 'Please enter your current password',
					passwordLength: 'Password must be at least 8 characters.',
					passwordMaxLength: 'Password may not be longer than 256 characters.',
					passwordRequired: 'Enter a password.',
					confirmPassword: 'Confirm the password.',
					passwordMatch: 'Passwords do not match.',
					passwordLengthConfirm: 'See password error.'
				}
			}
		}
	};

	if (root.angular) {
		root.angular.module('user.account.menu').config(config);
	} else if (typeof module !== 'undefined' && module.exports) {
		// Node.js
		module.exports = translations;
	}

	config.$inject = ['LocalizeProvider'];

	function config(LocalizeProvider) {
		LocalizeProvider.translations(translations);
	}
})();
