(function() {
	angular.module('date.range.drop.down').config(config);

	config.$inject = ['LocalizeProvider'];

	function config(LocalizeProvider) {
		LocalizeProvider.translations({
			en: {
				dateRangeDropDown: {
					start: 'Start',
					end: 'End',
					header: 'Select a date range.',
					cancel: 'Cancel',
					ok: 'OK',

					dateRanges: {
						lastFourWeeks: '4 Weeks',
						lastSixtyDays: 'Last 60 Days',
						yearToDate: 'Year To Date',
						addCustom: 'Custom',
						thisWeek: 'This Week',
						lastWeek: 'Last Week',
						last4Weeks: '4 Weeks',
						last8Weeks: '8 Weeks',
						// last30Days: 'Last 30 Days',
						// last60Days: 'Last 60 Days',
						yearToDate: 'Year To Date',
						lastYear: 'Last Year',
						custom: 'Custom'
					}
				}
			},

			sp: {
				dateRangeDropDown: {
					start: 'Comienzo',
					end: 'Fin',
					header: 'Seleccione un rango de fechas.',
					cancel: 'Cancelar',
					ok: 'OK',

					dateRanges: {
						lastFourWeeks: 'Últimas 4 semanas',
						lastSixtyDays: 'Últimos 60 días',
						yearToDate: 'El año hasta la fecha',
						addCustom: 'Personalizado',
						thisWeek: 'Esta semana',
						lastWeek: 'La semana pasada',
						last4Weeks: 'Últimas 4 semanas',
						last8Weeks: 'Últimas 8 semanas',
						// last30Days: 'Últimos 30 días',
						// last60Days: 'Últimos 60 días',
						yearToDate: 'El año hasta la fecha',
						lastYear: 'El año pasado',
						custom: 'Personalizado'
					}
				}
			}
		});
	}
})();
