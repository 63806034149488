(function() {
	var root = this;
	var translations = {
		en: {
			forms_common: {
				edit: 'Edit',
				create: 'Create',
				delete: 'Delete',
				save: 'Save',
				saveAll: 'Save All',
				cancel: 'Cancel',
				ok: 'OK',

				mediaServers: 'Media Servers',
				noMediaServers: 'No Media Servers have been configured for this organization.',
				syncedRecordFormWarning: {
					student: 'Locked fields on this record are controlled by your Student Information System.',
					group: 'This record is controlled by your Student Information System, and cannot be edited.',
					user: 'Locked fields on this record are controlled by your Student Information System.'
				},
				syncedField: 'This field is controlled by your Student Information System.',
				syncedGradeLevelField:
					'Some records in your selection are controlled by your Student Information System, and editing is restricted.  To Bulk Edit Grade Level, remove locked records from your selection.',
				deleteSyncedRecordsWarning:
					'Some records in your selection are controlled by your Student Information System and cannot be deleted. To complete this action, remove locked records from your selection.',
				removeGroupSyncedRecordsWarning:
					'Some records in your selection are controlled by your Student Information System and cannot be modified. To complete this action, remove locked records from your selection.',
				syncedRecord: 'This record is controlled by your Student Information System, and cannot be deleted.',
				syncedRecords:
					'Locked records in this list are controlled by your Student Information System. Editing is restricted, and locked records cannot be deleted.',

				teacher: 'Teacher',
				administrator: 'Administrator',
				selectGradeLevel: 'Select a Grade Level',
				details: 'Details',
				credentials: 'Credentials',
				organizations: 'Organizations',
				groups: 'Groups',
				users: 'Users',
				students: 'Students',
				schoolYear: 'School Year',
				usageRules: 'Usage Rules',
				usageGoal: 'Usage Goal',
				name: 'Name',
				firstName: 'First Name',
				lastName: 'Last Name',
				userName: 'Username',
				userNameEmail: 'Username (Email)',
				email: 'Email',
				password: 'Password',
				confirmPassword: 'Confirm Password',
				userType: 'User Type',
				accountType: 'Account Type',
				type: 'Type',
				organizationType: 'Type',
				siteCode: 'Site Code',
				tag: 'Student ID',
				alternateStudentNumber: 'Alternate ID',
				gradeLevel: 'Grade Level',
				sessionTime: 'Session Time',
				sessionTimeLL: 'Session Time: Language & Literacy',
				sessionTimeEsp: 'Session Time: Español',
				firstLanguage: 'First Language',
				language: 'Language',
				languageSupport: 'Language Support',
				languageSupportLL: 'Language Support: Language & Literacy',
				languageSupportEspanol: 'Language Support: Español',
				audioSupportReading: 'Audio Support: Reading',
				audioSupportLectura: 'Audio Support: Lectura',
				parentOrganization: 'Parent Organization',
				startDate: 'Start Date',
				endDate: 'End Date',
				startTime: 'Start Time',
				endTime: 'End Time',
				timeZone: 'Time Zone',
				days: 'Days',
				productSettings: 'Product Settings',
				demographics: 'Demographics',

				restoreDefaults: 'Restore Default Settings',
				studentTesting: 'Student Testing',
				staff: 'Staff',
				academics: 'Academics',
				assessments: 'Assessments',

				allowedDays: 'Allowed Days',
				allowedTimes: 'Allowed Times',

				error: {
					min: 'May not be less than %s.',
					max: 'May not be greater than %s.',
					invalid: 'Please enter a valid %s.',
					required: '%s is a required field.',
					maxLength: '%1$s may not be longer than %2$s characters.',
					minLength: '%1$s must be between %2$s and %3$s characters in length',
					daysRequired: 'Please select at least one day.',
					startDate: 'Session start date must be earlier than the end date.',
					endDate: 'Session end date must be later than the start date.',
					email: 'Please enter a valid email address.',
					wholeNumber: 'Must be a whole number.',
					duplicatedUsername: 'A user with this name already exists. Please choose another name'
				},

				labels: {
					timeZone: 'Time Zone',
					startDate: 'Start Date',
					endDate: 'End Date',
					startTime: 'Start Time',
					endTime: 'End Time',
					useInheritedSchoolYear: 'Use the School Year start/end dates from parent organization',
					useCustomSchoolYear: 'Enter custom School Year start/end dates'
				},

				messages: {
					studentAssessments: 'Manage which assessments are administered to this student throughout the school year.',
					selectTimeZone: 'Select a time zone',
					assessments: 'Manage which assessments are administered to your students throughout the school year.',
					sessionDescription: 'Set the date range that reports will use when pulling data for the school year.',
					usageRules: 'Set the days of the week and times of the day when students can access the Imagine Learning app.',
					updatedHeader: 'Organization updated.',
					updatedMessage: ' has been updated.',
					teacherRemoveFromGroupWarning:
						'Removing yourself as an associated staff member of this group will remove it from your list of groups as well.',
					schoolYearSettingsMessage:
						'The School Year start and end dates determine the data that will display in your reports. You can inherit the School Year setting from your parent organization, or you can enter custom School Year start and end dates. If you change the School Year setting, it may take up to 24 hours to populate your reports with the correct data.'
				}
			}
		}
	};

	if (root.angular) {
		root.angular.module('forms.common.localization', ['utils.localization']).config(config);
	} else if (typeof module !== 'undefined' && module.exports) {
		// Node.js
		module.exports = translations;
	}

	config.$inject = ['LocalizeProvider'];

	function config(LocalizeProvider) {
		LocalizeProvider.translations(translations);
	}
})();
