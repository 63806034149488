(function() {
	var root = this;
	var translations = {
		en: {
			usage_common: {
				blankStates: {
					noUsageResults: {
						title: 'No results for the report criteria',
						description: 'There has been no usage during the range requested.'
					}
				},

				usageReport: {
					message:
						'Reports for large organizations or groups may take ' +
						'a few minutes to generate.<br /> If your web browser uses an ' +
						'ad blocker you will need to disable it and enable pop-ups to ' +
						'download your PDF file.'
				},

				usage: 'Usage',
				usageLast4Weeks: 'Usage - 4 Weeks',
				usageLastWeek: 'Usage - Last Week',
				percentageOfGoal: '%s of weekly goal',
				weeklyUsage: 'PRODUCT USAGE',
				totalYTD: 'Total YTD',
				averageTotalYTD: 'Avg Total YTD',
				minutes: 'minutes',
				today: 'Today',
				thisWeek: 'This Week',
				avgStudentUsageLast4Weeks: 'Average student usage for the last 4 weeks',
				studentUsageLast4Weeks: 'Student usage for the last 4 weeks',
				avgStudentUsageLastWeek: 'Average student usage for the last week',
				studentUsageLastWeek: 'Student usage for the last week',
				weeklyGoal: 'Weekly Goal',
				ytd: 'YTD',
				threeWeekTrend: 'AVERAGE STUDENT USAGE',
				setRate: 'Calculate end of year prediction using data from the selected time period',
				studentUsageDetails: 'Student Usage Details',

				editGoal: {
					customButton: 'Custom',
					recommendedButton: 'Default',
					inheritedGoal: '* Goal inherited from %s',
					minutesPerStudent: 'Minutes per student',
					error: {
						invalid: 'Please enter a whole number for the usage goal.',
						min: 'Please enter a number higher than zero for the usage goal.',
						max: "There aren't enough minutes in a week to set this usage goal. Please enter a smaller number of minutes.",
						number: 'Please enter a number for the usage goal.',
						required: 'Please enter a number for the usage goal.'
					}
				}
			},

			usage_graph: {
				averageUsage: 'USAGE',
				averageMinutes: 'Average Student Usage',
				averageMinutesStudent: 'Student Usage',
				studentUsage: 'USAGE',
				weeklyGoal: 'Weekly Goal',
				predictedUsage: 'Predicted Usage',
				cumulativeUsage: 'Cumulative Usage',
				percentOfGoal: 'Percent of goal',
				descriptions: {
					organizationUsageKPI: 'Average student usage across all organizations',
					averageUsageKPI: 'Average student usage for the last 4 weeks',
					studentUsageKPI: 'Student usage for the last 4 weeks'
				}
			}
		}
	};

	if (root.angular) {
		root.angular.module('usage.common.localization', ['utils.localization']).config(config);
	} else if (typeof module !== 'undefined' && module.exports) {
		// Node.js
		module.exports = translations;
	}

	config.$inject = ['LocalizeProvider'];

	function config(LocalizeProvider) {
		LocalizeProvider.translations(translations);
	}
})();
