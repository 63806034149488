(function() {
	angular
		.module('date.range.drop.down', ['date.range.service', 'date.range.picker', 'utils.date', 'utils.localization'])
		.directive('dateRangeDropDown', directive)
		.controller('DateRangeDropDownController', controller);

	directive.$inject = ['$timeout'];

	function directive($timeout) {
		return {
			restrict: 'A',
			templateUrl: 'components/date.range.drop.down/date.range.drop.down.html',
			replace: true,
			controller: 'DateRangeDropDownController',
			controllerAs: 'model',
			bindToController: true,
			require: 'ngModel',
			scope: {
				ngModel: '=',
				session: '=',
				disabled: '=',
				default: '=',
				language: '=?'
				// hideLastYear: '=?'
			},
			link: function(scope, element, attrs, ngModelCtrl) {
				scope.model.hideLastYear = 'hideLastYear' in attrs;
				scope.language = scope.language || 'en';

				$timeout(function() {
					ngModelCtrl.$setPristine();
				}, 0);
			}
		};
	}

	controller.$inject = ['$scope', 'DateRangePicker', 'DateRangeService', '$element', '$timeout'];

	function controller($scope, DateRangePicker, DateRangeService, $element, $timeout) {
		var model = this,
			ngModelCtrl = $element.controller('ngModel');
		model.dateRanges = [];
		model.dateRange = {};

		//
		model.showPicker = showPicker;
		model.addCustomDateRange = addCustomDateRange;

		ngModelCtrl.$render = function() {
			model.dateRange = ngModelCtrl.$viewValue;
		};

		$scope.$watch(
			function() {
				return model.dateRange;
			},
			function(value, lastValue) {
				if (value && value.start === null) {
					model.showPicker();
				} else {
					var range = DateRangeService.findRange(value.start, value.end);
					if (!range) {
						$timeout(function() {
							model.addCustomDateRange(value);
						});
					} else {
						if (value && !angular.equals(range, ngModelCtrl.$viewValue)) {
							model.dateRange = range;
							ngModelCtrl.$setViewValue(range);
						}
					}
				}
			},
			true
		);

		$scope.$watch(
			function() {
				return model.session;
			},
			function(value, lastValue) {
				// we have a valid session
				if (value && value.start) {
					DateRangeService.setSession(value);
					model.dateRanges = DateRangeService.ranges;
				}
				// else {
				// model.dateRange = model.dateRange ? DateRangeService.findRange(model.dateRange.start, model.dateRange.end) : model.dateRanges[0];
				// model.dateRange = model.dateRange || model.dateRanges[0];
				// }
			},
			true
		);

		function addCustomDateRange(range) {
			if (!range) {
				return;
			}

			range.translationId = range.translationId || 'custom';
			let newRange = DateRangeService.getDateRange(range.translationId, model.session, range.start, range.end);
			let index = _.findIndex(DateRangeService.ranges, dateRange => {
				return newRange.translationId !== 'custom' && newRange.translationId === dateRange.translationId;
			});
			// custom range is new
			if (index === -1) {
				DateRangeService.ranges.unshift(newRange);
				model.dateRanges = DateRangeService.ranges;
				model.dateRange = model.dateRanges[0];
			}
			// custom range matches an existing default range
			else {
				model.dateRange = model.dateRanges[index];
			}
		}

		function showPicker() {
			DateRangePicker.show(addCustomDateRange);
		}
	}
})();
