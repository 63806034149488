(function() {
	var root = this;
	var translations = {
		en: {
			assign_product_modal: {
				titleText: 'Assign Product Access'
			}
		}
	};

	if (root.angular) {
		root.angular.module('assign.product.modal').config(config);
	} else if (typeof module !== 'undefined' && module.exports) {
		module.exports = translations;
	}

	config.$inject = ['LocalizeProvider'];

	function config(LocalizeProvider) {
		LocalizeProvider.translations(translations);
	}
})();
