(function() {
	angular.module('usage.helper.service', []).service('UsageHelpers', service);

	service.$inject = [];

	function service() {
		var svc = this;

		var usageMetrics = {
				dailyUsage: 'dailyUsage',
				weeklyUsage: 'weeklyUsage'
			},
			reportDataTypes = {
				dailyUsageDays: 'usage.dailyUsage.days',
				weeklyUsageWeeks: 'usage.weeklyUsage.weeks',
				dailyUsageGoals: 'usage.dailyUsage.dailyGoals',
				weeklyUsageGoals: 'usage.weeklyUsage.weeklyGoals',
				dailyUsageSum: 'usage.dailyUsage.rangeSecondsSum',
				weeklyUsageSum: 'usage.weeklyUsage.rangeSecondsSum',
				rangeUsageAverageSeconds: 'usage.rangeUsage.averageSeconds'
			};

		// methods
		svc.getUsageDataInstanceCount = getUsageDataInstanceCount;
		svc.getAverageUsageGoal = getAverageUsageGoal;
		svc.getAverageUsageMinutes = getAverageUsageMinutes;
		svc.getRangeUsageAverageMinutes = getRangeUsageAverageMinutes;
		svc.getRangeUsageGoal = getRangeUsageGoal;

		svc.getStudentUsageMinutes = getStudentUsageMinutes;
		svc.getStudentAverageUsageMinutes = getStudentAverageUsageMinutes;

		function getUsageDataInstanceCount(item, usageMetric) {
			var tmp =
				usageMetric === usageMetrics.dailyUsage
					? _.get(item, reportDataTypes.dailyUsageDays, [])
					: _.get(item, reportDataTypes.weeklyUsageWeeks, []);
			return tmp.length;
		}

		function getAverageUsageGoal(item, usageMetric) {
			var tmp,
				values,
				averagegoal = 0;
			tmp =
				usageMetric === usageMetrics.dailyUsage
					? _.get(item, reportDataTypes.dailyUsageGoals)
					: _.get(item, reportDataTypes.weeklyUsageGoals);

			if (tmp) {
				values = _.map(tmp, 'goal');
				averagegoal = _.last(values) || 0;
			}
			return isNaN(averagegoal) ? 0 : averagegoal;
		}

		function getAverageUsageMinutes(item, usageMetric, reportDivisor) {
			var averageMinutes = 0,
				sum =
					usageMetric === usageMetrics.dailyUsage
						? _.get(item, reportDataTypes.dailyUsageSum)
						: _.get(item, reportDataTypes.weeklyUsageSum);

			if (sum && item.totalActiveStudents && reportDivisor) {
				averageMinutes = sum / item.totalActiveStudents / 60 / reportDivisor;
			}
			return Math.round(averageMinutes);
		}

		function getRangeUsageAverageMinutes(item, reportDivisor) {
			var averageMinutes = 0,
				sum = _.get(item, reportDataTypes.rangeUsageAverageSeconds, 0);

			if (sum && reportDivisor) {
				averageMinutes = sum / 60 / reportDivisor;
			}
			return Math.round(averageMinutes);
		}

		function getRangeUsageGoal(rangeUsage, dailyUsage) {
			return dailyUsage ? (rangeUsage.currentGoal ? rangeUsage.currentGoal / 5 : 0) : rangeUsage.currentGoal || 0;
		}

		// student
		function getStudentUsageMinutes(student, usageMetric) {
			var tmp,
				values,
				sum,
				minutes = 0;
			tmp =
				usageMetric === usageMetrics.dailyUsage
					? _.get(student, reportDataTypes.dailyUsageDays)
					: _.get(student, reportDataTypes.weeklyUsageWeeks);

			if (tmp) {
				values = _.map(tmp, 'seconds');
				sum = _.sum(values);
				minutes = sum ? sum / 60 : 0;
			}
			return minutes;
		}

		function getStudentAverageUsageMinutes(student, usageMetric, reportDivisor) {
			var averageMinutes = 0,
				sum =
					usageMetric === usageMetrics.dailyUsage
						? _.get(student, reportDataTypes.dailyUsageSum)
						: _.get(student, reportDataTypes.weeklyUsageSum);

			if (sum && reportDivisor) {
				averageMinutes = sum / 60 / reportDivisor;
			}
			return Math.round(averageMinutes);
		}
	}
})();
