(function() {
	angular.module('icon.eye.toggle', []).directive('iconEyeToggle', directive);

	function directive() {
		return {
			restrict: 'A',
			scope: {
				value: '=iconEyeToggle'
			},
			link: function(scope, element) {
				scope.$watch(
					'value',
					function(val) {
						element.toggleClass('icon-eye', !val);
						element.toggleClass('icon-eye-with-line', val);
					},
					true
				);
			}
		};
	}
})();
