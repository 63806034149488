(function() {
	var root = this;
	var translations = {
		en: {
			application_header: {
				auditHistory: 'Audit History',
				home: 'Home',
				actionAreas: 'Action Areas',
				resources: 'Resources',
				activityExplorer: 'Activity Explorer',
				teacherResources: 'Teacher Resources',
				originalReports: 'Original Reports',
				usage: 'Usage',
				progress: 'Progress',
				growth: 'Growth',
				portfolio: 'Portfolio',
				management: 'Management',
				manager: 'Management',
				teachersManager: 'Students & Groups',
				ilEmployeePortal: 'IL Admin Portal',

				unsupportedPlatform: 'Unsupported Platform',
				unsupportedPlatformMessage:
					'<span class="chrome-message">Activity Explorer is not supported on this platform or browser. Alternatively, you can use Teacher Resources to find information about and launch activities.<br/> <br/> <a href="/resources_esp/home" target="_blank"><b class="">Go to Teacher Resources</b></a></span>'
			}
		}
	};

	if (root.angular) {
		root.angular.module('application.header').config(config);
	} else if (typeof module !== 'undefined' && module.exports) {
		// Node.js
		module.exports = translations;
	}

	config.$inject = ['LocalizeProvider'];

	function config(LocalizeProvider) {
		LocalizeProvider.translations(translations);
	}
})();
