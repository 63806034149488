(function() {
	angular.module('il.web.features', [
		'growth.grade.filter',
		'growth.qualifier.filter',
		'growth.view.filter',
		'student.test.reset',
		'feature.progress',
		'usage.helper.service',
		'feature.unassigned.students'
	]);
})();
