/*
Outlook-esque user initials in a colored circle
 */
(function() {
	angular.module('user.monogram', []).directive('userMonogram', directive);

	function directive() {
		return {
			restrict: 'EA',
			templateUrl: 'components/user.monogram/template.html',
			scope: {
				user: '=userMonogram',
				width: '=',
				height: '=',
				color: '@',
				textColor: '@'
			},
			link: function(scope, element, attrs) {
				scope.color = scope.color || '#472EC1';
				scope.textColor = scope.textColor || '#ffffff';

				scope.$watch(
					'user',
					function(value, last) {
						if (value && value.firstName && value.lastName) {
							scope.initials = _.toUpper(value.firstName[0] + value.lastName[0]);
						}
					},
					true
				);
			}
		};
	}
})();
