(function() {
	angular.module('create.student.choice.modal').controller('CreateStudentChoiceModalController', controller);

	controller.$inject = ['$scope'];

	function controller($scope) {
		var ctrl = this;
		ctrl.importWizardUrl = $scope.ngDialogData.importWizardUrl;
	}
})();
