(function() {
	angular.module('student.cards').service('StudentCardsService', service);

	service.$inject = [
		'$rootScope',
		'$timeout',
		'$q',
		'$window',
		'$templateRequest',
		'Organization',
		'NotificationFactory',
		'QRCode',
		'Class',
		'$compile'
	];

	function service($rootScope, $timeout, $q, $window, $templateRequest, Organization, NotificationFactory, QRCode, Class, $compile) {
		var svc = this;

		svc.students = [];
		svc.setStudents = setStudents;
		svc.getStudents = getStudents;
		svc.print = print;
		svc.printStudentCards = printStudentCards;
		svc.printStudentCardsFromGroups = printStudentCardsFromGroups;
		svc._generateQrcode = _generateQrcode;
		svc._loadStudentsSiteCodes = _loadStudentsSiteCodes;
		svc._buildHtml = _buildHtml;
		svc._loadStudentsFromGroups = _loadStudentsFromGroups;
		svc._loadIframe = _loadIframe;

		function printStudentCards(students) {
			svc.setStudents(students);
			svc.print();
		}

		function printStudentCardsFromGroups(groups) {
			svc.print(groups);
		}

		function setStudents(students) {
			svc.students = students;
		}

		function getStudents() {
			return svc.students;
		}

		function print(groups) {
			svc._loadStudentsFromGroups(groups)
				.then(function() {
					return svc._loadStudentsSiteCodes();
				})
				.then(function() {
					return svc._loadIframe();
				})
				.then(function(response) {
					// console.log(['PDF Success', response]);
				})
				.catch(function(error) {
					NotificationFactory.error(error);
				});
		}

		function _loadIframe() {
			var studentList = '<student-cards-component students="$ctrl.students"></student-cards-component>',
				scope = $rootScope.$new(),
				compiledHtml = $compile(studentList)(scope);

			var newIframe = document.createElement('iframe');
			newIframe.src = 'about:blank';
			newIframe.id = 'student-login-cards-iframe';
			document.body.appendChild(newIframe);

			newIframe.contentWindow.document.open('text/htmlreplace');
			newIframe.contentWindow.document.write('Generating student login cards...');
			newIframe.contentWindow.document.close();

			$timeout(function() {
				var htmlStr = svc._buildHtml(angular.element(compiledHtml).html());
				newIframe.contentWindow.document.open('text/htmlreplace');
				newIframe.contentWindow.document.write(htmlStr);
				newIframe.contentWindow.print();
				newIframe.contentWindow.document.close();
				document.getElementById('student-login-cards-iframe').remove();
			}, 1500);
		}

		function _loadStudentsFromGroups(groups) {
			var deferred = $q.defer();

			if (!groups || !groups.length) {
				deferred.resolve();
				return deferred.promise;
			}

			var groupIds = _.map(groups, function(group) {
				if (group.studentCount > 0) {
					return group.id;
				}
			});

			var studentsFromGroupRequests = [];
			angular.forEach(groupIds, function(groupId) {
				if (groupId) {
					studentsFromGroupRequests.push(Class.students(groupId, { limit: '-1' }));
				}
			});
			$q.all(studentsFromGroupRequests).then(function(results) {
				var students = _.flatMap(results, 'items');
				setStudents(students);
				deferred.resolve();
			});
			return deferred.promise;
		}

		function _loadStudentsSiteCodes() {
			var deferred = $q.defer();
			angular.forEach(svc.students, function(student) {
				student.sitecode = 'NONE ASSIGNED';
				if (angular.isDefined(student.organizationIds)) {
					var fetchSiteCodeQueue = [];
					angular.forEach(student.organizationIds, function(orgId) {
						fetchSiteCodeQueue.push(Organization.siteCode(orgId));
					});
					$q.all(fetchSiteCodeQueue)
						.then(function(sitecodes) {
							student.sitecode = _.uniqBy(sitecodes, function(e) {
								return e;
							}).toString();

							if ($window.hasOwnProperty('IL_PORTAL_ENV') && $window.IL_PORTAL_ENV.hasOwnProperty('name')) {
								switch ($window.IL_PORTAL_ENV.name) {
									case 'development':
									case 'test':
										student.sitecode = student.sitecode + '@test';
										break;
									case 'rc':
										student.sitecode = student.sitecode + '@rc';
										break;
								}
							}

							student.qrcode = svc._generateQrcode(student);
							deferred.resolve();
						})
						.catch(function(error) {
							deferred.reject(error);
							NotificationFactory.error(error);
						});
				}
			});
			return deferred.promise;
		}

		function _buildHtml(compiled) {
			// =============================================
			// CSS Generation Steps
			// ---------------------------------------------
			// 1. Compile common/web/src/components/student.cards/styles.scss
			//    at http://www.cssportal.com/scss-to-css/
			// 2. Minify at https://cssminifier.com/
			// 3. Copy | Paste the minified version to cssMinified
			// ---------------------------------------------

			var html, cssMinified;
			cssMinified =
				'#student-login-cards-iframe{position:absolute;z-index:60000000;overflow-y:scroll;right:0;left:0;bottom:0;top:0;width:100%;height:100%;background:#fff}body{margin:0;padding:0;font-family:Arial;font-weight:lighter;font-size:14px}*{box-sizing:border-box}table{border:1px solid #ccc}.cards{width:840px;margin:0 auto}.cards .card{width:100%;box-sizing:border-box;float:left;margin:5px}.cards .card .header{height:60px}.cards .card .content{height:176px!important}.cards .card .content .content-left{height:176px!important;border-bottom:1px solid #ccc;border-top:1px solid #ccc;padding-bottom:10px;margin:0}.cards .card .content .content-right{height:176px!important;border-left:1px solid #ccc;border-top:1px solid #ccc;border-bottom:1px solid #ccc;padding-bottom:10px;margin:0}.cards .card .content .content-left,.cards .card .content .content-right{max-width:192px}.cards .card .footer{padding:4px 15px;margin:0;height:31px}.header{flex:1;justify-content:left}.header-text{margin:4px 15px;font-size:20px;text-transform:uppercase}div.title{margin:0;color:#6f6f6f;font-size:12px;text-transform:uppercase;padding:15px 15px 0 15px}#qrcode{width:108px;margin-top:10px}#il-logo{width:110px;display:inline-block;float:left}.link{display:inline-block;float:right;margin-top:12px;font-size:16px}.student-property{text-align:left;padding:15px 15px 0 15px;word-break:break-all}.student-property>div{text-transform:uppercase;font-size:12px;margin-bottom:4px;color:#878787}.student-card-container{border:0;max-width:960px}@media print{.card{-webkit-column-break-inside:avoid;page-break-inside:avoid;break-inside:avoid}.no-print{display:none}}';

			html = '<html>';
			html += '<head>';
			html += '<title>Print Cards</title>';
			html += '<link rel="shortcut icon" href="/favicon.ico" />';
			html += '<style type="text/css">' + cssMinified + '</style>';
			html += '</head>';
			html += '<body class="print">';
			html += compiled;
			html += '</body>';
			html += '</html>';

			return html;
		}

		function _generateQrcode(student) {
			var str, qrcode;
			if (student.username && student.password && student.sitecode) {
				str = 'username ' + student.username + ' ' + 'password ' + student.password + ' ' + 'sitecode ' + student.sitecode + ' ';
				qrcode = QRCode.generate(str);
			}
			return qrcode;
		}
	}
})();
