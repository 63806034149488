(function() {
	'use strict';

	angular.module('web.dropdownMenu').directive('ilDropdownMenuItem', ilDropdownMenuItem);

	ilDropdownMenuItem.$inject = ['$document'];
	function ilDropdownMenuItem($document) {
		// Usage:
		//
		// Creates:
		//
		var directive = {
			link: link,
			require: '^ilDropdownMenuContainer',
			restrict: 'A',
			scope: {
				id: '@ilDropdownMenuItem'
			}
		};
		return directive;

		function link(scope, element, attrs, ctrl) {
			ctrl.register(scope.id);

			$document.on('click', onClick);

			element.on('$destroy', function() {
				$document.off('click', onClick);
				ctrl.unregister(scope.id);
			});

			element.addClass('dropdown-menu-item');

			scope.$watch(
				function() {
					return ctrl.isVisible(scope.id);
				},
				function(nowVisible, wasVisible) {
					if (nowVisible === wasVisible) {
						return;
					}

					if (nowVisible) {
						element.addClass('selected');
						ctrl.hideAllOthers(scope.id);
					} else {
						element.removeClass('selected');
					}
				}
			);

			function onClick(event) {
				if (ctrl.isVisible(scope.id)) {
					if (!element[0].contains(event.target).length && !event.target.hasAttribute('prevent-close')) {
						scope.$apply(function() {
							ctrl.setVisible(scope.id, false);
						});
					}
				}
			}
		}
	}
})();
