(function() {
	angular.module('growth.grade.filter').component('growthGradeFilter', {
		bindings: {
			type: '@',
			organizationId: '=',
			modelId: '=',
			grade: '=',
			context: '@'
		},
		controller: 'GrowthGradeFilterController',
		templateUrl: 'features/growth/growth.grade.filter/template.html'
	});
})();
