(function() {
	angular.module('user.account.menu', [
		'utils.localization',
		'ui.directives.clickOutside',
		'ui.logoutNotifier',
		'ui.notifications',
		'ui.form.utils',
		'ui.form.validSubmit',
		'ui.directives.match',
		'manager.modal.factory',
		'web.dropdownMenu',
		'api.organization',
		'api.iladmin',
		'api.user',
		'api.authentication',
		'api.application.user'
	]);
})();
