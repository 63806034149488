(function() {
	angular.module('assign.product.modal', [
		'ngDialog',
		'ui.notifications',
		'product.names',
		'api.manager',
		'api.application.user',
		'ui.tri.state.checkbox',
		'utils.localization'
	]);
})();
