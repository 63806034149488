(function() {
	angular.module('guid.service', ['angular-uuid']).service('GUID', service);

	service.$inject = ['uuid'];

	function service(uuid) {
		this.generate = generate;

		function generate() {
			return uuid.v4();
		}
	}
})();
