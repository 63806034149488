(function() {
	angular.module('legacy.browser.modal').directive('legacyBrowserModalLink', directive);

	directive.$inject = ['ngDialog'];

	function directive(ngDialog) {
		return {
			restrict: 'A',
			link: function(scope, element) {
				var dialog;

				function show() {
					dialog = ngDialog.open({
						className: 'ngdialog-theme-default',
						appendClassName: 'legacy-browser-modal',
						width: 500,
						template: 'components/legacy.browser.modal/modal.html',
						controller: 'LegacyBrowserModalController',
						controllerAs: '$ctrl',
						bindToController: true,
						closeByNavigation: true,
						closeByDocument: true
					});
				}

				element.on('click', show);

				element.on('$destroy', function() {
					if (dialog) {
						dialog.close();
						dialog = undefined;
					}
					element.off('click');
				});
			}
		};
	}
})();
