(function() {
	angular.module('internal.reports.modal').directive('internalReportsModalLink', directive);

	directive.$inject = ['ngDialog'];

	function directive(ngDialog) {
		return {
			restrict: 'A',
			scope: {
				organization: '=',
				reportPeriod: '@'
			},
			link: function(scope, element, attrs) {
				function show() {
					ngDialog.open({
						className: 'ngdialog-theme-default splash_screen_modal',
						template: 'components/internal.reports.modal/modal.html',
						controller: 'InternalReportsModalController',
						controllerAs: '$ctrl',
						closeByNavigation: false,
						closeByDocument: false,
						data: {
							organization: scope.organization,
							reportPeriod: scope.reportPeriod
						}
					});
				}

				element.bind('click touch', show);

				element.on('$destroy', function() {
					element.off('click touch', show);
				});
			}
		};
	}
})();
