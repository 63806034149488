(function() {
	var root = this;
	var translations = {
		en: {
			manager_list: {
				controls: {
					of: 'of',
					pageCounter: 'Page %1$s of %2$s'
				},
				terms: {
					enabled: 'Enabled',
					disabled: 'Disabled',
					unchanged: 'Unchanged',
					teacher: 'Teacher',
					teachers: 'Teachers',
					administrator: 'Administrator',
					administrators: 'Administrators',
					user: 'User',
					users: 'Users',
					staff: 'Staff',
					student: 'Student',
					students: 'Students',
					parentOrganization: 'Parent Organization',
					organization: 'Organization',
					organizations: 'Organizations',
					school: 'School',
					schools: 'Schools',
					group: 'Group',
					groups: 'Groups',
					organizationType: 'Organization Type',
					results: 'Results'
				},
				fields: {
					name: 'Name',
					firstName: 'First Name',
					lastName: 'Last Name',
					userName: 'Username',
					email: 'Email',
					userType: 'User Type',
					password: 'Password',
					passwordConfirm: 'Confirm Password',
					parentOrganization: 'Parent Organization',
					organizationType: 'Organization Type',
					type: 'Type',
					gradeLevel: 'Grade Level',
					siteCode: 'Site Code'
				},
				table: {
					noOrganizations: 'No organizations have been associated.',
					noGroups: 'No groups have been associated.',
					noResults: 'No results found.',
					noUsers: 'No users have been associated.',
					noStudents: 'No students have been associated.',
					selectedOrganizations: 'Selected Organizations',
					selectedGroups: 'Selected Groups',
					selectedUser: 'Selected User',
					selectedStudents: 'Selected Students'
				},
				placeholders: {
					organization: 'Find an organization',
					group: 'Find a group',
					user: 'Find a user',
					student: 'Find a student',
					search: 'Find in Results'
				},
				messages: {
					clearSearch: 'Clear Search',
					clickHereToClear: 'Click here to clear your search.',
					warning: 'Warning',
					syncedRecordHeader: 'Synced Record',
					syncedRecord: 'This record is synced with scheduled importing. Restricted editing available.',
					syncedFieldHeader: 'Synced Property',
					syncedField: 'This property is synced with a scheduled import and cannot be modified.',
					deleteSyncedEntity: 'This record is synced with a scheduled import and cannot be deleted.',
					noMediaServers: 'No Media Servers have been configured for this organization.',
					deleteOrganization:
						'This organization cannot be deleted because it is associated with one or more organizations, groups, users, or students. Remove these associations first before deleting the organization.',
					deleteUser:
						'This user account cannot be deleted because it is associated with one or more student groups. Remove this user account from their assigned group(s), then delete the user account.',
					deleteGroup: 'Groups cannot be deleted until all Students have been removed.'
				}
			}
		}
	};

	if (root.angular) {
		angular.module('manager.list').config([
			'LocalizeProvider',
			function(LocalizeProvider) {
				LocalizeProvider.translations(translations);
			}
		]);
	} else if (typeof module !== 'undefined' && module.exports) {
		// Node.js
		module.exports = translations;
	}
})();
