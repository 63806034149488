(function() {
	angular.module('generate.pdf.modal').controller('GeneratePdfModalController', controller);

	controller.$inject = ['$scope', '$window', 'NotificationFactory', 'CustomModal'];

	function controller($scope, $window, NotificationFactory, CustomModal) {
		var ctrl = this;
		ctrl.blob = undefined;
		ctrl.busy = true;
		ctrl.error = false;
		ctrl.noResults = false;
		ctrl.fileUrl = undefined;

		// methods
		ctrl.open = open;

		$scope.$on('ilModalRemove', function() {
			if (ctrl.busy && $scope.params && $scope.params.resource && $scope.params.resource.$cancelRequest) {
				$scope.params.resource.$cancelRequest();
			}
		});

		function open() {
			if (ctrl.blob !== undefined) {
				CustomModal.close();

				if ($window.navigator && $window.navigator.msSaveOrOpenBlob) {
					$window.navigator.msSaveOrOpenBlob(ctrl.blob, $scope.params.fileName || 'generated.pdf');
				} else {
					ctrl.fileUrl = URL.createObjectURL(ctrl.blob);
					$window.open(ctrl.fileUrl);
				}
			}
		}

		function initialize() {
			if ($scope.params && $scope.params.promise) {
				ctrl.busy = true;
				ctrl.noResults = false;
				ctrl.blob = undefined;
				$scope.params.promise
					.then(function(result) {
						ctrl.blob = result && result.data ? new Blob([result.data], { type: 'application/pdf' }) : undefined;
					})
					.catch(function(error) {
						ctrl.fileUrl = undefined;
						ctrl.blob = undefined;
						ctrl.noResults = true;
						ctrl.error = NotificationFactory.error(error);
					})
					.finally(function() {
						ctrl.busy = false;
					});
			}
		}

		initialize();
	}
})();
