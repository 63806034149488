(function() {
	var root = this;
	var translations = {
		en: {
			paged_list: {
				page: 'Page',
				of: 'of',

				perPage: {
					ten: '10 per page',
					twenty: '20 per page',
					thirty: '30 per page',
					fourty: '40 per page',
					fifty: '50 per page',
					oneHundred: '100 per page',
					twoHundred: '200 per page'
				}
			}
		},

		sp: {
			paged_list: {
				page: 'Página',
				of: 'de',

				perPage: {
					ten: '10 por página',
					twenty: '20 por página',
					thirty: '30 por página',
					fourty: '40 por página',
					fifty: '50 por página',
					oneHundred: '100 por página',
					twoHundred: '200 por página'
				}
			}
		}
	};
	if (root.angular) {
		root.angular.module('paged.list').config(config);
	} else if (typeof module !== 'undefined' && module.exports) {
		// Node.js
		module.exports = translations;
	}

	config.$inject = ['LocalizeProvider'];

	function config(LocalizeProvider) {
		LocalizeProvider.translations(translations);
	}
})();
