(function() {
	angular.module('global.search.service', ['api.application.user']).service('GlobalSearchService', service);

	service.$inject = ['ApplicationUserService', 'UserRole'];

	function service(ApplicationUserService, UserRole) {
		// properties
		this.query = '';
		this.sources = emptySources();

		// methods
		this.emptySources = emptySources;
		this.getParams = getParams;
		this.sourcesToParams = sourcesToParams;
		this.paramsToSources = paramsToSources;

		this.clear = function() {
			this.query = '';
			this.sources = emptySources();
		};

		function emptySources() {
			return {
				organizations: true,
				groups: true,
				users: true,
				students: true,
				activities: false
			};
		}

		function getParams() {
			return sourcesToParams(this.sources);
		}

		function sourcesToParams(sources) {
			var isTeacher = ApplicationUserService.getUserRole() === UserRole.teacher;

			return [
				!isTeacher && sources.organizations ? 'o' : '',
				sources.groups ? 'g' : '',
				!isTeacher && sources.users ? 'u' : '',
				sources.students ? 's' : '',
				sources.activities ? 'a' : ''
			].join('');
		}

		function paramsToSources(params) {
			var isTeacher = ApplicationUserService.getUserRole() === UserRole.teacher;

			params = params && params.length ? params.toLowerCase() : 'ogus';
			return {
				organizations: !isTeacher && params.match('o') !== null,
				groups: params.match('g') !== null,
				users: !isTeacher && params.match('u') !== null,
				students: params.match('s') !== null,
				activities: params.match('a') !== null
			};
		}
	}
})();
