(function() {
	angular.module('student.cards').controller('StudentCardsController', controller);

	controller.$inject = ['StudentCardsService', 'loginLinkUrl'];

	function controller(StudentCardsService, loginLinkUrl) {
		var ctrl = this;

		ctrl.loginLinkUrl = loginLinkUrl;

		ctrl.$onInit = init;
		ctrl.students = undefined;
		function init() {
			var students = StudentCardsService.getStudents(),
				viewCollection = [],
				item = [];
			_.forEach(students, function(student, index) {
				item.push(student);
				var increment = index + 1;
				if (increment % 2 === 0) {
					viewCollection.push(item);
					item = [];
				}
			});
			// if odd number add last item
			if (students.length % 2 !== 0) {
				viewCollection.push([students[students.length - 1], undefined]);
			}
			ctrl.students = viewCollection;
		}

		init();
	}
})();
