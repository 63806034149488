(function() {
	angular
		.module('kpi.student.achievements', [
			'usage.common.localization',
			'helpers.percentages',
			'ui.notifications',
			'api.reporting',
			'utils.date'
		])
		.directive('studentAchievementsKpi', directive)
		.controller('StudentAchievementsKpiCtrl', controller);

	directive.$inject = [];

	function directive() {
		return {
			restrict: 'A',
			scope: {
				student: '=studentAchievementsKpi',
				dateRange: '=',
				headerText: '@'
			},
			controller: controller,
			controllerAs: 'model',
			templateUrl: 'features/progress/achievements/student.achievements.kpi/student.achievements.html',
			link: function(scope, element, attrs) {}
		};
	}

	controller.$inject = ['$scope', '$q', 'NotificationFactory', 'schoolYear', 'ReportingAPI', 'CurrentDate'];

	function controller($scope, $q, NotificationFactory, schoolYear, ReportingAPI, CurrentDate) {
		var model = this;
		model.initialized = false;
		model.busy = false;
		model.error = false;
		model.data = {};

		model.refreshModel = refreshModel;

		$scope.$watchCollection(
			'student',
			function(value) {
				if (value && !model.busy) {
					refreshModel(value);
				} else {
					model.data = {};
				}
			},
			true
		);

		function refreshModel(student) {
			if (!student || !student.id || !student.organizationIds || !student.organizationIds.length) {
				return;
			}

			model.busy = true;
			model.data = {};

			var promise;
			if ($scope.dateRange) {
				promise = $q.when($scope.dateRange);
			} else {
				promise = schoolYear.get(student.organizationIds[0]);
			}

			promise
				.then(function(dates) {
					return ReportingAPI.student.get({
						id: student.id,
						startDate: dates.start,
						endDate: dates.end && dates.end.getTime() > CurrentDate.get().getTime() ? CurrentDate.get() : dates.end,
						dataFields: ['achievements']
					});
				})
				.then(function(result) {
					model.data = result && result.achievements ? result.achievements : {};
				})
				['catch'](function(error) {
					model.error = NotificationFactory.error(error);
				})
				['finally'](function() {
					model.busy = false;
					model.initialized = true;
				});
		}
	}
})();
