(function() {
	angular
		.module('date.range.service', [
			// 'ui.router',
			'utils.date'
		])
		.constant('defaultRangeNames', {
			thisWeek: 'This Week',
			lastWeek: 'Last Week',
			last4Weeks: '4 Weeks',
			last8Weeks: '8 Weeks',
			last30Days: 'Last 30 Days',
			last60Days: 'Last 60 Days',
			yearToDate: 'Year To Date',
			lastYear: 'Last Year',
			custom: 'Custom'
		})
		.constant('defaultRangeIds', {
			thisWeek: 'thisWeek',
			lastWeek: 'lastWeek',
			last4Weeks: 'last4Weeks',
			last8Weeks: 'last8Weeks',
			last30Days: 'last30Days',
			last60Days: 'last60Days',
			yearToDate: 'yearToDate',
			lastYear: 'lastYear',
			custom: 'custom'
		})
		.service('DateRangeService', service);

	service.$inject = ['CurrentDate', '$filter', 'defaultRangeNames', 'defaultRangeIds'];

	function service(CurrentDate, $filter, defaultRangeNames, defaultRangeIds) {
		let svc = this;

		svc.thisWeek = thisWeek;
		svc.lastWeek = lastWeek;
		svc.last4Weeks = last4Weeks;
		svc.last8Weeks = last8Weeks;
		svc.last30Days = last30Days;
		svc.last60Days = last60Days;
		svc.yearToDate = yearToDate;
		svc.lastYear = lastYear;
		svc.getDefaults = getDefaults;
		svc.getSaturday = getSaturday;
		svc.getSunday = getSunday;
		svc.getRange = getRange;
		svc.cleanDate = cleanDate;
		svc.getDateFromString = getDateFromString;
		svc.getDateString = getDateString;
		svc.getDisplayDateString = getDisplayDateString;
		svc.updateRangeParams = updateRangeParams;
		svc.getDateRange = getDateRange;
		svc.findRange = findRange;
		svc.defaultRangeSet = defaultRangeSet;
		svc.setSession = setSession;
		svc.schoolYearSafeDate = schoolYearSafeDate;

		function findRange(start, end) {
			return _.find(svc.ranges, range => {
				return (
					getDisplayDateString(range.start) === getDisplayDateString(start) &&
					(getDisplayDateString(range.end) === getDisplayDateString(end) || end > getToday(true))
				);
			});
		}

		function updateRangeParams(range, state) {
			if (!state || !range || !range.start || !range.end) {
				return;
			}

			if (state && state.go && angular.isFunction(state.go)) {
				let startString = getDateString(range.start);
				let endString = getDateString(range.end);
				state.params = state.params || {};
				if (!state.params.start || state.params.start !== startString || !state.params.end || state.params.end !== endString) {
					if (!(state.params.start && state.params.start === startString && state.params.end && state.params.end === endString)) {
						state.params.start = startString;
						state.params.end = endString;
						state.go(state.current.name, state.params, { notify: false, location: 'replace' });
					}
				}
			}
		}

		function cleanDate(param) {
			if (angular.isDate(param)) {
				return param;
			} else if (angular.isString(param)) {
				return getDateFromString(param);
			}
		}

		function getDateFromString(dateString) {
			dateString = dateString.replace(/[^0-9\-]/gim, '');
			let params = dateString.split('-');
			return new Date(params[2], params[0] > 0 ? params[0] - 1 : params[0], params[1], 0, 0, 0, 0);
		}

		function getDateString(date) {
			return $filter('date')(date, 'M-d-y');
		}

		function getDisplayDateString(date) {
			return $filter('date')(date, 'M/d/y');
		}

		function getSunday(date) {
			let curr = new Date(date);
			let first = curr.getDate() - curr.getDay(); // First day is the day of the month - the day of the week
			let sunday = first + 7; // sat day is the first day + 5
			return new Date(curr.setDate(sunday));
		}

		function getSaturday(date) {
			let curr = new Date(date);
			let first = curr.getDate() - curr.getDay(); // First day is the day of the month - the day of the week
			let saturday = first + 6; // sat day is the first day + 5
			return new Date(curr.setDate(saturday));
		}

		function getToday(endOfDay) {
			let today = CurrentDate.get();
			return new Date(
				today.getFullYear(),
				today.getMonth(),
				today.getDate(),
				endOfDay ? 23 : 0,
				endOfDay ? 59 : 0,
				endOfDay ? 59 : 0,
				endOfDay ? 999 : 0
			);
		}

		function schoolYearSafeDate(day, session) {
			let firstDayOfSession = session && session.start && angular.isDate(session.start) ? cleanDate(session.start) : undefined;
			let lastDayOfSession = session && session.end && angular.isDate(session.end) ? cleanDate(session.end) : undefined;
			let safeDay = firstDayOfSession && day < firstDayOfSession ? firstDayOfSession : day;
			return lastDayOfSession && safeDay > lastDayOfSession ? lastDayOfSession : safeDay;
		}

		function thisWeek(session) {
			return getDateRange(defaultRangeIds.thisWeek, session);
		}

		function lastWeek(session) {
			return getDateRange(defaultRangeIds.lastWeek, session);
		}

		function last4Weeks(session) {
			return getDateRange(defaultRangeIds.last4Weeks, session);
		}

		function last8Weeks(session) {
			return getDateRange(defaultRangeIds.last8Weeks, session);
		}

		function last30Days() {
			let today = getToday();
			return getRange(
				new Date(new Date(today).setDate(today.getDate() - 30)),
				today,
				defaultRangeNames.last30Days,
				defaultRangeIds.last30Days
			);
		}

		function last60Days() {
			let today = getToday();
			return getRange(
				new Date(new Date(today).setDate(today.getDate() - 60)),
				today,
				defaultRangeNames.last60Days,
				defaultRangeIds.last60Days
			);
		}

		function yearToDate(session) {
			return getRange(session.start, getToday(), defaultRangeNames.yearToDate, defaultRangeIds.yearToDate);
		}

		function lastYear(session) {
			return getRange(
				new Date(session.start.getFullYear() - 1, session.start.getMonth(), session.start.getDate(), 0, 0, 0, 0),
				new Date(session.end.getFullYear() - 1, session.end.getMonth(), session.end.getDate(), 0, 0, 0, 0),
				defaultRangeNames.lastYear,
				defaultRangeIds.lastYear
			);
		}

		function getRange(start, end, text, translationId) {
			start = new Date(cleanDate(start));
			end = new Date(cleanDate(end));

			let found = findRange(start, end);
			let result =
				!found || (found && translationId && found.translationId && found.translationId !== translationId)
					? {
							text: text && text.length ? text : [getDisplayDateString(start), getDisplayDateString(end)].join(' - '),
							start: start,
							end: end,
							translationId: translationId || defaultRangeIds.custom
					  }
					: found;

			return result;
		}

		function getDefaults(session, hideLastYear) {
			let ranges = [];
			ranges.push(thisWeek(session));
			ranges.push(lastWeek(session));
			ranges.push(last4Weeks(session));
			ranges.push(last8Weeks(session));

			if (session && session.start) {
				ranges.push(yearToDate(session));
				// if(!hideLastYear) {
				// 	ranges.push(lastYear(session));
				// }
			}

			ranges.push({
				text: defaultRangeNames.custom,
				start: null,
				end: null,
				translationId: defaultRangeIds.custom
			});
			return ranges;
		}

		function setSession(session) {
			if (session && !angular.equals(svc.session, session)) {
				svc.session = session;
				svc.ranges = defaultRangeSet(session);
			}
		}

		function getDateRange(rangeId, session, start, end) {
			const constToday = getToday();
			let today = getToday(),
				lastWeek = new Date(new Date(today).setDate(today.getDate() - 7)),
				firstDay =
					rangeId === defaultRangeIds.custom
						? start
						: rangeId === defaultRangeIds.yearToDate
						? session.start
						: rangeId === defaultRangeIds.thisWeek
						? new Date(today.setDate(today.getDate() - today.getDay()))
						: new Date(lastWeek.setDate(lastWeek.getDate() - lastWeek.getDay())),
				lastDay =
					rangeId === defaultRangeIds.custom
						? end
						: rangeId === defaultRangeIds.yearToDate
						? session.end > constToday
							? constToday
							: session.end
						: rangeId === defaultRangeIds.thisWeek ||
						  rangeId === defaultRangeIds.last4Weeks ||
						  rangeId === defaultRangeIds.last8Weeks
						? constToday
						: new Date(lastWeek.setDate(lastWeek.getDate() - lastWeek.getDay() + 6));

			firstDay =
				rangeId === defaultRangeIds.last4Weeks
					? new Date(firstDay.setDate(firstDay.getDate() - 14))
					: rangeId === defaultRangeIds.last8Weeks
					? new Date(firstDay.setDate(firstDay.getDate() - 42))
					: firstDay;

			firstDay = schoolYearSafeDate(firstDay, session);
			lastDay = schoolYearSafeDate(lastDay, session);

			return getRange(
				firstDay,
				lastDay,
				rangeId === defaultRangeIds.custom ? undefined : defaultRangeNames[rangeId],
				rangeId === defaultRangeIds.custom ? undefined : defaultRangeIds[rangeId]
			);
		}

		function defaultRangeSet(session) {
			let ranges = [];
			ranges.push(getDateRange(defaultRangeIds.thisWeek, session));
			ranges.push(getDateRange(defaultRangeIds.lastWeek, session));
			ranges.push(getDateRange(defaultRangeIds.last4Weeks, session));
			ranges.push(getDateRange(defaultRangeIds.last8Weeks, session));

			if (session) {
				ranges.push(getDateRange(defaultRangeIds.yearToDate, session));
			}

			ranges.push({
				text: defaultRangeNames.custom,
				start: null,
				end: null,
				translationId: defaultRangeIds.custom
			});
			return ranges;
		}

		svc.ranges = defaultRangeSet();
	}
})();
