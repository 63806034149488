(function() {
	angular.module('assign.product.modal').controller('AssignProductModalController', controller);

	controller.$inject = [
		'$scope',
		'Localize',
		'Products',
		'ApiProducts',
		'ManagerBulkOperations',
		'ApplicationUserService',
		'NotificationFactory'
	];

	function controller($scope, Localize, Products, ApiProducts, ManagerBulkOperations, ApplicationUserService, NotificationFactory) {
		let ctrl = this;

		ctrl.productList = Products;
		let userProducts = ApplicationUserService.getUser().products;
		ctrl.showILE = _.indexOf(userProducts, ApiProducts.ILE) !== -1;
		ctrl.showSpanish = _.indexOf(userProducts, ApiProducts.Spanish) !== -1;
		ctrl.showReading = _.indexOf(userProducts, ApiProducts.Reader) !== -1;
		ctrl.showLectura = _.indexOf(userProducts, ApiProducts.Enyay) !== -1 || _.indexOf(userProducts, ApiProducts.Lectura) !== -1;
		ctrl.showGalileo = ctrl.showILE || ctrl.showSpanish;
		ctrl.deleteGalileo = true;
		ctrl.galileoDisabled = false;

		ctrl.states = {
			None: 'none',
			True: 'true',
			False: 'false'
		};

		ctrl.literacy = ctrl.states.None;
		ctrl.espanol = ctrl.states.None;
		ctrl.reading = ctrl.states.None;
		ctrl.galileo = ctrl.states.None;
		ctrl.enyay = ctrl.states.None;
		ctrl.lectura = ctrl.states.None;

		ctrl.titleText = $scope.ngDialogData.titleText || Localize.translateInstant('assign_product_modal.titleText');
		ctrl.students = $scope.ngDialogData.students;
		ctrl.callback = $scope.ngDialogData.callback;

		ctrl.applyChanges = applyChanges;
		ctrl.selectGalileo = selectGalileo;
		ctrl.hasChanges = hasChanges;

		ctrl.init = init;

		function init() {
			angular.forEach(ctrl.students, function(student) {
				if (
					student.products.indexOf(ApiProducts.ILE) !== -1 ||
					student.products.indexOf(ApiProducts.Spanish) !== -1 ||
					student.products.indexOf(ApiProducts.Reader) !== -1 ||
					student.products.indexOf(ApiProducts.Enyay) !== -1 ||
					student.products.indexOf(ApiProducts.Lectura) !== -1
				) {
					ctrl.deleteGalileo = false;
					ctrl.galileoDisabled = true;
				}
			});
		}

		function applyChanges() {
			let items = [];

			angular.forEach(ctrl.students, function(student) {
				let index,
					products = student.products;

				ctrl.deleteGalileo = false;

				if (ctrl.literacy === ctrl.states.False) {
					index = _.indexOf(products, ApiProducts.ILE);
					if (index !== -1) {
						products.splice(index, 1);
						ctrl.deleteGalileo = isGalileoRemoved(products);
					}
				}

				if (ctrl.literacy === ctrl.states.True) {
					index = _.indexOf(products, ApiProducts.ILE);
					if (index === -1) {
						products.push(ApiProducts.ILE);
					}
				}

				if (ctrl.espanol === ctrl.states.False) {
					index = _.indexOf(products, ApiProducts.Spanish);
					if (index !== -1) {
						products.splice(index, 1);
						ctrl.deleteGalileo = isGalileoRemoved(products);
					}
				}

				if (ctrl.espanol === ctrl.states.True) {
					index = _.indexOf(products, ApiProducts.Spanish);
					if (index === -1) {
						products.push(ApiProducts.Spanish);
					}
				}

				if (ctrl.reading === ctrl.states.False) {
					index = _.indexOf(products, ApiProducts.Reader);
					if (index !== -1) {
						products.splice(index, 1);
						ctrl.deleteGalileo = isGalileoRemoved(products);
					}
				}

				if (ctrl.reading === ctrl.states.True) {
					index = _.indexOf(products, ApiProducts.Reader);
					if (index === -1) {
						products.push(ApiProducts.Reader);
					}
				}

				if (ctrl.enyay === ctrl.states.False) {
					index = _.indexOf(products, ApiProducts.Enyay);
					if (index !== -1) {
						products.splice(index, 1);
						ctrl.deleteGalileo = isGalileoRemoved(products);
					}
				}

				if (ctrl.enyay === ctrl.states.True) {
					index = _.indexOf(products, ApiProducts.Enyay);
					if (index === -1) {
						products.push(ApiProducts.Enyay);
					}
				}

				if (ctrl.lectura === ctrl.states.False) {
					index = _.indexOf(products, ApiProducts.Lectura);
					if (index !== -1) {
						products.splice(index, 1);
						ctrl.deleteGalileo = isGalileoRemoved(products);
					}
				}

				if (ctrl.lectura === ctrl.states.True) {
					index = _.indexOf(products, ApiProducts.Lectura);
					if (index === -1) {
						products.push(ApiProducts.Lectura);
					}
				}

				if (ctrl.galileo === ctrl.states.False) {
					ctrl.deleteGalileo = isGalileoRemoved(products);
				}

				validateGalileo(products);

				items.push({
					Id: student.id,
					products: products
				});
			});

			ManagerBulkOperations.patch.students(items).then(function(results) {
				$scope.closeThisDialog();
				if (ctrl.callback) {
					ctrl.callback();
				}
			});
		}

		function isGalileoRemoved(newProducts) {
			return newProducts.length === 1 && newProducts[0] === ApiProducts.Galileo;
		}

		function validateGalileo(products) {
			let someProductsSelected =
				_.indexOf(products, ApiProducts.ILE) !== -1 ||
				_.indexOf(products, ApiProducts.Spanish) !== -1 ||
				_.indexOf(products, ApiProducts.Reader) !== -1 ||
				_.indexOf(products, ApiProducts.Enyay) !== -1 ||
				_.indexOf(products, ApiProducts.Lectura) !== -1;

			const galIdx = _.indexOf(products, ApiProducts.Galileo);

			if (someProductsSelected) {
				if (galIdx === -1) {
					products.push(ApiProducts.Galileo);
				}
			} else {
				if (galIdx !== -1 && ctrl.deleteGalileo) {
					products.splice(galIdx, 1);
				} else {
					if (ctrl.galileo === ctrl.states.True && galIdx === -1) {
						products.push(ApiProducts.Galileo);
					}
				}
			}
		}

		function selectGalileo() {
			ctrl.galileoDisabled = true;
			if (
				(ctrl.literacy === ctrl.states.None && ctrl.espanol === ctrl.states.None && ctrl.reading === ctrl.states.None) ||
				ctrl.literacy === ctrl.states.False ||
				ctrl.espanol === ctrl.states.False ||
				ctrl.reading === ctrl.states.False
			) {
				ctrl.galileo = ctrl.states.None;
				ctrl.galileoDisabled = ctrl.deleteGalileo ? false : true;
			} else if (ctrl.literacy === ctrl.states.True || ctrl.espanol === ctrl.states.True || ctrl.reading === ctrl.states.True) {
				ctrl.galileo = ctrl.states.True;
			}

			if (ctrl.literacy === ctrl.states.False && ctrl.espanol === ctrl.states.False && ctrl.reading === ctrl.states.False) {
				ctrl.galileo = ctrl.states.False;
			}
		}

		function hasChanges() {
			return !(
				ctrl.literacy === ctrl.states.None &&
				ctrl.espanol === ctrl.states.None &&
				ctrl.reading === ctrl.states.None &&
				ctrl.enyay === ctrl.states.None &&
				ctrl.lectura === ctrl.states.None &&
				ctrl.galileo === ctrl.states.None
			);
		}

		init();
	}
})();
