// hooks in to browser matchMedia queries as well as legacy onBeforePrint/onAfterPrint (IE) to allow
// broadcasting our own events to be consumed by JS/directives that require a redraw for printing
// known issues: FF and IE have bugs where they do not have print styles applied at time of beforePrint event

(function() {
	angular.module('print.events', []).service('PrintEventsService', service);

	service.$inject = ['$rootScope', '$window'];

	function service($rootScope, $window) {
		this.init = init;
		this.beforePrint = beforePrint;
		this.afterPrint = afterPrint;

		// we are using angular events instead of registered window events
		// hanging on to this for future reference...
		//function fireRefreshEventOnWindow() {
		// 	var evt = $window.document.createEvent("HTMLEvents");
		// 	evt.initEvent('chartPrint', true, false);
		// 	$window.dispatchEvent(evt);
		//}

		function beforePrint() {
			//fireRefreshEventOnWindow();
			$rootScope.$broadcast('beforePrint');
		}

		function afterPrint() {
			$rootScope.$broadcast('afterPrint');
		}

		function init() {
			if ($window.matchMedia) {
				var mediaQueryList = $window.matchMedia('print');
				mediaQueryList.addListener(function(mql) {
					if (mql.matches) {
						beforePrint();
					} else {
						afterPrint();
					}
				});
			}

			$window.onbeforeprint = beforePrint;
			$window.onafterprint = afterPrint;
		}
	}
})();
