(function() {
	angular.module('grade.level.drop.down').component('gradeLevelDropDown', {
		bindings: {
			product: '@?',
			type: '@',
			id: '<',
			dateRange: '<',
			grade: '=?'
		},
		controller: 'GradeLevelDropDownController',
		templateUrl: 'components/grade.level.drop.down/template.html'
	});
})();
