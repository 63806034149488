(function() {
	angular.module('paged.list').factory('PagedList', factory);

	factory.$inject = ['$rootScope', '$q', 'NotificationFactory'];

	function factory($rootScope, $q, NotificationFactory) {
		var PagedList = function(query, id) {
			var pagedList = this;
			pagedList.query = query;

			pagedList.pageSizes = [
				{ value: 10, text: 'ten' },
				{ value: 20, text: 'twenty' },
				{ value: 30, text: 'thirty' },
				{ value: 40, text: 'fourty' },
				{ value: 50, text: 'fifty' },
				{ value: 100, text: 'oneHundred' },
				{ value: 200, text: 'twoHundred' }
			];

			// if(!angular.isArray(pagedList.query)) {
			// 	pagedList.pageSizes.push({ 'value': 0, 'text': 'Show All' });
			// }

			pagedList.id = id;
			pagedList.params = {};
			pagedList.params.search = '';
			pagedList.filterString = undefined;
			pagedList.items = [];
			pagedList.pageDisplayText = '';
			pagedList.selectAll = false;
			pagedList.selectedItems = [];
			pagedList.showSelected = false;
			pagedList.totalCount = 0; //total number of items
			pagedList.error = undefined;
			pagedList.busy = false; //true while loading content
			pagedList.enabled = false;
			pagedList.initialized = false;
			pagedList.showPerPage = true;
			pagedList.perPage = pagedList.pageSizes[0].value; //how many items on each page
			pagedList.pageNumber = 1; //which page the user wants to see
			pagedList.lastPageNumber = 0;
			pagedList.loadedPageNumber = -1; //which page is currently loaded
			pagedList.language = 'en';

			// methods
			pagedList.pageCount = pageCount;
			pagedList.fetchPage = fetchPage;
			pagedList.load = load;
			pagedList.refresh = refresh;
			pagedList.refreshAndRewind = refreshAndRewind;
			pagedList.back = back;
			pagedList.forward = forward;
			pagedList.last = last;
			pagedList.sort = sort;
			pagedList.toggleViewMode = toggleViewMode;
			pagedList.updatePageDisplayText = updatePageDisplayText;

			pagedList.fetchSelectedItems = fetchSelectedItems;
			pagedList.isSelected = isSelected;
			pagedList.allSelected = allSelected;
			pagedList.toggleSelected = toggleSelected;
			pagedList.toggleSelectItem = toggleSelectItem;
			pagedList.toggleSelectAll = toggleSelectAll;

			function pageCount() {
				return Math.ceil(pagedList.totalCount / pagedList.perPage);
			}

			function callBack() {
				if (pagedList.callBack) {
					pagedList.callBack();
				}
			}

			function fetchPage() {
				var pars;
				if (!pagedList.enabled || pagedList.busy || pagedList.loadedPageNumber === pagedList.pageNumber) {
					//we are not enabled, already busy, or already on the right page
					return;
				}

				if (pagedList.pageNumber >= pagedList.totalCount / pagedList.perPage + 1) {
					//if we are past the end, wrap around
					pagedList.pageNumber = 1;
				}

				if (pagedList.pageNumber < 1) {
					//if we are past the beginning, wrap around
					pagedList.pageNumber = pageCount();
				}

				//go fetch the data
				//pagedList.items = [];
				pagedList.loadedPageNumber = pagedList.pageNumber;
				var offset = pagedList.perPage * (pagedList.pageNumber - 1);

				pagedList.busy = true;
				pagedList.error = undefined;
				pagedList.params = pagedList.params || {};
				pagedList.params.offset = offset;
				pagedList.params.limit = pagedList.perPage;

				if (pagedList.showSelected === true) {
					//we are showing only the currently selected items - ex: bulk edit mode
					pagedList.busy = false;
					pagedList.totalCount = pagedList.selectedItems.length;
					pagedList.items = _.slice(
						pagedList.selectedItems,
						pagedList.params.offset,
						pagedList.params.offset + pagedList.params.limit
					);
					updatePageDisplayText();
					// pagedList.updatePerPage();
					pagedList.initialized = true;
					callBack();
				} else if (angular.isArray(pagedList.query)) {
					pagedList.totalCount = pagedList.query.length;
					pagedList.items = _.slice(pagedList.query, pagedList.params.offset, pagedList.params.offset + pagedList.params.limit);
					pagedList.busy = false;
					updatePageDisplayText();
					pagedList.initialized = true;
					callBack();
				} else {
					//we are showing the contents of a server query which relies on an id
					if (pagedList.id && pagedList.id.length) {
						pars = angular.copy(pagedList.params);
						pagedList
							.query(pagedList.id, pars)
							.then(function(results, headers) {
								pagedList.busy = false;
								pagedList.totalCount = results.totalCount;
								pagedList.items = results.items;
								// pagedList.updatePerPage();
								updatePageDisplayText();

								callBack();
							})
							.catch(function(error) {
								// catch 422's as empty results sets
								if (error && error.status && error.status === 422) {
									pagedList.busy = false;
									pagedList.totalCount = 0;
									pagedList.items = [];
									// pagedList.updatePerPage();
									updatePageDisplayText();
								} else {
									pagedList.error = NotificationFactory.error(error);
								}
							})
							.finally(function() {
								pagedList.busy = false;
								pagedList.initialized = true;
							});
					}
					//generic query
					else {
						pars = angular.copy(pagedList.params);
						pagedList
							.query(pars)
							.then(function(results, headers) {
								pagedList.busy = false;
								pagedList.totalCount = results.totalCount;
								pagedList.items = results.items;
								// pagedList.updatePerPage();
								updatePageDisplayText();

								callBack();
							})
							.catch(function(error) {
								// catch 422's as empty results sets
								if (error && error.status && error.status === 422) {
									pagedList.busy = false;
									pagedList.totalCount = 0;
									pagedList.items = [];
									// pagedList.updatePerPage();
									updatePageDisplayText();
								} else {
									pagedList.error = NotificationFactory.error(error);
								}
							})
							.finally(function() {
								pagedList.busy = false;
								pagedList.initialized = true;
							});
					}
				}
			}

			function load() {
				if (!pagedList.enabled) {
					pagedList.enabled = true;
				}
				// pagedList.params.search = '';
				// pagedList.params.desc = false; // let this come from the instance
				pagedList.filterString = undefined;
				pagedList.initialized = false;
				refresh();
			}

			//refresh current page
			function refresh() {
				if (!pagedList.enabled) {
					return;
				}
				//invalidate the current page
				pagedList.loadedPageNumber = -1;
				//get the current page
				fetchPage();
			}

			//go to first page
			function refreshAndRewind() {
				if (!pagedList.enabled) {
					return;
				}

				pagedList.pageNumber = 1;
				pagedList.totalCount = 0;
				refresh();
			}

			//go back a page
			function back() {
				if (!pagedList.enabled) {
					return;
				}
				pagedList.lastPageNumber = _.clone(pagedList.pageNumber);
				pagedList.pageNumber--;
				refresh();
			}

			//go forward a page
			function forward() {
				if (!pagedList.enabled) {
					return;
				}
				pagedList.lastPageNumber = _.clone(pagedList.pageNumber);
				pagedList.pageNumber++;
				refresh();
			}

			//go to last page
			function last() {
				if (!pagedList.enabled) {
					return;
				}
				pagedList.pageNumber = pageCount();
				refresh();
			}

			function sort(field) {
				//only toggle sort order if the same field is being sorted
				if (pagedList.params.sortby === field) {
					if (pagedList.params.desc === undefined) {
						pagedList.params.desc = false;
					} else {
						pagedList.params.desc = !pagedList.params.desc;
					}
				} else {
					pagedList.params.sortby = field;
					pagedList.params.desc = false;
				}

				if (angular.isArray(pagedList.query)) {
					pagedList.query = _.orderBy(pagedList.query, pagedList.params.sortby, pagedList.params.desc ? 'desc' : 'asc');
				}
				refresh();
			}

			// toggle showing the query results or the selected items
			function toggleViewMode() {
				pagedList.showSelected = !pagedList.showSelected;
				refreshAndRewind();
			}

			function updatePageDisplayText() {
				pagedList.pageDisplayText = [
					pagedList.pageNumber === 1 ? 1 : 1 + (pagedList.pageNumber - 1) * pagedList.perPage,
					'-',
					pagedList.totalCount < pagedList.perPage
						? pagedList.totalCount
						: pagedList.pageNumber === pageCount()
						? pagedList.totalCount
						: pagedList.pageNumber * pagedList.perPage,
					pagedList.language === 'sp' ? 'de' : 'of',
					pagedList.totalCount
				].join(' ');
			}

			//update pagination after user removes items
			$rootScope.$watch(
				function() {
					return pagedList.selectedItems;
				},
				function(value, lastValue) {
					if (pagedList.showSelected === true && value && value.length !== lastValue.length) {
						if (pageCount() < pagedList.pageNumber) {
							if (value.length === 0) {
								pagedList.showSelected = false;
								refreshAndRewind();
							} else {
								pagedList.pageNumber = pageCount();
								refresh();
							}
						}
					}
				},
				true
			);

			// //reload list when search is cleared out
			// $rootScope.$watch(function () {
			// 	return pagedList.params.search;
			// }, function (value, lastValue) {
			// 	if ((!value || value.length === 0) && (lastValue && lastValue.length) ||
			// 		(value && value.length) && (!lastValue || !lastValue.length)) {
			// 		refreshAndRewind();
			// 	}
			// }, true);

			function stateChangeSuccess() {
				pagedList.showSelected = false;
				pagedList.selectedItems = [];
			}

			$rootScope.$on('$stateChangeSuccess', stateChangeSuccess);

			// updatePerPage: function () {
			// 	pagedList.pageSizes[7].value = pagedList.totalCount;
			// }

			//get paged slice of selected items
			function fetchSelectedItems(params) {
				return $q.when(_.slice(pagedList.selectedItems, params.offset, params.limit));
			}

			function isSelected(item) {
				return (
					_.findIndex(pagedList.selectedItems, function(selectedItem) {
						return selectedItem.id === item.id;
					}) !== -1
				);
			}

			function allSelected() {
				return (
					_.every(pagedList.items, function(item) {
						return pagedList.isSelected(item);
					}) === true
				);
			}

			function toggleSelected(item) {
				var index = _.findIndex(pagedList.selectedItems, function(selectedItem) {
					return selectedItem.id === item.id;
				});

				if (index === -1) {
					pagedList.selectedItems.push(item);
				} else {
					pagedList.selectedItems.splice(index, 1);
				}

				if (pagedList.showSelected === true) {
					pagedList.refresh();
				}
			}

			function toggleSelectItem(item, selected) {
				//selected - bool - selected state items should be put in
				var index = _.findIndex(pagedList.selectedItems, function(selectedItem) {
					return selectedItem.id === item.id;
				});

				if (index === -1 && selected) {
					pagedList.selectedItems.push(item);
				}
				if (index !== -1 && !selected) {
					pagedList.selectedItems.splice(index, 1);
				}
			}

			function toggleSelectAll() {
				var allItemsSelected =
					_.every(pagedList.items, function(item) {
						return pagedList.isSelected(item);
					}) === true;

				angular.forEach(pagedList.items, function(item) {
					pagedList.toggleSelectItem(item, !allItemsSelected);
				});

				if (pagedList.showSelected === true) {
					//refresh and show the user the item has been removed
					pagedList.refresh();
				}
			}
		};

		return PagedList;
	}
})();
