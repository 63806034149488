(function() {
	angular
		.module('test.enablement.organization')
		.controller('TestEnablementOrganizationController', controller)
		.component('testEnablementOrganization', {
			bindings: {
				organization: '<',
				test: '@'
			},
			controller: 'TestEnablementOrganizationController',
			templateUrl: 'components/test.enablement.organization/component.html'
		});

	controller.$inject = [
		'ngDialog',
		'CurrentDate',
		'Organization',
		'PagedList',
		'TestRanges',
		'testTypes',
		'testWindows',
		'testSettingsFields',
		'ApplicationUserService'
	];

	function controller(
		ngDialog,
		CurrentDate,
		Organization,
		PagedList,
		TestRanges,
		testTypes,
		testWindows,
		testSettingsFields,
		ApplicationUserService
	) {
		var ctrl = this;
		ctrl.busy = false;
		ctrl.testWindows = testWindows;
		var currentRange = TestRanges.closestTestRange(CurrentDate.get()).replace('OfYear', '');
		ctrl.testWindow = currentRange && currentRange.length ? currentRange : ctrl.testWindows.beginning;
		ctrl.testTypes = testTypes;
		ctrl.testType = ctrl.testWindow === ctrl.testWindows.beginning ? testTypes.lexile : testTypes.growth;
		ctrl.testSettings = undefined;
		ctrl.pagedList = undefined;

		ctrl.user = ApplicationUserService.getUser();

		ctrl.$onInit = onInit;
		ctrl.$onChanges = onChanges;
		ctrl.edit = edit;
		ctrl.showSelectedModal = showSelectedModal;
		ctrl.getWindowSettingsField = getWindowSettingsField;
		ctrl.buttonsDisabled = buttonsDisabled;
		ctrl.testChanged = testChanged;

		ctrl.enableButtonActive = enableButtonActive;
		ctrl.disableButtonActive = disableButtonActive;

		function enableButtonActive() {
			if (!ctrl.pagedList || !ctrl.pagedList.selectedItems || !ctrl.pagedList.selectedItems.length) {
				return false;
			}
			return _.some(ctrl.pagedList.selectedItems, function(item) {
				var i = item[getWindowSettingsField()];
				return !i.optIn || i.optIn !== true;
			});
		}

		function disableButtonActive() {
			if (!ctrl.pagedList || !ctrl.pagedList.selectedItems || !ctrl.pagedList.selectedItems.length) {
				return false;
			}
			return _.some(ctrl.pagedList.selectedItems, function(item) {
				var i = item[getWindowSettingsField()];
				return i.optIn && i.optIn === true;
			});
		}

		function onChanges(changesObj) {
			if (changesObj.organization && changesObj.organization.currentValue) {
				onInit();
			}
		}

		function testChanged() {
			if (
				ctrl.testType === testTypes.growth &&
				ctrl.testWindow === ctrl.testWindows.beginning &&
				ctrl.user.userRole !== 'ILAdmin' &&
				ctrl.user.userRole !== 'ILSiteAdmin'
			) {
				ctrl.testWindow = ctrl.testWindows.middle;
			}
		}

		function buttonsDisabled() {
			return (
				!ctrl.pagedList ||
				!ctrl.pagedList.selectedItems ||
				!ctrl.pagedList.selectedItems.length ||
				ctrl.pagedList.selectedItems.length > 100
			);
		}

		function edit(testWindow, enabled) {
			if (buttonsDisabled()) {
				return;
			}
			var params = {
				className: 'ngdialog-theme-default',
				template: 'components/test.enablement.organization/modal.html',
				controller: 'TestEnablementOrganizationModalController',
				controllerAs: 'ctrl',
				data: {
					organizations: ctrl.pagedList.selectedItems,
					test: ctrl.testType,
					enabled: enabled,
					testWindow: testWindow,
					setting: getWindowSettingsField(),
					callback: onInit
				}
			};
			ngDialog.open(params);
		}

		function showSelectedModal() {
			if (buttonsDisabled()) {
				return;
			}
			var params = {
				className: 'ngdialog-theme-default',
				template: 'components/test.enablement.organization/selected.modal.html',
				controller: 'TestEnablementOrganizationSelectedItemsModalController',
				controllerAs: 'ctrl',
				data: {
					organizations: ctrl.pagedList.selectedItems
				}
			};
			ngDialog.open(params);
		}

		function getWindowSettingsField(_type, _window) {
			_type = _type || ctrl.testType;
			_window = _window || ctrl.testWindow;
			return [_type, testSettingsFields[_window]].join('');
			// return [ctrl.testType, testSettingsFields[ctrl.testWindow]].join('');
		}

		function onInit() {
			if ((ctrl.pagedList && ctrl.pagedList.busy) || !ctrl.organization || !ctrl.organization.id) {
				return;
			}

			ctrl.testType = !ctrl.testWindow || ctrl.testWindow === ctrl.testWindows.beginning ? testTypes.lexile : testTypes.growth;

			ctrl.pagedList = new PagedList(Organization.children, ctrl.organization.id);
			ctrl.pagedList.params = {
				organizationType: 'School',
				id: ctrl.organization.id,
				additionalFields: [
					'growthSettingsBoy',
					'growthSettingsMoy',
					'growthSettingsEoy',
					'lexileSettingsBoy',
					'lexileSettingsMoy',
					'lexileSettingsEoy'
				],
				sortby: 'name',
				desc: false
			};
			ctrl.pagedList.perPage = 30;
			ctrl.pagedList.load();
		}
	}
})();
