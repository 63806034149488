(function() {
	angular.module('student.test.reset').controller('StudentPlacementResetController', controller);

	controller.$inject = [
		'$scope',
		'ReassignAssessmentService',
		'Portal',
		'$filter',
		'ngDialog',
		'resetStatuses',
		'$localStorage',
		'$sessionStorage',
		'NotificationFactory',
		'$q'
	];

	function controller(
		$scope,
		ReassignAssessmentService,
		Portal,
		$filter,
		ngDialog,
		resetStatuses,
		$localStorage,
		$sessionStorage,
		NotificationFactory,
		$q
	) {
		const tests = {
			benchmark: 'Benchmark Test',
			placement: 'Placement Test',
			boy: 'Beginning of Year Benchmark Test',
			moy: 'Middle of Year Benchmark Test',
			eoy: 'End of Year Benchmark Test'
		};

		let ctrl = this;
		ctrl.resetPlacement = resetPlacement;
		ctrl.reassignBenchmark = reassignBenchmark;
		ctrl.close = $scope.closeThisDialog;

		ctrl.busy = false;
		ctrl.student = $scope.ngDialogData.student;
		ctrl.test = $scope.ngDialogData.test;
		ctrl.isDemo = $scope.ngDialogData.isDemo;
		ctrl.localizationPath = `growth_common.${ctrl.test.isPlacement ? 'resetPlacement' : 'reassignBenchmark'}.modal`;

		ctrl.title = $filter('translateFormatString')(
			`${ctrl.localizationPath}.title`,
			tests[ctrl.test.period.toLowerCase()],
			ctrl.student.lastName,
			ctrl.student.firstName
		);

		ctrl.reset = reset;

		function reset() {
			ctrl.test.isPlacement ? ctrl.resetPlacement() : ctrl.reassignBenchmark();
		}

		function resetPlacement() {
			let promise = ctrl.isDemo ? $q.resolve({}) : ReassignAssessmentService.resetPlacement(Portal.product, ctrl.student.id);
			promise
				.then(() => {
					openConfirmationModal();
					saveStudentResetStatus(resetStatuses.success);
				})
				.catch(() => {
					showResetError();
					saveStudentResetStatus(resetStatuses.error);
				})
				.finally(() => {
					$scope.ngDialogData.onClose(ctrl.test);
				});
			ctrl.close();
		}

		function reassignBenchmark() {
			let promise = ctrl.isDemo ? $q.resolve({}) : ReassignAssessmentService.resetBenchmark(Portal.product, ctrl.student.id);
			promise
				.then(() => {
					openConfirmationModal();
					saveStudentResetStatus(resetStatuses.success);
				})
				.catch(() => {
					showResetError();
					saveStudentResetStatus(resetStatuses.error);
				})
				.finally(() => {
					$scope.ngDialogData.onClose(ctrl.test);
				});
			ctrl.close();
		}

		function openConfirmationModal() {
			ngDialog.open({
				className: 'ngdialog-theme-default student-test-reset',
				template: 'features/growth/student.test.reset/confirmation.modal.template.html',
				controller: 'StudentPlacementResetConfirmationController',
				controllerAs: '$ctrl',
				closeByNavigation: false,
				closeByDocument: false,
				data: {
					test: ctrl.test
				}
			});
		}

		function saveStudentResetStatus(resetStatus) {
			if (ctrl.isDemo) {
				$sessionStorage.resetStudentsStatus = $sessionStorage.resetStudentsStatus || {};
				$sessionStorage.resetStudentsStatus = {
					...$sessionStorage.resetStudentsStatus,
					[ctrl.student.id]: {
						[ctrl.test.period]: {
							status: resetStatus,
							lastEndDate: ctrl.test.endDate
						}
					}
				};
			} else {
				$localStorage.resetStudentsStatus = $localStorage.resetStudentsStatus || {};
				$localStorage.resetStudentsStatus = {
					...$localStorage.resetStudentsStatus,
					[ctrl.student.id]: {
						[ctrl.test.period]: {
							status: resetStatus,
							lastEndDate: ctrl.test.endDate
						}
					}
				};
			}
		}

		function showResetError() {
			NotificationFactory.error({
				content: $filter('translate')('growth_common.resetError'),
				closeAfter: 5,
				persist: true
			});
			ctrl.close();
		}
	}
})();
