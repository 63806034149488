(function() {
	angular.module('add.demographics.modal').directive('addDemographicsModal', directive);

	directive.$inject = ['ngDialog'];

	function directive(ngDialog) {
		return {
			scope: {
				organization: '=',
				students: '=',
				orgId: '@',
				callback: '&'
			},
			restrict: 'A',
			link: function(scope, element) {
				function show() {
					ngDialog.open({
						width: '100%',
						height: '100%',
						className: 'ngdialog-theme-default full',
						template: 'components/add.demographics.modal/template.html',
						controller: 'AddDemographicsModalController',
						controllerAs: '$ctrl',
						closeByNavigation: true,
						closeByDocument: false,
						data: {
							organization: scope.organization,
							students: scope.students,
							orgId: scope.orgId,
							callback: scope.callback
						}
					});
				}

				element.on('click', show);

				element.on('$destroy', function() {
					element.off('click');
				});
			}
		};
	}
})();
