(function() {
	angular.module('create.groups.modal', [
		'ngDialog',
		'api.manager',
		'ui.notifications',
		'ui.manager.multiselect',
		'guid.service',
		'utils.localization'
	]);
})();
