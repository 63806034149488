(function() {
	angular
		.module('organization.assessment.provider', [])
		.controller('OrganizationAssessmentProviderController', controller)
		.component('organizationAssessmentProvider', {
			bindings: {
				organization: '<'
			},
			controller: 'OrganizationAssessmentProviderController',
			templateUrl: 'components/organization.assessment.provider/organization.assesment.provider.html'
		});

	controller.$inject = ['AssessmentProvider', 'AssessmentProvidersScreenNames'];

	function controller(AssessmentProvider, AssessmentProvidersScreenNames) {
		var ctrl = this;
		ctrl.$onInit = function() {
			ctrl.organization.assessmentProviderGradeOverrides = (ctrl.organization.settings.AssessmentProviderGradeOverride || []).map(
				item => {
					const gradeOverride = item.split(':');
					return { grade: gradeOverride[0], value: AssessmentProvidersScreenNames[gradeOverride[1]] };
				}
			);
			AssessmentProvider.getForOrgAndChildren(ctrl.organization.id).then(assessmentProvider => {
				ctrl.organization.assessmentProvider = AssessmentProvidersScreenNames[assessmentProvider.defaultAssessmentProvider];
			});
		};
	}
})();
