angular.module('oauthSignin').controller('OauthSigninController', OauthSigninController);

OauthSigninController.$inject = ['$interval', '$location', '$log', 'AuthenticationService', 'NotificationFactory', 'OidcUserManager'];
function OauthSigninController($interval, $location, $log, AuthenticationService, NotificationFactory, OidcUserManager) {
	const $ctrl = this;

	let interval;
	let count = 0;

	$ctrl.dots = '';

	$ctrl.navigate = navigate;
	$ctrl.$onInit = $onInit;
	$ctrl.$onDestroy = $onDestroy;

	function navigate(url) {
		window.location.replace(url);
	}

	function $onInit() {
		const defaultUrl = $ctrl.defaultUrl || '/';
		const search = $location.search();
		if (!search?.code || !search?.code?.length) {
			$location.url(defaultUrl).replace();
			return;
		}

		OidcUserManager.initialize();
		AuthenticationService.completeLogin()
			.then(url => {
				if (!!url && !url.match(/^\//)) {
					navigate(url);
				} else {
					$location.url(url || defaultUrl).replace();
				}
			})
			.catch(err => {
				if (err && err.message && err.message === 'No matching state found in storage') {
					$log.debug('No matching state found in storage. Re-initiating login.');
					AuthenticationService.startLogin();
				} else {
					NotificationFactory.error(err);
					AuthenticationService.logOff();
				}
			});

		interval = $interval(() => {
			count = (count + 1) % 6;
			$ctrl.dots = new Array(count).fill('.').join('') + new Array(6 - count).fill('\u00A0').join('');
		}, 500);
	}

	function $onDestroy() {
		$interval.cancel(interval);
	}
}
