(function() {
	angular
		.module('widgets.grade.levels', ['ui.notifications', 'helpers.percentages', 'api.reporting', 'utils.date'])
		.directive('gradeLevelsWidget', directive)
		.controller('GradeLevelsWidgetCtrl', controller);

	directive.$inject = [];

	function directive() {
		return {
			restrict: 'A',
			scope: {
				organization: '=gradeLevelsWidget',
				dateRange: '=',
				widgetTitle: '@'
			},
			controller: controller,
			controllerAs: 'model',
			templateUrl: 'components/grade.levels.widget/grade.levels.html',
			link: function(scope, element, attrs) {
				scope.model.growthView = 'growthView' in attrs;
			}
		};
	}

	controller.$inject = ['$scope', '$q', 'NotificationFactory', 'ReportingAPI', 'schoolYear', 'CurrentDate', 'PercentageHelpers'];

	function controller($scope, $q, NotificationFactory, ReportingAPI, schoolYear, CurrentDate, PercentageHelpers) {
		var navigationLink = "dashboard.grade({ id: 'ITEMID' })";
		var model = this;
		model.id = $scope.$id;
		model.initialized = false;
		model.busy = false;
		model.error = false;

		model.PercentageHelpers = PercentageHelpers;

		model.gradeLevels = undefined;

		model.getNavigationLink = getNavigationLink;
		model.gradeLevelSort = gradeLevelSort;

		var gradeLevelOrder = {
			PrePreK: 0,
			PreK: 1,
			Kindergarten: 2,
			First: 3,
			Second: 4,
			Third: 5,
			Fourth: 6,
			Fifth: 7,
			Sixth: 8,
			Seventh: 9,
			Eighth: 10,
			Ninth: 11,
			Tenth: 12,
			Eleventh: 13,
			Twelfth: 14,
			Other: 15,
			All: 16
		};

		function gradeLevelSort(v1, v2) {
			if (!v1 || !v2) {
				return false;
			}
			return gradeLevelOrder[v1] > gradeLevelOrder[v2];
		}

		function getNavigationLink(item) {
			return navigationLink.replace('ITEMID', item.id);
		}

		function refreshModel() {
			model.busy = true;
			model.error = false;
			model.initialized = false;

			var promise, dataFields;

			if ($scope.dateRange && $scope.dateRange.start && angular.isDate($scope.dateRange.start)) {
				promise = $q.when($scope.dateRange);
			} else {
				promise = schoolYear.get($scope.organization.id);
			}

			if (model.growthView) {
				dataFields = ['counts', 'performance'];
			} else {
				dataFields = ['counts', 'performance'];
			}

			promise
				.then(function(dates) {
					return ReportingAPI.gradeLevels({
						id: $scope.organization.id,
						startDate: dates.start,
						endDate: dates.end && dates.end.getTime() > CurrentDate.get().getTime() ? CurrentDate.get() : dates.end,
						dataFields: dataFields
					});
				})
				.then(function(result) {
					model.gradeLevels = result.items || [];
					model.gradeLevels = _.sortBy(model.gradeLevels, function(grade) {
						return gradeLevelOrder[grade.gradeLevel];
					});
				})
				['catch'](function(error) {
					model.error = NotificationFactory.error(error);
				})
				['finally'](function() {
					model.busy = false;
					model.initialized = true;
				});
		}

		$scope.$watch(
			'organization',
			function(value) {
				if (value && !_.isEmpty(value)) {
					if (!model.busy && value.id && value.id.length) {
						refreshModel();
					}
				} else {
					model.data = undefined;
				}
			},
			true
		);

		$scope.$watch(
			'dateRange',
			function(value) {
				if (value && !model.busy && $scope.organization && $scope.organization.id) {
					refreshModel();
				}
			},
			true
		);
	}
})();
