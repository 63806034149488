(function() {
	angular.module('test.enablement.organization').controller('TestEnablementOrganizationSelectedItemsModalController', controller);

	controller.$inject = ['$scope'];

	function controller($scope) {
		// props
		var ctrl = this;
		ctrl.data = undefined;

		// methods
		ctrl.init = init;

		function init() {
			ctrl.data = angular.copy($scope.ngDialogData);
		}
		init();
	}
})();
