(function() {
	angular
		.module('kpi.organization.licenses', ['ui.notifications', 'api.manager'])
		.directive('organizationLicensesKpi', directive)
		.controller('OrganizationLicensesKpiCtrl', controller);

	directive.$inject = [];

	function directive() {
		return {
			restrict: 'A',
			scope: {
				id: '=organizationLicensesKpi',
				widgetTitle: '@'
			},
			controller: controller,
			controllerAs: 'model',
			templateUrl: 'components/organization.licenses.kpi/organization.licenses.html',
			link: function(scope, element, attrs) {}
		};
	}

	controller.$inject = ['$scope', 'NotificationFactory', 'Organization'];

	function controller($scope, NotificationFactory, Organization) {
		var testLicenses = [
			{
				id: 'bee9eae2-2b5b-4c92-b332-a621016da095',
				licenseType: 'Standard',
				productType: 'Spanish',
				quantity: 2200,
				start: '2014-12-16T07:00:00Z',
				end: '2015-12-31T07:00:00Z'
			},
			{
				id: '652a13f5-9e25-40cc-940c-a621016da15a',
				licenseType: 'Standard',
				productType: 'English',
				quantity: 10,
				start: '2015-05-01T06:00:00Z',
				end: '2016-04-30T06:00:00Z'
			},
			{
				id: 'ad955356-5be0-488a-b515-a621016da1b0',
				licenseType: 'Standard',
				productType: 'English',
				quantity: 1300,
				start: '2014-11-30T07:00:00Z',
				end: '2015-11-30T07:00:00Z'
			},
			{
				id: 'f5e0e16e-c4e8-406e-bcbc-a621016da1f6',
				licenseType: 'Standard',
				productType: 'English',
				quantity: 1,
				start: '2015-03-30T06:00:00Z',
				end: '2016-03-30T06:00:00Z'
			},
			{
				id: 'dce60474-9887-4960-8a1a-a621016da20c',
				licenseType: 'Standard',
				productType: 'English',
				quantity: 10,
				start: '2016-04-30T06:00:00Z',
				end: '2016-09-30T06:00:00Z'
			},
			{
				id: '81be574f-3e80-490a-aeb8-a621016da24e',
				licenseType: 'Standard',
				productType: 'English',
				quantity: 1300,
				start: '2015-11-30T07:00:00Z',
				end: '2016-09-30T06:00:00Z'
			},
			{
				id: '281cf9f9-7539-4d73-8c96-a621016da291',
				licenseType: 'Standard',
				productType: 'English',
				quantity: 200,
				start: '2015-09-30T06:00:00Z',
				end: '2016-09-30T06:00:00Z'
			},
			{
				id: 'f0332372-527f-475e-a0d7-a621016da2d7',
				licenseType: 'Standard',
				productType: 'Spanish',
				quantity: 50,
				start: '2015-12-31T07:00:00Z',
				end: '2016-09-30T06:00:00Z'
			},
			{
				id: '1da4ddfb-e324-4bbe-86c2-a621016da2ed',
				licenseType: 'Standard',
				productType: 'Spanish',
				quantity: 75,
				start: '2015-11-17T07:00:00Z',
				end: '2016-09-30T06:00:00Z'
			}
		];

		var model = this;
		model.initialized = false;
		model.licenses = [];
		model.busy = true;
		model.errorId = false;

		model.refreshModel = refreshModel;

		$scope.$watch(
			'id',
			function(value, oldValue) {
				if (value) {
					refreshModel(value);
				} else {
					model.licenses = [];
				}
			},
			true
		);

		function refreshModel(id) {
			model.errorId = model.initialized = false;
			Organization.licenses(id)
				.then(function(results) {
					model.licenses = testLicenses; //results;
				})
				['catch'](function(error) {
					model.errorId = NotificationFactory.error(error);
				})
				['finally'](function() {
					model.busy = false;
					model.initialized = true;
				});
		}
	}
})();
