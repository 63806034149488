(function() {
	angular
		.module('global.search.input', ['ui.router', 'global.search.service', 'search.common.localization'])
		.directive('ilGlobalSearchInput', directive)
		.controller('GlobalSearchInputController', controller);

	controller.$inject = ['$scope', '$state', '$timeout', 'GlobalSearchService'];

	function controller($scope, $state, $timeout, GlobalSearchService) {
		var model = this;

		// props
		model.query = '';
		model.GlobalSearchService = GlobalSearchService;
		model.localSources = angular.copy(GlobalSearchService.sources);

		// methods
		model.updateSearchScope = updateSearchScope;
		model.setSearchFilter = setSearchFilter;
		model.search = search;
		model.onKeyEvent = onKeyEvent;
		model.stateChangeSuccess = stateChangeSuccess;

		const filterMap = {
			orgs: {
				searchFilter: 'orgs',
				displayText: 'Organizations'
			},
			groups: {
				searchFilter: 'groups',
				displayText: 'Groups'
			},
			users: {
				searchFilter: 'users',
				displayText: 'Users'
			},
			students: {
				searchFilter: 'students',
				displayText: 'Students'
			},
			activities: {
				searchFilter: 'activities',
				displayText: 'Activities'
			},
			all: {
				searchFilter: 'all',
				displayText: 'Management'
			}
		};

		const serviceToFilter = {
			o: 'orgs',
			g: 'groups',
			u: 'users',
			s: 'students',
			a: 'activities'
		};

		function updateFilter(filterValue) {
			const { searchFilter, displayText } = filterMap[filterValue];
			model.searchFilter = searchFilter;
			model.displayText = displayText;
		}

		function updateFilterFromService() {
			const mapValue = serviceToFilter[GlobalSearchService.getParams()] ?? 'all';
			updateFilter(mapValue);
		}

		if ($state.current.name.match(/^activities/i)) {
			updateFilter('activities');
		} else {
			updateFilterFromService();
		}

		function updateSearchScope() {
			model.localSources = {};
			if (model.searchFilter === 'all') {
				model.localSources = model.GlobalSearchService.emptySources();
			} else {
				model.localSources.organizations = model.searchFilter === 'orgs';
				model.localSources.groups = model.searchFilter === 'groups';
				model.localSources.users = model.searchFilter === 'users';
				model.localSources.students = model.searchFilter === 'students';
				model.localSources.activities = model.searchFilter === 'activities';
			}
		}

		function setSearchFilter(filterValue) {
			updateFilter(filterValue);
			updateSearchScope();
		}

		function onKeyEvent(event) {
			// enter pressed and we have a string
			if (event.which === 13) {
				event.preventDefault && angular.isFunction(event.preventDefault) ? event.preventDefault() : angular.noop;
				if (event.target) {
					event.target.blur();
				}
				search();
			}
		}

		function search() {
			// if(model.query && model.query.length && $scope.toState && $scope.toState.length) {
			if (model.query && model.query.length) {
				updateSearchScope();
				var encQuery = encodeURIComponent(model.query),
					params = GlobalSearchService.sourcesToParams(model.localSources);
				GlobalSearchService.sources = angular.copy(model.localSources);

				if (model.searchFilter === 'activities') {
					$state.go('activities.search', { query: encQuery, sources: params }, { notify: true });
				} else {
					$state.go(
						model.toState && model.toState.length ? model.toState : 'dashboard.search',
						{ query: encQuery, sources: params },
						{ notify: true }
					);
				}
			}
		}

		$scope.$watch(
			function() {
				return GlobalSearchService.query;
			},
			function(value) {
				model.query = value;
			},
			true
		);

		function stateChangeSuccess(event, toState, toParams, fromState, fromParams) {
			if (toState.name && toState.name !== 'dashboard.search' && toState.name !== 'espanol.search') {
				GlobalSearchService.query = '';
			}
		}

		$scope.$on('$stateChangeSuccess', stateChangeSuccess);
	}

	directive.$inject = [];

	function directive() {
		return {
			restrict: 'A',
			replace: true,
			templateUrl: 'components/global.search/global.search.input.html',
			controller: controller,
			controllerAs: 'model',
			bindToController: true,
			scope: {
				toState: '@',
				default: '@'
			},
			link: function(scope, element, attrs, model) {
				element.bind('keydown keypress', scope.model.onKeyEvent);
				element.on('$destroy', function() {
					element.off('keydown keypress', scope.model.onKeyEvent);
				});
				if (model.default) {
					model.searchFilter = model.default;
				}
			}
		};
	}
})();
