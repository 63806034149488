(function() {
	angular
		.module('widgets.organization.settings', [
			'test.enablement',
			'settings.common.localization',
			'ui.date',
			'ui.timeinput',
			'ui.notifications',
			'api.manager',
			'entity.view.model',
			'utils.date'
		])
		.directive('organizationSettingsWidget', directive)
		.controller('OrganizationSettingsWidgetController', controller);

	directive.$inject = [];

	function directive() {
		return {
			restrict: 'A',
			scope: {
				id: '=organizationSettingsWidget',
				sections: '=?',
				widgetTitle: '@'
			},
			controller: controller,
			controllerAs: 'model',
			templateUrl: 'components/organization.settings/organization.settings.html',
			link: function(scope, element, attrs) {}
		};
	}

	controller.$inject = [
		'$rootScope',
		'$scope',
		'$filter',
		'NotificationFactory',
		'managerAPIHelpers',
		'TimeZones',
		'Organization',
		'EntityViewModel',
		'CurrentDate',
		'DateUtils'
	];

	function controller(
		$rootScope,
		$scope,
		$filter,
		NotificationFactory,
		managerAPIHelpers,
		TimeZones,
		Organization,
		EntityViewModel,
		CurrentDate,
		DateUtils
	) {
		var model = this;
		model.details = undefined;
		model.schoolYear = undefined;
		model.usageRules = undefined;
		model.assessments = undefined;

		model.allowedTypes = ['Any', 'Selected'];
		model.allowedDaysType = model.allowedTypes[0];
		model.allowedTimesType = model.allowedTypes[0];

		model.datePickerOptions = {
			changeYear: false,
			changeMonth: false
		};

		model.schoolYearSessionStartDateOptions = {
			changeYear: false,
			changeMonth: false,
			yearRange: '1977:-0'
		};

		model.schoolYearSessionEndDateOptions = {
			changeYear: false,
			changeMonth: false,
			yearRange: '1977:-0'
		};

		model.busy = true;
		model.error = false;
		model.mediaServersBusy = false;
		model.mediaServersError = false;
		model.usageGoalBusy = false;
		model.usageGoalError = false;
		model.cachedUsageGoal = undefined;
		model.synced = false; // is this record updated by a scheduled sync
		model.initialized = false;
		model.organization = undefined;

		model.mediaServers = undefined;
		model.mediaServersFilter = '';
		model.cachedMediaServers = undefined;
		model.backupUsage = undefined;

		model.organizationTypes = ['District', 'School', 'Other'];
		model.timeZones = TimeZones;
		model.daysArray = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
		model.monthsArray = [
			{ name: 'January', value: 1, days: 31 },
			{ name: 'February', value: 2, days: 29 },
			{ name: 'March', value: 3, days: 31 },
			{ name: 'April', value: 4, days: 30 },
			{ name: 'May', value: 5, days: 31 },
			{ name: 'June', value: 6, days: 30 },
			{ name: 'July', value: 7, days: 31 },
			{ name: 'August', value: 8, days: 31 },
			{ name: 'September', value: 9, days: 30 },
			{ name: 'October', value: 10, days: 31 },
			{ name: 'November', value: 11, days: 30 },
			{ name: 'December', value: 12, days: 31 }
		];

		_.map(model.monthsArray, function(month) {
			var counter = 0;
			var result = new Array(month.days);
			month.days = _.map(result, function(day) {
				day = { value: ++counter };
				return day;
			});
			return month;
		});

		// public methods
		model.refreshModel = refreshModel;
		model.daysDirty = daysDirty;
		model.toggleDay = toggleDay;
		model.dayAbbr = dayAbbr;
		model.daySelected = daySelected;
		model.getSmallTime = getSmallTime;
		model.getTimeInDate = getTimeInDate;
		model.defaultRestrictStudentAccessTime = defaultRestrictStudentAccessTime;
		model.clearUsageRestriction = clearUsageRestriction;
		model.daysChanged = daysChanged;
		model.timesChanged = timesChanged;
		model.getMediaServers = getMediaServers;
		model.mediaServersDirty = mediaServersDirty;
		model.updateMediaServers = updateMediaServers;
		model.cancelMediaServers = cancelMediaServers;

		model.getUsageGoal = getUsageGoal;
		model.usageGoalDirty = usageGoalDirty;
		model.updateUsageGoal = updateUsageGoal;
		model.cancelUsageGoal = cancelUsageGoal;
		model.showSection = showSection;

		model.noteBarTestSettingProps = {
			url: '/'
		};

		function showSection(name) {
			var visibleSections =
				$scope.sections && $scope.sections.length
					? _.map($scope.sections, function(section) {
							return _.lowerCase(section);
					  })
					: [];
			return visibleSections && visibleSections.length ? _.indexOf(visibleSections, _.lowerCase(name)) !== -1 : true;
		}

		$scope.$watch(
			'id',
			function(value) {
				if (value) {
					refreshModel(value);
				}
			},
			true
		);

		function mediaServersDirty() {
			if (!model.cachedMediaServers || !model.cachedMediaServers.length) {
				return false;
			}

			var cachedMediaServerIds = _.map(
				_.reject(model.cachedMediaServers, function(mediaServer) {
					return mediaServer.selected !== true;
				}),
				'id'
			).sort();

			var currentMediaServerIds = _.map(
				_.reject(model.mediaServers, function(mediaServer) {
					return mediaServer.selected !== true;
				}),
				'id'
			).sort();

			return !angular.equals(cachedMediaServerIds, currentMediaServerIds);
		}

		function cancelMediaServers() {
			model.mediaServers = angular.copy(model.cachedMediaServers);
		}

		function updateMediaServers() {
			var mediaServerIds = _.map(
				_.reject(model.mediaServers, function(server) {
					return server.selected !== true;
				}),
				'id'
			);
			var payload = {
				mediaservers: [{ ids: mediaServerIds }]
			};

			model.mediaServersBusy = true;
			Organization.updateSettings(model.organization.id, payload)
				.then(function(result) {
					getMediaServers(model.organization.id);
				})
				.catch(function(error) {
					model.mediaServersError = NotificationFactory.error(error);
					model.mediaServersBusy = false;
				});
		}

		function getMediaServers(id) {
			if (!id || !id.length) {
				return;
			}

			var cachedSettings;
			model.mediaServersBusy = true;
			model.mediaServersError = false;
			Organization.siteCode(id)
				.then(function(result) {
					model.siteCode = result;
					// returns a list of ids of the associated media servers
					return Organization.getSettings(id);
				})
				.then(function(result) {
					cachedSettings = result;
					if (model.siteCode) {
						// returns an array of media servers
						return Organization.mediaServers(model.siteCode);
					}
					return $q.when();
				})
				.then(function(result) {
					angular.forEach(result, function(item) {
						item.displayText = item.machineName;
					});
					model.mediaServers = $filter('orderBy')(result, 'machineName');
					// reconcile list of ids to the media servers
					if (model.mediaServers && model.mediaServers.length > 0 && cachedSettings && cachedSettings.mediaservers) {
						if (
							cachedSettings.mediaservers.length &&
							cachedSettings.mediaservers[0].ids &&
							cachedSettings.mediaservers[0].ids.length
						) {
							angular.forEach(model.mediaServers, function(server) {
								server.selected = _.indexOf(cachedSettings.mediaservers[0].ids, server.id) !== -1;
							});
							model.originalMediaServerIds = angular.copy(cachedSettings.mediaservers[0].ids);
						}
					}
					// stash away originally associated
					model.cachedMediaServers = angular.copy(model.mediaServers);
				})
				.catch(function(error) {
					if (error && error.status && error.status === 500) {
						//eat the forbidden error
						model.mediaServers = [];
					} else {
						model.mediaServersError = NotificationFactory.error(error);
					}
				})
				.finally(function() {
					model.mediaServersBusy = false;
				});
		}

		function usageGoalDirty() {
			return !model.cachedUsageGoal ? false : !angular.equals(model.cachedUsageGoal.goalValue, model.editGoalValue);
		}

		function cancelUsageGoal() {
			model.editGoalType = model.cachedUsageGoal.goalType;
			model.editGoalValue = model.cachedUsageGoal.goalValue;
		}

		function getUsageGoal() {
			model.usageGoalBusy = true;
			model.usageGoalError = false;

			Organization.usageGoals(model.organization.id)
				.then(function(goals) {
					model.editGoalDefault = goals.inheritedUsageGoal.goal;
					if (goals.currentUsageGoal && goals.currentUsageGoal.goal) {
						model.editGoalType = 'custom';
						model.editGoalValue = goals.currentUsageGoal.goal;
					} else {
						model.editGoalType = 'default';
						model.editGoalValue = model.editGoalDefault;
					}
					model.cachedUsageGoal = {
						goalType: model.editGoalType,
						goalValue: model.editGoalValue
					};
				})
				.catch(function(error) {
					error = error || {};
					model.usageGoalError = NotificationFactory.error(error);
				})
				.finally(function() {
					model.usageGoalBusy = false;
				});
		}

		function updateUsageGoal() {
			var goal = model.editGoalType === 'default' ? 0 : model.editGoalValue;
			model.usageGoalBusy = true;
			model.usageGoalError = false;

			Organization.updateUsageGoal(model.organization.id, goal)
				.then(function() {
					// model.weeklyGoal = goal || model.editGoalDefault;
					model.cachedUsageGoal = {
						goalType: model.editGoalType,
						goalValue: model.editGoalValue
					};
				})
				.catch(function(error) {
					error = error || {};
					model.usageGoalError = NotificationFactory.error(error);
				})
				.finally(function() {
					model.usageGoalBusy = false;
				});
		}

		function daysChanged() {
			if (model.allowedDaysType === model.allowedTypes[0]) {
				model.usageRules.viewModel.restrictStudentAccessTime.allowedDays = angular.copy(model.daysArray).sort();
			} else {
				model.usageRules.viewModel.restrictStudentAccessTime.allowedDays =
					model.backupUsage && model.backupUsage.allowedDays
						? angular.copy(model.backupUsage.allowedDays)
						: angular.copy(model.daysArray).sort();
			}
		}

		function timesChanged() {
			if (model.allowedTimesType === model.allowedTypes[0]) {
				model.usageRules.viewModel.restrictStudentAccessTime.startTime = getTimeInDate('0:00');
				model.usageRules.viewModel.restrictStudentAccessTime.endTime = getTimeInDate('23:59');
			} else if (model.backupUsage) {
				model.usageRules.viewModel.restrictStudentAccessTime.startTime = angular.copy(model.backupUsage.startTime);
				model.usageRules.viewModel.restrictStudentAccessTime.endTime = angular.copy(model.backupUsage.endTime);
				model.usageRules.viewModel.restrictStudentAccessTime.start = angular.copy(model.backupUsage.start);
				model.usageRules.viewModel.restrictStudentAccessTime.end = angular.copy(model.backupUsage.end);
			}
		}

		function defaultRestrictStudentAccessTime() {
			return {
				start: undefined, // getTimeInDate('00:00'),
				end: undefined, //getTimeInDate('23:59'),
				startTime: getTimeInDate('0:00'),
				endTime: getTimeInDate('23:59'),
				timezone: DateUtils.getLocalTimeZone() || 'US/Pacific',
				allowedDays: angular.copy(model.daysArray).sort()
			};
		}

		function clearUsageRestriction() {
			model.busy = true;
			Organization.update({
				id: model.organization.id,
				restrictStudentAccessTime: null
			})
				.then(function() {
					model.organization.restrictStudentAccessTime = defaultRestrictStudentAccessTime();
					if (model.usageRules && model.usageRules.viewModel) {
						model.usageRules.base.restrictStudentAccessTime = defaultRestrictStudentAccessTime();
						model.usageRules.cancel();
					}
				})
				['catch'](function(error) {
					model.error = NotificationFactory.error(error);
				})
				['finally'](function() {
					model.busy = false;
				});
		}

		function success() {
			NotificationFactory.success({
				heading: $filter('translate')('forms_common.messages.updatedHeader'),
				content:
					(model.organization && model.organization.name ? model.organization.name : '') +
					$filter('translate')('forms_common.messages.updatedMessage'),
				closeAfter: 5
			});
		}

		function refreshModel(id) {
			model.error = model.initialized = false;
			Organization.get(id, {
				additionalFields: [
					'parentName',
					'lexileSettings',
					'growthSettingsBoy',
					'growthSettingsMoy',
					'growthSettingsEoy',
					'schoolYearSessions',
					'restrictStudentAccessTime',
					'isSchoolYearInherited'
				]
			})
				.then(function(results) {
					model.organization = results;

					// is org on a sync schedule
					model.synced = managerAPIHelpers.isEntitySynced(model.organization);

					// // map school year sessions
					// var thisYear = new Date().getFullYear(),
					// 	thisMonth = new Date().getMonth();

					model.organization.schoolYearSessions = _.map(model.organization.schoolYearSessions, function(session) {
						session =
							!session || session === null
								? {
										session: 'MainSession',
										beginMonth: undefined,
										beginDay: undefined,
										endMonth: undefined,
										endDay: undefined
								  }
								: session;

						//session.startDate = session.beginMonth && session.beginDay ? new Date([session.beginMonth > thisMonth ? thisYear - 1 : thisYear, session.beginMonth, session.beginDay].join('/')) : undefined;
						//session.endDate = session.endMonth && session.endDay ? new Date([thisYear, session.endMonth, session.endDay].join('/')) : undefined;
						return session;
					});

					//restrict usage
					if (model.organization.restrictStudentAccessTime) {
						model.organization.restrictStudentAccessTime.startTime = model.organization.restrictStudentAccessTime.start
							? getTimeInDate(model.organization.restrictStudentAccessTime.start)
							: undefined;
						model.organization.restrictStudentAccessTime.endTime = model.organization.restrictStudentAccessTime.end
							? getTimeInDate(model.organization.restrictStudentAccessTime.end)
							: undefined;
						model.allowedTimesType =
							model.organization.restrictStudentAccessTime.start === '00:00' &&
							model.organization.restrictStudentAccessTime.end === '23:59'
								? model.allowedTypes[0]
								: model.allowedTypes[1];
						model.allowedDaysType =
							model.organization.restrictStudentAccessTime.allowedDays.length < 7
								? model.allowedTypes[1]
								: model.allowedDaysType;
					} else {
						model.organization.restrictStudentAccessTime = defaultRestrictStudentAccessTime();
					}
					model.organization.restrictStudentAccessTime.allowedDays = model.organization.restrictStudentAccessTime.allowedDays.sort();
					model.backupUsage = angular.copy(model.organization.restrictStudentAccessTime);

					// model.assessments = new EntityViewModel(
					// 	model.organization,
					// 	['lexileSettings', 'growthSettingsBoy', 'growthSettingsMoy', 'growthSettingsEoy'],
					// 	{
					// 		update: Organization.update,
					// 		success: success
					// 	}
					// );

					model.details = new EntityViewModel(model.organization, ['name', 'organizationType'], {
						update: Organization.update,
						callback: function() {
							$scope.$emit('dashboard.reload');
						},
						success: success
					});

					var schoolYearClone;
					model.schoolYear = new EntityViewModel(model.organization, ['schoolYearSessions', 'isSchoolYearInherited'], {
						preupdate: function() {
							// make sure model.schoolYear.viewModel.schoolYearSessions is null if model.schoolYear.viewModel.isSchoolYearInherited === true
							schoolYearClone = angular.copy(model.schoolYear.viewModel.schoolYearSessions);
							model.schoolYear.viewModel.schoolYearSessions = model.schoolYear.viewModel.isSchoolYearInherited
								? null
								: model.schoolYear.viewModel.schoolYearSessions;
						},

						valid: function() {
							return true;
						},
						update: Organization.update,
						callback: function() {
							$rootScope.$broadcast('OrganizationSchoolYearRefresh');
						},
						success: function() {
							// since model.schoolYear.viewModel.schoolYearSessions can be nullified on pre-update we return orig value
							// here so school year form still exists
							model.schoolYear.viewModel.schoolYearSessions = schoolYearClone;
							success();
						}
					});

					model.usageRules = new EntityViewModel(model.organization, ['restrictStudentAccessTime'], {
						preupdate: function() {
							//all days & times selected
							if (model.allowedDaysType === model.allowedTypes[0] && model.allowedTimesType === model.allowedTypes[0]) {
								model.usageRules.viewModel.restrictStudentAccessTime = null;
							} else {
								model.usageRules.viewModel.restrictStudentAccessTime.start = getSmallTime(
									model.usageRules.viewModel.restrictStudentAccessTime.startTime
								);
								model.usageRules.viewModel.restrictStudentAccessTime.end = getSmallTime(
									model.usageRules.viewModel.restrictStudentAccessTime.endTime
								);
							}
							model.backupUsage = angular.copy(model.usageRules.viewModel.restrictStudentAccessTime);
						},
						update: Organization.update, //updateUsageRules, // Organization.update,
						success: success,
						callback: function() {
							if (model.usageRules.viewModel.restrictStudentAccessTime === undefined) {
								model.usageRules.viewModel.restrictStudentAccessTime = defaultRestrictStudentAccessTime();
							}
						}
					});

					getMediaServers(model.organization.id);
					getUsageGoal();
				})
				['catch'](function(error) {
					model.organization = undefined;
					model.error = NotificationFactory.error(error);
				})
				['finally'](function() {
					model.busy = false;
					model.initialized = true;
				});
		}

		function daysDirty() {
			if (
				!model.usageRules ||
				!model.usageRules.viewModel ||
				!model.usageRules.base ||
				!model.usageRules.viewModel.restrictStudentAccessTime ||
				!model.usageRules.base.restrictStudentAccessTime ||
				!model.usageRules.viewModel.restrictStudentAccessTime.allowedDays ||
				!model.usageRules.base.restrictStudentAccessTime.allowedDays
			) {
				return false;
			}
			return !_.isEqual(
				model.usageRules.viewModel.restrictStudentAccessTime.allowedDays.sort(),
				model.usageRules.base.restrictStudentAccessTime.allowedDays.sort()
			);
		}

		function toggleDay(day) {
			var selectedIndex = _.indexOf(model.usageRules.viewModel.restrictStudentAccessTime.allowedDays, day);
			if (selectedIndex !== -1) {
				model.usageRules.viewModel.restrictStudentAccessTime.allowedDays.splice(selectedIndex, 1);
			} else {
				model.usageRules.viewModel.restrictStudentAccessTime.allowedDays.push(day);
			}
			model.usageRules.viewModel.restrictStudentAccessTime.allowedDays = model.usageRules.viewModel.restrictStudentAccessTime.allowedDays.sort();
		}

		function dayAbbr(day) {
			return day.substring(0, 3);
		}

		function daySelected(day) {
			return !!(
				model.usageRules &&
				model.usageRules.viewModel &&
				model.usageRules.viewModel.restrictStudentAccessTime &&
				model.usageRules.viewModel.restrictStudentAccessTime.allowedDays &&
				model.usageRules.viewModel.restrictStudentAccessTime.allowedDays.length &&
				_.indexOf(model.usageRules.viewModel.restrictStudentAccessTime.allowedDays, day) !== -1
			);
		}

		function getSmallTime(date) {
			return angular.isDate(date) ? $filter('date')(date, 'HH:mm') : date;
		}

		function getTimeInDate(time) {
			var date = CurrentDate.get();
			var split = time.split(':');
			date.setHours(split[0]);
			date.setMinutes(split[1]);
			date.setSeconds(null);
			date.setMilliseconds(null);
			return date;
		}
	}
})();
