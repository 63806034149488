(function() {
	var root = this;
	var translations = {
		en: {
			lists_common: {
				exportToCSV: 'Export to CSV',

				name: 'Name',
				firstName: 'First Name',
				lastName: 'Last Name',
				userName: 'User Name',
				email: 'Email Address',
				password: 'Password',
				gradeLevel: 'Grade Level',
				userType: 'User Type',
				organizationType: 'Organization Type',
				firstLanguage: 'First Language',
				create: 'Create',
				cancel: 'Cancel',
				save: 'Save',
				saveAndCreate: 'Save and create another.',

				error: {
					requiredField: '%s is a required field.',
					fieldMinLength: '%1$s must be at least %2$s characters.',
					fieldMaxLength: '%1$s may not be longer than %2$s characters.',
					required: 'Required field.',
					email: 'Please enter a valid email address.',
					emailConflict: 'This email already exists.',
					studentIdConflict: 'This student id is already in use.',
					userNameConflict: 'This username is already in use.',
					passwordMismatch: 'Password does not match.',
					siteCode: 'Site code entered is already in use.',
					teacherRemoveFromGroupWarning:
						'Removing yourself as an associated staff member of this group will remove it from your list of groups as well.',
					teacherRemoveStudentFromGroupsWarning:
						'Removing this student from all groups will remove them from your list of students as well.'
				},

				messages: {
					noChange: '--',
					noDataAvailable: 'No data available',
					noResults: 'No results found',
					createGroup: 'Create a new group.',
					createOrganization: 'Create a new organization.',
					createUser: 'Create a new User.',
					createStudent: 'Create a new Student.',
					noGroups: 'You have not created any groups at',
					noOrganizations: 'You have not created any organizations at',
					noStudents: 'You have not created any students at',
					noUsers: 'You have not created any users at',
					organizationNameYet: '%s yet.',
					clickToCreate: 'Click this button to create your first.'
				},

				terms: {
					totalUsage: 'Total Usage',
					totalMinutes: 'Total Minutes',
					daysActive: 'Days Active',
					weeksActive: 'Weeks Active',
					lessonsPassed: 'Lessons Passed',
					lessonsCompleted: 'Lessons Completed',
					avgLessonsPassed: 'Avg Lessons Passed',
					avgLessonsCompleted: 'Avg Lessons Completed',
					rate: 'Avg Weekly Usage',
					avgScaledScore: 'Avg Scaled Score',
					scaledScoreGrowth: 'Scaled Score Change',
					beginningScaledScore: 'Beginning Score',
					endingScaledScore: 'Ending Score',
					avgBeginningScore: 'Avg Beginning Score',
					avgEndingScore: 'Avg Ending Score',
					startingScore: 'Starting Score',
					beginningScore: 'Beginning Score',
					endingScore: 'Avg Score YTD',
					targetRange: 'Target Range',
					name: 'Name',
					group: 'Group',
					user: 'User',
					teacher: 'Teacher',
					student: 'Student',
					organizations: 'Organizations',
					gradeLevel: 'Grade Level',
					gradeLevels: 'Grade Levels',
					grade: 'Grade',
					groups: 'Groups',
					users: 'Users',
					teachers: 'Teachers',
					students: 'Students',
					totalStudents: 'Active Students (YTD)',
					totalStudentsDescription: 'Students with at least 1 minute of usage during the school year.',
					totalActiveStudentsYTDDescription: 'Students with at least 1 minute of usage during the school year.',
					activeStudentsInRange: 'Active Students (%s)',
					activeStudents: 'Active Students',
					activeStudentsDescription:
						'Students with at least 1 minute of usage during the time period (as measured from Sunday to Saturday).',
					activeStudentsDescriptionLong:
						'Students with at least 1 minute of usage during the last 4 weeks (as measured from Sunday to Saturday).',
					performance: 'Avg Scaled Score Change',
					studentPerformance: 'Scaled Score Change',
					usageTrend: 'Avg Student Usage (3 wks)',
					usage: 'Usage',
					usageYTD: 'Usage YTD',
					weeklyUsage: 'Usage This Week',
					aboutReport: 'About this Report',
					predictedEOYUsage: 'Predicted EOY Usage',
					usageEOYTarget: 'EOY Target',
					totalYTDUsage: 'Total Usage YTD',
					averageUsage: 'Average Usage',
					usageDaily: 'Daily Usage',
					usageWeekly: 'Weekly Usage',
					averageUsageDaily: 'Average Daily Usage',
					averageUsageWeekly: 'Average Weekly Usage',
					averageYTDUsage: 'Avg Usage YTD',
					averageWeeklyUsage: 'Avg Weekly Usage',
					averagePercentOfDailyGoal: 'Avg Percent of Daily Goal',
					averagePercentOfWeeklyGoal: 'Avg Percent of Weekly Goal',
					percentOfWeeklyGoal: 'Percent of Weekly Usage Goal',
					dailyGoal: 'Daily Goal',
					weeklyGoal: 'Weekly Goal',
					currentWeeklyGoal: 'Current Weekly Goal',
					averagePercentOfCurrentWeeklyGoal: 'Avg Percent of Current Weekly Goal',
					assignedContentCompletedDashboard:
						'Student has completed the curriculum for their assigned grade level and is in <b>Guided Review Mode</b>. See Usage Report for more information.',
					assignedContentCompletedOverTimeDashboard:
						'Student has completed the curriculum for their assigned grade level and has exceeded the estimated %s to complete the <b>Guided Review Mode</b> lesson.',
					estimatedRemainingTimeReviewLesson: 'Estimated time to complete: ',
					assignedContentCompletedUsage:
						'Student has completed the curriculum for their assigned grade level and is in <b>Guided Review Mode</b>. See the Guided Review Mode section below for more information'
				},

				info: {
					usageCalculationTooltip: 'Calculations based on a 5 day week.',
					glmStudentCount: 'Students that have completed a graded activity.',
					glmAvgMovement:
						'The average change in student performance level. For example, an average movement of 1.00 would mean that students typically changed one performance level.',
					glmMovement:
						'The difference between the grade level curriculum that the student was working on at the beginning of the school year and the level they are currently working on.',
					performanceTrendWithRange: 'The average change in scaled score from %1$s to %2$s.',
					performanceTrend: 'The average change in scaled score from the beginning of the school year to the current week.',
					performanceTrendReport: 'The average change in scaled score from the beginning to the end of the selected date range.',
					scaledScoreDescription:
						'The scaled score is a measurement that includes the difficulty of the comprehension skill, text, and accompanying comprehension questions.',
					usageTrend: 'The average student progress toward the combined usage goal for the last three weeks.',
					weeklyUsage: 'The average student progress toward the weekly usage goal.'
				}
			}
		}
	};

	if (root.angular) {
		root.angular.module('lists.common.localization', ['utils.localization']).config(config);
	} else if (typeof module !== 'undefined' && module.exports) {
		// Node.js
		module.exports = translations;
	}

	config.$inject = ['LocalizeProvider'];

	function config(LocalizeProvider) {
		LocalizeProvider.translations(translations);
	}
})();
