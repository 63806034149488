(function() {
	angular
		.module('widgets.organization.organizations.list', [
			'lists.common.localization',
			'growth.common.localization',
			'ui.blank.state',
			'ui.notifications',
			'ui.popup',
			'api.manager',
			'api.reporting',
			'paged.list',
			'utils.date',
			'utils.gradeLevel',
			'usage.helper.service',
			'helpers.percentages'
		])
		.directive('organizationOrganizationsListWidget', directive)
		.controller('OrganizationOrganizationsListWidgetCtrl', controller);

	directive.$inject = [];

	function directive() {
		return {
			restrict: 'A',
			scope: {
				parentOrganization: '=',
				gradeLevel: '=?',
				dateRange: '=',
				widgetTitle: '@'
			},
			controller: 'OrganizationOrganizationsListWidgetCtrl',
			controllerAs: 'model',
			templateUrl: 'components/organization.organizations.list/organization.organizations.list.html',
			link: function(scope, element, attrs) {
				scope.model.espanolUsageView = 'espanolUsageView' in attrs;
				scope.model.growthView = 'growthView' in attrs;
				scope.model.growthTestView = 'growthTestView' in attrs;
				scope.model.usageView = 'usageView' in attrs;
				scope.model.predictiveUsageView = 'predictiveUsageView' in attrs;
				scope.model.lexileView = 'lexileView' in attrs;
				scope.model.lexileStatusView = 'lexileStatusView' in attrs;
				scope.model.gradeLevelMaterialView = 'gradeLevelMaterialView' in attrs;
				scope.model.progressByLessonView = 'progressByLessonView' in attrs;
				scope.model.dashboardView = 'dashboardView' in attrs;
				scope.model.noExport = 'noExport' in attrs;

				element.on('$destroy', function() {
					scope.model.clearPendingRequests();
				});
			}
		};
	}

	controller.$inject = [
		'$scope',
		'$sce',
		'$q',
		'$filter',
		'NotificationFactory',
		'Organization',
		'ReportingAPI',
		'Usage',
		'PagedList',
		'schoolYear',
		'CurrentDate',
		'UsageHelpers',
		'PercentageHelpers',
		'GradeLevel',
		'DateUtils'
	];

	function controller(
		$scope,
		$sce,
		$q,
		$filter,
		NotificationFactory,
		Organization,
		ReportingAPI,
		Usage,
		PagedList,
		schoolYear,
		CurrentDate,
		UsageHelpers,
		PercentageHelpers,
		GradeLevel,
		DateUtils
	) {
		var model = this;
		model.id = $scope.$id;
		model.PercentageHelpers = PercentageHelpers;

		//exposed for export
		model.reportParams = {};
		model.dataFields = [];

		model.initialized = false;
		model.error = false;
		model.organizationTypes = ['Other', 'District', 'School'];

		model.lexileTestRanges = [
			{
				name: 'Beginning of Year Test: ' + $filter('translate')('growth_common.assessments.dates.range1'),
				value: 'beginningOfYear'
			},
			{
				name: 'Middle of Year Test: ' + $filter('translate')('growth_common.assessments.dates.range2'),
				value: 'middleOfYear'
			},
			{
				name: 'End of Year Test: ' + $filter('translate')('growth_common.assessments.dates.range3'),
				value: 'endOfYear'
			}
		];
		model.lexileTestRange = model.lexileTestRanges[0].value;

		model.startDateString = '';
		model.endDateString = '';

		model.organization = {
			name: '',
			organizationType: model.organizationTypes[2]
		};

		model.exportUrl = exportUrl;
		// model.getFilename = getFilename;
		model.refreshModel = refreshModel;
		model.getFieldName = getFieldName;
		model.getAverageUsageMinutes = getAverageUsageMinutes;
		model.clearPendingRequests = clearPendingRequests;
		model.averageOfValues = averageOfValues;
		model.getRangeUsageGoal = getRangeUsageGoal;
		model.getAvgLessonsCompleted = getAvgLessonsCompleted;
		model.getAvgLessonsPassed = getAvgLessonsPassed;

		var resource;

		function clearPendingRequests() {
			if (resource && resource.$cancelRequest) {
				resource.$cancelRequest();
			}
		}

		function averageOfValues(valueArray, valueName) {
			if (!valueArray || !valueArray.length) {
				return 0;
			}

			var values = _.map(valueArray, valueName);
			var sum = _.sum(values);
			return sum ? sum / valueArray.length : 0;
		}

		model.organizationList = new PagedList(function() {
			if (model.espanolUsageView) {
				resource = Usage.organizationOrganizations(model.organizationList.params, true);
			} else {
				resource = ReportingAPI.organization.children(model.organizationList.params);
			}
			return resource.$promise;
		});

		$scope.$watch(
			'parentOrganization',
			function(value) {
				if (value && value.id) {
					refreshModel();
				}
			},
			true
		);

		$scope.$watch(
			'dateRange',
			function(value) {
				if (value && $scope.parentOrganization && $scope.parentOrganization.id) {
					refreshModel();
				}
			},
			true
		);

		$scope.$watch(
			'gradeLevel',
			function(value, last) {
				if (value && $scope.parentOrganization && $scope.parentOrganization.id) {
					refreshModel();
				}
			},
			true
		);

		function getFieldName(text) {
			return $filter('translate')(text);
		}

		function getAverageUsageMinutes(organization) {
			return UsageHelpers.getRangeUsageAverageMinutes(organization, model.reportDivisor);
		}

		function getRangeUsageGoal(rangeUsage) {
			return UsageHelpers.getRangeUsageGoal(rangeUsage, model.usageReportType === 'dailyUsage');
		}

		function refreshModel() {
			model.error = false;

			var promise;
			if ($scope.dateRange && $scope.dateRange.start && angular.isDate($scope.dateRange.start)) {
				promise = $q.when($scope.dateRange);
			} else {
				promise = schoolYear.get($scope.parentOrganization.id);
			}

			if (model.lexileView) {
				model.dataFields = ['counts', 'lexile'];
			} else if (model.lexileStatusView) {
				model.dataFields = ['counts', 'lexileTestStatuses'];
			} else if (model.growthView) {
				model.dataFields = ['counts', 'performance'];
			} else if (model.growthTestView) {
				model.dataFields = ['literacyGradeLevelPerformance'];
			} else if (model.usageView) {
				model.dataFields = ['counts'];
			} else if (model.predictiveUsageView) {
				model.dataFields = ['counts'];
			} else if (model.gradeLevelMaterialView) {
				model.dataFields = ['counts', 'gradeLevelMaterial'];
			} else if (model.progressByLessonView) {
				model.dataFields = ['counts', 'gradeLevelMaterial'];
			} else if (model.dashboardView) {
				model.dataFields = ['counts', 'progress'];
			}

			promise
				.then(function(dates) {
					var reportRange = DateUtils.daysInRange(dates.start, dates.end);
					model.usageReportType = reportRange > 15 ? 'weeklyUsage' : 'dailyUsage';

					model.usageMetric = model.predictiveUsageView
						? 'yearToDateUsage'
						: model.espanolUsageView
						? model.usageReportType
						: model.usageView || model.dashboardView
						? 'rangeUsage'
						: undefined;

					// model.usageMetric = model.predictiveUsageView ? 'yearToDateUsage' :  (model.usageView || model.dashboardView) ? 'rangeUsage' : undefined;
					model.reportDivisor =
						model.usageReportType === 'dailyUsage'
							? DateUtils.weekdaysInRange(dates.start, dates.end)
							: Math.ceil(reportRange / 7);

					if (model.espanolUsageView) {
						model.reportParams = {
							id: $scope.parentOrganization.id,
							grade:
								!$scope.gradeLevel || ($scope.gradeLevel && $scope.gradeLevel.toLowerCase() === 'all')
									? undefined
									: $scope.gradeLevel,
							start: dates.start,
							end: dates.end && dates.end.getTime() > CurrentDate.get().getTime() ? CurrentDate.get() : dates.end,
							intervalSize: model.usageMetric
						};

						model.organizationList.params.id = model.reportParams.id;
						model.organizationList.params.grade = model.reportParams.grade;
						model.organizationList.params.start = model.reportParams.start;
						model.organizationList.params.end = model.reportParams.end;
						model.organizationList.params.intervalSize = model.reportParams.intervalSize;
					} else {
						if (model.usageMetric) {
							model.dataFields.push(model.usageMetric);
						}

						model.reportParams = {
							grade:
								!$scope.gradeLevel || ($scope.gradeLevel && $scope.gradeLevel.toLowerCase() === 'all')
									? undefined
									: $scope.gradeLevel,
							id: $scope.parentOrganization.id,
							startDate: dates.start,
							endDate: dates.end && dates.end.getTime() > CurrentDate.get().getTime() ? CurrentDate.get() : dates.end,
							sortby: 'name',
							dataFields: model.dataFields
						};

						model.organizationList.params.id = model.reportParams.id;
						model.organizationList.params.grade = model.reportParams.grade;
						model.organizationList.params.startDate = model.reportParams.startDate;
						model.organizationList.params.endDate = model.reportParams.endDate;
						model.organizationList.params.dataFields = model.dataFields;
					}

					model.organizationList.params.sortby = 'name';
					model.organizationList.perPage = 10;

					model.startDateString = $filter('date')(model.organizationList.params.startDate, 'MMMM d, y');
					model.endDateString = $filter('date')(model.organizationList.params.endDate, 'MMMM d, y');
					model.organizationList.load();
				})
				['catch'](function(error) {
					model.error = NotificationFactory.error(error);
				})
				['finally'](function() {
					model.initialized = true;
					model.busy = false;
				});
		}

		function exportUrl() {
			if (_.isEmpty(model.reportParams)) {
				return '';
			}
			return $sce.trustAsResourceUrl(ReportingAPI.exportUrls.organization.children(model.reportParams));
		}

		function getAvgLessonsCompleted(item) {
			var completed = _.get(item, 'progress.completed', 0);
			var skipped = _.get(item, 'progress.skipped', 0);
			var totalActiveStudents = _.get(item, 'totalActiveStudents', 0);

			if (totalActiveStudents === 0) {
				return '--';
			} else {
				return (completed / totalActiveStudents).toFixed(0);
			}
		}

		function getAvgLessonsPassed(item) {
			var passed = _.get(item, 'progress.passed', 0);
			var totalActiveStudents = _.get(item, 'totalActiveStudents', 0);

			if (totalActiveStudents === 0) {
				return '--';
			} else {
				return (passed / totalActiveStudents).toFixed(0);
			}
		}
	}
})();
