(function() {
	angular.module('growth.grade.filter', [
		'ui.notifications',
		'api.reporting',
		'api.schoolYear',
		'date.range.service',
		'utils.date',
		'api.application.user'
	]);
})();
