(function() {
	angular.module('legacy.browser.modal').controller('LegacyBrowserModalController', controller);

	controller.$inject = [];

	function controller() {
		var $ctrl = this;

		$ctrl.close = close;

		function close() {
			$ctrl.closeThisDialog();
		}
	}
})();
