(function() {
	var root = this;
	var translations = {
		en: {
			test_enablement: {
				labels: {
					annualBenchmarkTest: 'Beginning of Year Benchmark Test',
					testingDates: 'Testing Dates',
					readingLevel: 'Reading Level Assessment',
					benchmark: 'Benchmark Test',
					beginning: 'Beginning of Year',
					middle: 'Middle of Year',
					end: 'End of Year'
				},
				descriptions: {
					alwaysEnabled: '· This test is always enabled as part of the Imagine Language and Literacy program.',
					annualBenchmarkTest:
						'This test provides a baseline for comparison to show growth in student literacy and oral language skills. It is given to returning students, and may automatically adjust student placement as needed. The initial placement test serves as the baseline for students starting the program. This test can be enabled any time, but can only be administered once each year during the testing dates.',
					readingLevelAssessment:
						'This test provides a measurement of students reading comprehension ability using the Lexile Framework for Reading. This test is given at the beginning, middle, and end of the school year to students in Grade 2 and above who have completed the Imagine Learning Beginning Books curriculum. By default, this test is disabled for all students for the current school year.'
				},
				dates: {
					beginning: 'Aug 1 - Oct 31',
					middle: 'Dec 1 - Feb 28',
					end: 'Apr 1 - Jun 30'
				},
				dialog: {
					caution: 'Caution:',
					updateHeading: 'Update Test Status',
					warning: 'Changing the test status will update it for all students in this organization.',
					enabled: 'Enabled',
					enable: 'Enable',
					enabledDescription: 'Turn the %s on for all students in this organization and sub-organizations.',
					enabledNote: 'Note: It may take up to 12–18 hours for it to become available for students.',
					disabled: 'Disabled',
					disable: 'Disable',
					disabledDescription: 'Turn the %s off for all students in this organization and sub-organizations.',
					disabledNote: 'Note: New students can still be placed with the Placement Test.'
				},
				messages: {
					noLongerEditable: 'This test window has passed and is no longer editable.',
					editableAfter: 'This test window can be enabled after the %s window has passed.'
				}
			}
		}
	};

	if (root.angular) {
		root.angular.module('test.enablement').config(config);
	} else if (typeof module !== 'undefined' && module.exports) {
		// Node.js
		module.exports = translations;
	}

	config.$inject = ['LocalizeProvider'];

	function config(LocalizeProvider) {
		LocalizeProvider.translations(translations);
	}
})();
