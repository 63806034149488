(function() {
	angular.module('create.students.modal', [
		'ngDialog',
		'api.manager',
		'ui.notifications',
		'ui.manager.multiselect',
		'ui.directives.autofocus',
		'utils.gradeLevel',
		'guid.service',
		'utils.localization',
		'api.application.user'
	]);
})();
