(function() {
	angular.module('test.ranges', ['utils.date']).service('TestRanges', service);

	service.$inject = ['$filter', 'CurrentDate'];

	function service($filter, CurrentDate) {
		var svc = this;
		// methods
		svc.currentTestRange = currentTestRange;
		svc.latestTestRange = latestTestRange;
		svc.closestTestRange = closestTestRange;
		svc.getTestRange = getTestRange;
		svc.inActiveWindow = inActiveWindow;

		// props
		svc.testRanges = [
			{
				name: $filter('translate')('growth_common.lexileTests.names.beginning'),
				dates: $filter('translate')('growth_common.lexileTests.dates.beginning'),
				value: 'beginningOfYear',
				valueShort: 'beginning'
			},
			{
				name: $filter('translate')('growth_common.lexileTests.names.middle'),
				dates: $filter('translate')('growth_common.lexileTests.dates.middle'),
				value: 'middleOfYear',
				valueShort: 'middle'
			},
			{
				name: $filter('translate')('growth_common.lexileTests.names.end'),
				dates: $filter('translate')('growth_common.lexileTests.dates.end'),
				value: 'endOfYear',
				valueShort: 'end'
			}
		];
		svc.beginning = svc.testRanges[0];
		svc.middle = svc.testRanges[1];
		svc.end = svc.testRanges[2];
		svc.current = getTestRange(currentTestRange(CurrentDate.get()));

		function inActiveWindow() {
			return currentTestRange(CurrentDate.get()) !== undefined;
		}

		function getTestRange(range) {
			if (!range || !range.length) {
				return undefined;
			}

			range = range.toLowerCase();
			if (range.match(/middle/gi)) {
				return svc.testRanges[1];
			}

			if (range.match(/end/gi)) {
				return svc.testRanges[2];
			}

			return svc.testRanges[0];
		}

		// returns the active test range or undefined if not in an active window
		// used to find detect if currently in an active test range
		function currentTestRange(now) {
			if (!now || !angular.isDate(now)) {
				return undefined;
			}

			var month = now.getMonth();
			var day = now.getDate();

			// Beginning of Year Test: Aug 1 - Oct 31
			if (month > 6 && month < 10) {
				return svc.testRanges[0].value;
			}
			// End of Year Test: Apr 1 - Jun 30
			else if (month > 2 && month < 6) {
				return svc.testRanges[2].value;
			}
			// Middle of Year Test: Dec 1 - Feb 28
			else if (month === 11 || month < 2) {
				return svc.testRanges[1].value;
			} else {
				return undefined;
			}
		}

		// returns the latest relevant test range to report results
		// used to find relevant test range to report results
		function latestTestRange(now) {
			if (!now || !angular.isDate(now)) {
				return undefined;
			}

			var month = now.getMonth();
			var day = now.getDate();

			// End of Year Test: Apr 1 - Jun 30
			if (month > 2 && month < 6) {
				return svc.testRanges[2].value;
			}
			// Middle of Year Test: Dec 1 - Feb 28
			else if (month > 10 || month < 3) {
				return svc.testRanges[1].value;
			}
			// Beginning of Year Test: Aug 1 - Oct 31
			else {
				return svc.testRanges[0].value;
			}
		}

		// returns the closest test range to the current date
		// used to find next test range to enable
		function closestTestRange(now) {
			if (!now || !angular.isDate(now)) {
				return undefined;
			}

			var month = now.getMonth();
			var day = now.getDate();

			// End of Year Test: Apr 1 - Jun 30
			if (month > 1 && month < 6) {
				return svc.testRanges[2].value;
			}
			// Middle of Year Test: Dec 1 - Feb 28
			else if (month === 10 || month === 11 || month < 2) {
				return svc.testRanges[1].value;
			}
			// Beginning of Year Test: Aug 1 - Oct 31
			else {
				return svc.testRanges[0].value;
			}
		}
	}
})();
