(function() {
	angular.module('add.demographics.modal').controller('AddDemographicsModalController', controller);

	controller.$inject = ['$scope', 'Localize', 'NotificationFactory', 'ApplicationUserService', 'DemographicsSettings'];

	function controller($scope, Localize, NotificationFactory, ApplicationUserService, DemographicsSettings) {
		var ctrl = this;
		ctrl.students = $scope.ngDialogData.students;
		ctrl.callback = $scope.ngDialogData.callback;
		ctrl.organization = $scope.ngDialogData.organization || ApplicationUserService.getUser().organization;
		ctrl.organizationIds = ApplicationUserService.getUser().organizationIds;
		ctrl.allowedDemographics = [];

		//
		ctrl.init = init;
		ctrl.getAllowedDemographics = getAllowedDemographics;
		ctrl.toggleDemographic = toggleDemographic;
		ctrl.showDemographicsTable = false;

		function getAllowedDemographics() {
			return DemographicsSettings.get(ctrl.organizationIds[0]).then(function(results) {
				ctrl.allowedDemographics = results.allowedDemographics;
			});
		}

		function toggleDemographic(student, demographic) {
			var index = student.demographics.indexOf(demographic);
			if (index === -1) {
				student.demographics.push(demographic);
			} else {
				student.demographics.splice(index, 1);
			}
		}

		function init() {
			ctrl.busy = true;

			_.forEach(ctrl.students, function(student) {
				if (!student.demographics) {
					student.demographics = [];
				}
			});

			ctrl.getAllowedDemographics().then(function() {
				ctrl.busy = false;
			});
		}

		init();
	}
})();
