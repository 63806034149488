(function() {
	angular.module('donut.chart').controller('DonutChartController', controller);

	controller.$inject = ['$element'];

	function controller($element) {
		var $ctrl = this;

		$ctrl.$onInit = onInit;
		$ctrl.$onChanges = onChanges;

		function onChanges(changesObj) {
			if (changesObj.percent && changesObj.percent.currentValue) {
				onInit();
			}
		}

		function onInit() {
			var percent, bar, degrees, css, slice, fill, after;

			percent = parseInt($ctrl.percent || '0');
			percent = isNaN(percent) ? 0 : percent > 100 ? 100 : percent;
			degrees = percent * 3.6 + 'deg';

			// fill angle
			bar = $element.find('.donut .bar');
			css = ['rotate(', degrees, ')'].join('');
			bar.css('-webkit-transform', css);
			bar.css('-moz-transform', css);
			bar.css('-ms-transform', css);
			bar.css('-o-transform', css);
			bar.css('transform', css);

			// extra css for fill angle > 50%
			if (percent > 50) {
				slice = $element.find('.donut .slice');
				slice.toggleClass('rect-auto', true);

				fill = $element.find('.donut .fill');
				fill.toggleClass('pie', true);
				fill.toggleClass('pie-fill', true);

				after = $element.find('.donut .bar:after');
				after.toggleClass('pie-fill', true);
			}
		}
	}
})();
