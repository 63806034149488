(function() {
	var root = this;
	var translations = {
		en: {
			terms_common: {
				home: 'Home',

				pdfReport: {
					message:
						'Reports for large organizations or groups may take ' +
						'a few minutes to generate.<br /> If your web browser uses an ' +
						'ad blocker you will need to disable it and enable pop-ups to ' +
						'download your PDF file.'
				},

				activitymenu: 'Activity Menu',
				manager: 'Management',
				teachersManager: 'Students & Groups',
				skills: 'Skills',

				blankStates: {
					icons: {
						chart: 'icon-bar-chart',
						table: 'icon-table',
						grade: 'icon-list',
						organization: 'icon-institution',
						group: 'icon-class'
					},

					noGroups: {
						title: 'No groups match report criteria',
						description: 'No groups match the report filter criteria, or no groups are directly assigned to this organization.'
					},

					noOrganizations: {
						title: 'No organizations match report criteria',
						description:
							'No organizations match the report filter criteria, or no organizations are directly assigned to this organization.'
					}
				},
				grammarLevels: {
					basic: 'Basic',
					beginning: 'Beginning Academic',
					intermediate: 'Intermediate Academic',
					advanced: 'Advanced Academic'
				},

				labels: {
					timePeriod: 'Time Period:',
					gradeLevel: 'Grade:'
				},

				about_plg:
					'This report compares the percentage of students working above, on, and below grade level at the beginning of the year and today’s date. This report only includes students who have completed at least one activity at any grade level since the beginning of the year.',

				unassigned: 'Unassigned Students',
				unassignedStudents: 'Unassigned Students',
				unassignedActiveStudents: 'Unassigned Active Students',

				showChart: 'Show Chart',
				hideChart: 'Hide Chart',
				noDataAvailable: 'No data available',

				organization: 'Organization',
				grade: 'Grade',
				group: 'Group',
				user: 'Staff',
				student: 'Student',

				studentsLastWeekSnapshot: 'Students - Last Week Snapshot',
				studentsThisWeekSnapshot: 'Students - This Week Snapshot',
				students4WeekSnapshot: 'Students - 4 Week Snapshot',
				organizations4WeekSnapshot: 'Organizations - 4 Week Snapshot',
				groups4WeekSnapshot: 'Groups - 4 Week Snapshot',

				organizations: 'Organizations',
				grades: 'Grades',
				groups: 'Groups',
				users: 'Staff',
				students: 'Students',

				administrator: 'Administrator',
				teacher: 'Teacher',
				gradeLevel: 'Grade Level',
				gradeLevels: 'Grade Levels',
				teachers: 'Teachers',
				provisioning: 'Provisioning',
				organizationInfo: 'Organization Info',
				schoolYear: 'School Year',
				assessments: 'Assessments',
				studentTesting: 'Student Testing',
				beginningOfYearGrowthTest: 'Beginning of Year Benchmark Test',
				BoyGrowthTest: 'Beginning of Year Benchmark Test',
				MoyGrowthTest: 'Middle of Year Benchmark Test',
				EoyGrowthTest: 'End of Year Benchmark Test',
				beginningOfYearTest: 'Beginning of Year Test',
				middleOfYearTest: 'Middle of Year Test',
				endOfYearTest: 'End of Year Test',
				annualGrowthAssessment: 'Annual Benchmark Assessment',
				readingLevelAssessment: 'Reading Level Assessment',
				benchmarkTests: 'Benchmark Tests',
				associatedStudents: 'Associated Students',
				average: 'Average',
				weeklyUsage: 'By Week',
				predictive: 'Predicted EOY',
				previewMessage: 'This is part of a preview of our improved analytics offerings. More features and data will be added soon.',
				overview: 'Overview',
				usage: 'Usage',
				progress: 'Progress',
				growthOverview: 'Growth Overview',
				scaledScore: 'Scaled Score',
				benchmarkTest: 'Benchmark Test',
				growth: 'Growth',
				portfolio: 'Portfolio',
				settings: 'Settings',
				achievements: 'Achievements',
				global: 'Global',
				studentName: 'Student Name',
				actionAreas: 'Action Areas',
				readingComprehension: 'Reading Comprehension',
				lexileQualified: 'RLA Qualified',
				lexileNotQualified: 'Not Qualified',

				dashboard: 'Dashboard',

				name: 'Name',
				placement: 'Placement',
				changeYtd: 'Change YTD',
				ytd: 'YTD',
				targetRange: 'TargetRange',
				currentTargetRange: 'Current Target Range',
				atOrAboveTargetRange: 'At or Above Target Range',

				grade1: 'Grade 1',
				grade2: 'Grade 2',
				grade3: 'Grade 3',
				grade4: 'Grade 4',
				grade5: 'Grade 5',
				grade6: 'Grade 6',

				level1: 'Level 1',
				level2: 'Level 2',
				level3: 'Level 3',
				level4: 'Level 4',

				beginning: 'Beginning',
				middle: 'Middle',
				end: 'End',

				startofYear: 'Start of Year',
				yearStart: 'Year Start',
				start: 'Start',
				current: 'Current',

				benchmarkTestStatus: 'Benchmark Test Status',
				benchmarkTestGains: 'Benchmark Test Gains',
				benchmarkTestPerformance: 'Benchmark Test Performance',

				readingLevelAssessmentStatus: 'Reading Level Assessment Status',
				readingLevelAssessmentGains: 'Reading Level Assessment Gains',
				readingLevelAssessmentPerformance: 'Reading Level Assessment Performance',

				studentsEligible: 'Eligible Students',

				assessmentTerms: {
					status: {
						completed: 'Completed',
						testedOut: 'Tested Out',
						notReady: 'Not Ready',

						inProgress: 'In Progress',
						notStarted: 'Not Started',
						expired: 'Expired'
					},

					description: {
						eligible: 'Students who are assigned to a group.',
						completed: 'Completed the test for the selected period.',
						testedOut: 'Completed the test for the selected period.',
						notReady:
							'Performance on tests of prerequisite skills indicates the student is not yet ready to be assessed on the literacy and/or oral vocabulary skills targeted by the test.',
						inProgress: "Started the test, but haven't completed it.",
						notStarted: "Eligible to take the test, but haven't started it or the test has been disabled.",
						expired: "Started the test, but didn't complete it within 28 calendar days."
					},
					nextSteps: {},
					benchmark: {
						testedOutDescription: 'Tested out of grade level content for the literacy and/or oral vocabulary skills.'
					}
				},
				performanceLevels: {
					columnHeaders: {
						advanced: 'Advanced',
						intermediate: 'Intermediate',
						beginner: 'Beginner',
						basic: 'Basic',

						aboveLevel: 'Above',
						onLevel: 'On',
						belowLevel: '1 Below',
						farBelowLevel: '2+ Below',
						proficient: 'Proficient',
						below: 'Below'
					},
					description: {
						above: 'Students above level',
						advanced: 'Students advanced level',
						basic: 'Students basic level',
						beginner: 'Students beginning level',
						below: 'Students 1 level below',
						farBelow: 'Students 2 or more levels below',
						intermediate: 'Students intermediate level',
						on: 'Students on level',
						oneBelow: 'Students 1 level below',
						onGrade: 'Students on level',
						onGradeLevel: 'Students on level',
						proficient: 'Students showing proficiency',
						twoBelow: 'Students 2 or more levels below'
					}
				},
				timeFrames: {
					beginningOfYear: 'Beginning of Year',
					endOfYear: 'End of Year',
					endOfCurrentTimeFrame: 'End of Current Time Frame'
				}
			}
		}
	};

	if (root.angular) {
		root.angular.module('terms.common.localization', ['utils.localization']).config(config);
	} else if (typeof module !== 'undefined' && module.exports) {
		// Node.js
		module.exports = translations;
	}

	config.$inject = ['LocalizeProvider'];

	function config(LocalizeProvider) {
		LocalizeProvider.translations(translations);
	}
})();
