(function() {
	angular
		.module('widgets.student.achievements', ['usage.common.localization', 'ui.notifications', 'generate.pdf.modal', 'utils.date'])
		.constant('StudentAchievementsWidgetContent', {
			noResults: 'No results for the date range selected'
		})
		.directive('studentAchievementsWidget', directive)
		.controller('StudentAchievementsWidgetController', controller);

	directive.$inject = [];

	function directive() {
		return {
			restrict: 'A',
			scope: {
				student: '=studentAchievementsWidget',
				widgetTitle: '@',
				dateRange: '=?'
			},
			controller: controller,
			controllerAs: 'model',
			templateUrl: 'features/progress/achievements/student.achievements.widget/student.achievements.html'
		};
	}

	controller.$inject = [
		'$scope',
		'ApplicationUserService',
		'NotificationFactory',
		'schoolYear',
		'DateRangeService',
		'StudentAchievementsWidgetContent',
		'ReactDependenciesService'
	];

	function controller(
		$scope,
		ApplicationUserService,
		NotificationFactory,
		schoolYear,
		DateRangeService,
		StudentAchievementsWidgetContent,
		ReactDependenciesService
	) {
		var model = this;
		model.content = StudentAchievementsWidgetContent;
		model.dateRange = undefined;
		model.session = undefined;
		model.initialized = false;
		model.busy = false;
		model.certificateBusy = false;
		model.error = false;
		model.data = {};

		model.refreshModel = refreshModel;
		model.rangeChanged = rangeChanged;

		function studentAchievementsDateRangeChanged(dateRange) {
			model.dateRanges = dateRange;
			DateRangeService.updateRangeParams(model.dateRanges, $scope);
		}

		$scope.$watchCollection(
			'student',
			function(value) {
				if (value && value.id && !model.busy) {
					refreshModel();
				} else {
					model.data = {};
				}
			},
			true
		);

		$scope.$watch(
			'dateRange',
			function(value) {
				if (value && !angular.equals(model.dateRange, value) && !model.busy) {
					refreshModel(value);
				}
			},
			true
		);

		function rangeChanged() {
			if (model.dateRange && !model.busy && $scope.student && $scope.student.id) {
				refreshModel(model.dateRange);
			}
		}

		function refreshModel(dateRange) {
			if (
				model.busy ||
				!$scope.student ||
				!$scope.student.id ||
				!$scope.student.organizationIds ||
				!$scope.student.organizationIds.length
			) {
				return;
			}

			model.busy = true;
			model.data = {};
			dateRange = dateRange || $scope.dateRange;

			var intersect = _.intersection($scope.student.organizationIds, ApplicationUserService.getUser().organizationIds);
			intersect = intersect && intersect.length ? intersect[0] : $scope.student.organizationIds[0];

			schoolYear
				.get(intersect)
				.then(function(dates) {
					model.session = dates;
					model.last4Weeks = DateRangeService.last4Weeks();
					model.yearToDate = DateRangeService.yearToDate(model.session);
					model.dateRange = dateRange || model.dateRange || model.last4Weeks;
				})
				.then(function() {
					model.studentAchievementsReportProps = {
						config: ReactDependenciesService.apiConfig(),
						studentId: $scope.student.id,
						onDateRangeChanged: studentAchievementsDateRangeChanged,
						session: model.session,
						entityDetails: {
							name: $scope.student.username,
							id: $scope.student.id
						},
						initialDateRange: {
							start: model.dateRange.start,
							end: model.dateRange.end
						}
					};
				})
				.catch(function(error) {
					model.error = NotificationFactory.error(error);
				})
				.finally(function() {
					model.busy = false;
					model.initialized = true;
					DateRangeService.updateRangeParams(model.dateRange);
				});
		}
	}
})();
