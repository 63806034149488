(function() {
	angular.module('student.test.reset').controller('StudentTestResetLinkController', controller);

	controller.$inject = [
		'$scope',
		'ngDialog',
		'$localStorage',
		'resetStatuses',
		'$q',
		'$filter',
		'ApplicationUserService',
		'$sessionStorage',
		'TestRanges',
		'CurrentDate'
	];

	function controller(
		$scope,
		ngDialog,
		$localStorage,
		resetStatuses,
		$q,
		$filter,
		ApplicationUserService,
		$sessionStorage,
		TestRanges,
		CurrentDate
	) {
		let ctrl = this;
		ctrl.showModal = showModal;
		ctrl.resetTestbutton = {
			tooltipText: $filter('translate')('growth_common.resetNoResult'),
			disabled: true
		};
		ctrl.$onInit = onInit;
		ctrl.isDemo = false;
		ctrl.demoTest;

		$scope.$watch(
			function() {
				return ctrl.test;
			},
			onTestUpdate,
			true
		);

		const benchmarkPeriods = {
			boy: 'Beginning of Year',
			moy: 'Middle of Year',
			eoy: 'End of Year'
		};

		const outcomeSummaryCompleted = 'Completed';
		const outcomeSummaryExpired = 'Expired';
		const outcomeSummaryTimedOut = 'TimedOut';
		const testWindowStatusEnabled = 'Enabled';
		const placementTestPeriod = 'placement';

		function onInit() {
			const user = ApplicationUserService.getUser();
			ctrl.isDemo = !!user && !!user.siteCode && user.siteCode === 'demo';
		}

		function showModal() {
			ngDialog.open({
				className: 'ngdialog-theme-default student-test-reset',
				template: 'features/growth/student.test.reset/modal.template.html',
				controller: 'StudentPlacementResetController',
				controllerAs: '$ctrl',
				closeByNavigation: false,
				closeByDocument: false,
				data: {
					student: ctrl.student,
					test: ctrl.isDemo ? ctrl.demoTest : ctrl.test,
					onClose: setResetTestButton,
					isDemo: ctrl.isDemo
				}
			});
		}

		function onTestUpdate(value, lastValue) {
			if (!!value && !_.isEmpty(value) && !_.isEqual(value, lastValue)) {
				if (ctrl.isDemo) {
					ctrl.demoTest = {
						...ctrl.test,
						endDate: CurrentDate.get(),
						testWindowStatus: testWindowStatusEnabled
					};
					setResetTestButton(ctrl.demoTest);
				} else {
					setResetTestButton(ctrl.test);
				}
			}
		}

		function setResetTestButton(test) {
			ctrl.resetTestbutton = {
				tooltipText: $filter('translate')('growth_common.resetNoResult'),
				disabled: true
			};
			if (
				test &&
				test.period &&
				(test.outcomeSummary === outcomeSummaryCompleted ||
					test.outcomeSummary === outcomeSummaryExpired ||
					test.outcomeSummary === outcomeSummaryTimedOut)
			) {
				if (test.isPlacement) {
					if (isStudentReassigned(test.period)) {
						ctrl.resetTestbutton.tooltipText = $filter('translate')('growth_common.resetPlacement.buttonTooltip.done');
						ctrl.resetTestbutton.disabled = true;
					} else {
						ctrl.resetTestbutton.tooltipText = $filter('translateFormatString')(
							'growth_common.resetPlacement.buttonTooltip.active',
							ctrl.student.lastName,
							ctrl.student.firstName
						);
						ctrl.resetTestbutton.disabled = false;
					}
				} else if (!test.isPlacement) {
					if (test.testWindowStatus !== testWindowStatusEnabled) {
						ctrl.resetTestbutton.tooltipText = $filter('translate')(
							'growth_common.reassignBenchmark.buttonTooltip.windowClosed'
						);
						ctrl.resetTestbutton.disabled = true;
					} else if (isStudentReassigned(test.period)) {
						ctrl.resetTestbutton.tooltipText = $filter('translate')('growth_common.reassignBenchmark.buttonTooltip.done');
						ctrl.resetTestbutton.disabled = true;
					} else {
						ctrl.resetTestbutton.tooltipText = $filter('translateFormatString')(
							'growth_common.reassignBenchmark.buttonTooltip.active',
							benchmarkPeriods[test.period],
							ctrl.student.lastName,
							ctrl.student.firstName
						);
						ctrl.resetTestbutton.disabled = false;
					}
				}
			}
		}

		function isStudentReassigned(testPeriod) {
			let studentResetStatus = _.get(
				ctrl.isDemo ? $sessionStorage : $localStorage,
				`resetStudentsStatus.${ctrl.student.id}.${testPeriod}.status`
			);
			return studentResetStatus === resetStatuses.success;
		}
	}
})();
