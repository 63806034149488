(function() {
	angular
		.module('date.range.picker', ['ui.date', 'ui.modal.custom', 'utils.date', 'utils.localization'])
		.constant('DateRangePickerTemplateUrl', 'components/date.range.picker/date.range.picker.html')
		.service('DateRangePicker', service)
		.controller('DateRangePickerController', controller);

	service.$inject = ['CustomModal', 'DateRangePickerTemplateUrl', 'CurrentDate'];

	function service(CustomModal, DateRangePickerTemplateUrl, CurrentDate) {
		this.show = function(callback) {
			CustomModal.show(DateRangePickerTemplateUrl, { callback: callback });
		};

		this.close = function(callback) {
			CustomModal.close();
		};
	}

	controller.$inject = ['$scope', 'CustomModal', 'CurrentDate'];

	function controller($scope, CustomModal, CurrentDate) {
		var form,
			picker = this;
		picker.minDays = 7;
		picker.minRange = 86400000 * picker.minDays;
		picker.maxDays = 365;
		picker.maxRange = 86400000 * picker.maxDays;

		picker.dateOptions = {
			changeYear: false,
			changeMonth: false,
			onClose: onClose
		};

		picker.onKeyPress = onKeyPress;
		picker.cancel = cancel;
		picker.ok = ok;
		picker.isValid = isValid;
		picker.minSpanValid = minSpanValid;
		picker.maxSpanValid = maxSpanValid;

		function onClose(value, picker, $element) {
			// $element.blur();
			if (!form) {
				form = angular.element('#date_range_form');
			}
			form.focus();
		}

		function clearModel() {
			var start = new Date(CurrentDate.get().getTime() - 30 * 24 * 60 * 60 * 1000);
			picker.startDate = new Date(start);
			picker.endDate = CurrentDate.get();
			picker.currentDate = CurrentDate.get();
		}

		clearModel();

		function onKeyPress(event) {
			event.preventDefault();
			event.stopPropagation();
			event.stopImmediatePropagation();
		}

		function cancel() {
			CustomModal.close();
		}

		function ok() {
			if ($scope.params.callback && angular.isFunction($scope.params.callback)) {
				$scope.params.callback({ start: picker.startDate, end: picker.endDate });
			}
			CustomModal.close();
		}

		function minSpanValid() {
			var diffmsecs = Date.parse(picker.endDate) - Date.parse(picker.startDate);
			var diffdays = Math.floor(diffmsecs / (1000 * 60 * 60 * 24));
			return diffdays >= 6;
		}

		function maxSpanValid() {
			var diffmsecs = Date.parse(picker.endDate) - Date.parse(picker.startDate);
			var diffdays = Math.floor(diffmsecs / (1000 * 60 * 60 * 24));
			return diffdays < picker.maxDays;
		}

		// returns true if the start and end dates are valid, and are at least a week apart and no more than a year and a half time span
		function isValid() {
			if (
				!picker.startDate ||
				!picker.endDate ||
				picker.startDate.getTime() > picker.currentDate.getTime() ||
				picker.endDate.getTime() > picker.currentDate.getTime()
			) {
				return false;
			}
			// var diffmsecs = Date.parse(picker.endDate) - Date.parse(picker.startDate);
			// var diffdays = Math.floor(diffmsecs / (1000 * 60 * 60 * 24));
			// console.log(diffdays);
			// return diffdays >= 6 && diffdays < picker.maxDays;
			return minSpanValid() && maxSpanValid();
		}
	}
})();
