(function() {
	angular.module('csv.download.modal').service('CsvDownloadModal', service);

	service.$inject = ['ngDialog', '$state', '$window', '$timeout', '$log', 'NotificationFactory', 'ReportQueue', 'PendoService'];

	function service(ngDialog, $state, $window, $timeout, $log, NotificationFactory, ReportQueue, PendoService) {
		this.start = start;
		this.close = close;
		this.download = download;

		function start(params) {
			ngDialog.open({
				className: 'ngdialog-theme-default csv-download-modal',
				template: 'components/csv.download.modal/template.html',
				controller: 'CsvDownloadModalController',
				controllerAs: 'ctrl',
				data: {
					id: params.id,
					email: params.email,
					completedText: params.completedText,
					displayText: params.displayText
				}
			});
			// PENDO LOCATION_API: remove when using routes
			PendoService.pendoService.updateLocationWithQueryParam('csvModal');
		}

		function close() {
			// PENDO LOCATION_API: remove when using routes
			PendoService.pendoService.revertToBrowserUrl();
			ngDialog.close();
		}

		function _saveData(data, fileName) {
			var a = document.createElement('a');
			$window.document.body.appendChild(a);
			a.style = 'display: none';
			return function(data, fileName) {
				var json = JSON.stringify(data),
					blob = new Blob([json], { type: 'octet/stream' }),
					url = $window.URL.createObjectURL(blob);
				a.href = url;
				a.download = fileName;
				a.click();
				$window.URL.revokeObjectURL(url);
			};
		}

		function download(params) {
			var resource,
				blob,
				busy,
				fileName = 'download.csv',
				a,
				clickUrl;
			resource = ReportQueue.csvExport.download(params);

			if (resource) {
				resource.$promise
					.then(function(response) {
						$window.data = response.data;
						blob = response && response.data ? new Blob([response.data], { type: 'text/csv' }) : undefined;
						if (blob) {
							$timeout(function() {
								if ($window.navigator && $window.navigator.msSaveOrOpenBlob) {
									$window.navigator.msSaveOrOpenBlob(blob, fileName);
								} else {
									clickUrl = $window.URL.createObjectURL(blob);
									a = document.createElement('a');
									$window.document.body.appendChild(a);
									a.style = 'display: none';
									a.href = clickUrl;
									a.download = fileName;
									a.click();
									$window.URL.revokeObjectURL(clickUrl);
								}
							}, 0);
						}
						$state.go('dashboard.organization', {}, { notify: true });
					})
					.catch(function(error) {
						NotificationFactory.error(error);
						$timeout(function() {
							$state.go('dashboard.organization', {}, { notify: true });
						}, 3000);
					})
					.finally(function() {
						busy = false;
					});
			} else {
				busy = false;
			}
		}
	}
})();
