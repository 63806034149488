(function() {
	angular.module('create.student.choice.modal').directive('createStudentChoiceModal', directive);

	directive.$inject = ['ngDialog'];

	function directive(ngDialog) {
		return {
			scope: {
				importWizardUrl: '='
			},
			restrict: 'A',
			link: function(scope, element) {
				function show() {
					ngDialog.open({
						width: '100%',
						height: '100%',
						className: 'ngdialog-theme-default full',
						template: 'components/create.student.choice.modal/template.html',
						controller: 'CreateStudentChoiceModalController',
						controllerAs: '$ctrl',
						closeByNavigation: true,
						closeByDocument: false,
						data: {
							importWizardUrl: scope.importWizardUrl
						}
					});
				}

				element.on('click', show);

				element.on('$destroy', function() {
					element.off('click');
				});
			}
		};
	}
})();
