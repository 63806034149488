(function() {
	angular.module('d.paginator').controller('dPaginatorController', controller);

	controller.$inject = ['$scope'];

	function controller($scope) {
		var ctrl = this,
			initConfig = false;

		ctrl.first = first;
		ctrl.last = last;
		ctrl.forward = forward;
		ctrl.back = back;
		ctrl.onPerPageChange = onPerPageChange;

		ctrl.perPage = {
			limit: 10,
			label: '10 per page'
		};

		ctrl.perPages = [
			{
				limit: 10,
				label: '10 per page'
			},
			{
				limit: 20,
				label: '20 per page'
			},
			{
				limit: 30,
				label: '30 per page'
			},
			{
				limit: 40,
				label: '40 per page'
			},
			{
				limit: 50,
				label: '50 per page'
			},
			{
				limit: 100,
				label: '100 per page'
			},
			{
				limit: 200,
				label: '200 per page'
			}
		];

		$scope.$watch(
			function() {
				return ctrl.config;
			},
			_onPageConfigChange,
			true
		);

		function onPerPageChange() {
			if (
				ctrl.perPage &&
				ctrl.perPage.limit !== ctrl.config.limit &&
				ctrl.refreshCallback &&
				typeof ctrl.refreshCallback === 'function'
			) {
				ctrl.config.limit = ctrl.perPage.limit;
				ctrl.config.offset = 0;
				ctrl.config.page = 1;
				ctrl.config.totalPages = _getTotalPages();
				ctrl.config.record.start = 1;
				ctrl.config.record.end =
					ctrl.config.record.start + ctrl.perPage.limit - 1 < ctrl.config.totalCount
						? ctrl.config.record.start + ctrl.perPage.limit - 1
						: ctrl.config.totalCount;
				ctrl.refreshCallback();
			}
		}

		function _onPageConfigChange(n) {
			if (n && !initConfig && n.totalCount) {
				ctrl.config.page = 1;
				ctrl.config.totalPages = _getTotalPages();
				ctrl.config.record = {};
				ctrl.config.record.start = 1;
				ctrl.config.record.end =
					ctrl.config.record.start + ctrl.perPage.limit - 1 < ctrl.config.totalCount
						? ctrl.config.record.start + ctrl.perPage.limit - 1
						: ctrl.config.totalCount;
				initConfig = true;
			}
			if (n && n.totalCount) {
				ctrl.config.record.end =
					ctrl.config.record.start + ctrl.perPage.limit - 1 < ctrl.config.totalCount
						? ctrl.config.record.start + ctrl.perPage.limit - 1
						: ctrl.config.totalCount;
			}
		}

		function first() {
			if (ctrl.refreshCallback && typeof ctrl.refreshCallback === 'function') {
				if (ctrl.config.page === 1) {
					ctrl.last();
				} else {
					ctrl.config.offset = 0;
					ctrl.config.page = 1;
					ctrl.config.totalPages = _getTotalPages();

					ctrl.config.record.start = 1;
					ctrl.config.record.end = ctrl.config.limit;
				}

				ctrl.refreshCallback();
			}
		}

		function last() {
			if (ctrl.refreshCallback && typeof ctrl.refreshCallback === 'function') {
				if (ctrl.config.page === _getTotalPages()) {
					ctrl.first();
				} else {
					ctrl.config.offset = Math.floor(ctrl.config.totalCount / ctrl.config.limit) * ctrl.config.limit;
					ctrl.config.page = _getTotalPages();
					ctrl.config.totalPages = _getTotalPages();

					ctrl.config.record.start =
						ctrl.config.offset + 1 > ctrl.config.totalCount
							? ctrl.config.offset + 1 - ctrl.config.limit
							: ctrl.config.offset + 1;
					ctrl.config.record.end = ctrl.config.totalCount;
				}

				ctrl.refreshCallback();
			}
		}

		function forward() {
			if (ctrl.refreshCallback && typeof ctrl.refreshCallback === 'function') {
				if (ctrl.config.page === _getTotalPages()) {
					ctrl.first();
				} else {
					ctrl.config.offset = ctrl.config.offset + ctrl.config.limit;
					ctrl.config.page = ctrl.config.page + 1;
					ctrl.config.totalPages = _getTotalPages();

					ctrl.config.record.start = ctrl.config.record.start + ctrl.config.limit;
					ctrl.config.record.end =
						ctrl.config.offset + ctrl.config.limit < ctrl.config.totalCount
							? ctrl.config.offset + ctrl.config.limit
							: ctrl.config.totalCount;
				}

				ctrl.refreshCallback();
			}
		}

		function back() {
			if (ctrl.refreshCallback && typeof ctrl.refreshCallback === 'function') {
				if (ctrl.config.page === 1) {
					ctrl.last();
				} else {
					ctrl.config.offset = ctrl.config.offset - ctrl.config.limit;
					ctrl.config.page = ctrl.config.page - 1;
					ctrl.config.totalPages = _getTotalPages();

					ctrl.config.record.start = ctrl.config.record.start - ctrl.config.limit;
					ctrl.config.record.end = ctrl.config.record.start + ctrl.config.limit - 1;
				}

				ctrl.refreshCallback();
			}
		}

		function _getTotalPages() {
			return Math.ceil(ctrl.config.totalCount / ctrl.config.limit);
		}
	}
})();
