(function() {
	angular
		.module('group.student.navigation')
		.directive('groupStudentNavigationDropDown', directive)
		.controller('GroupStudentNavigationDropDownCtrl', controller);

	controller.$inject = ['GroupStudentNavigation'];

	function controller(GroupStudentNavigation) {
		var ctrl = this;
		ctrl.navigator = GroupStudentNavigation;
		ctrl.open = false;
	}

	directive.$inject = ['$document', 'GroupStudentNavigation'];

	function directive($document, GroupStudentNavigation) {
		return {
			restrict: 'A',
			templateUrl: 'components/group.student.navigation/dropdown.html',
			replace: true,
			controller: 'GroupStudentNavigationDropDownCtrl',
			controllerAs: 'ctrl',
			bindToController: true,
			link: function(scope, element, attrs, ctrl) {
				scope.toggleMenu = function(open) {
					ctrl.open = open !== undefined ? open : !ctrl.open;
					var body = angular.element('body'),
						dropdown = element.find('.student_drop_down');
					dropdown.toggleClass('open', ctrl.open);
					body.toggleClass('no_scroll', ctrl.open);
				};

				$document.on('click touchstart', onClickAndTouch);

				function onClickAndTouch(event) {
					if (!(element.has(event.target).length > 0 || element[0] === event.target)) {
						scope.toggleMenu(ctrl.open);
					}
				}

				scope.$on('$destroy', function handleDestroyEvent() {
					GroupStudentNavigation.clear();
					$document.off('click touchstart', onClickAndTouch);
				});
			}
		};
	}
})();
