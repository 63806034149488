(function() {
	angular.module('common.localization', [
		'forms.common.localization',
		'growth.common.localization',
		'lists.common.localization',
		'progress.common.localization',
		'search.common.localization',
		'settings.common.localization',
		'terms.common.localization',
		'usage.common.localization'
	]);
})();
