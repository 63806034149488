(function() {
	angular.module('student.test.reset').controller('StudentPlacementResetConfirmationController', controller);

	controller.$inject = ['$scope'];

	function controller($scope) {
		let ctrl = this;
		ctrl.test = $scope.ngDialogData.test;
		ctrl.close = $scope.closeThisDialog;
		ctrl.localizationPath = `growth_common.${ctrl.test.isPlacement ? 'resetPlacement' : 'reassignBenchmark'}.confirmation`;
	}
})();
