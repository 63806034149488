(function() {
	angular.module('il.web.components', [
		'add.demographics.modal',
		'application.header',
		'assign.groups.modal',
		'assign.product.modal',
		'create.groups.modal',
		'create.student.choice.modal',
		'create.students.modal',
		'create.users.modal',
		'csv.download.modal',
		'date.range.drop.down',
		'date.range.picker',
		'date.range.service',
		'donut.chart',
		'entity.view.model',
		'filter.pills',
		'generate.pdf.modal',
		'global.search',
		'grade.level.drop.down',
		'group.student.navigation',
		// 'groups.table',
		'guid.service',
		'httpBusy.service',
		'icon.eye.toggle',
		'information.bar',
		'internal.reports.modal',
		'kpi.organization.assessment.status',
		'kpi.organization.licenses',
		'logout.redirect',
		'manager.list',
		'manager.modal.factory',
		'page.title.service',
		'paged.list',
		'portal.service',
		'print.events',
		'qualifier.filter.visibility',
		'resources.links',
		'route.helpers',
		'student.cards',
		'students.edit',
		// 'students.table',
		'test.enablement.organization',
		'test.enablement',
		'test.ranges',
		'test.window.notification',
		'ui.breadcrumbs',
		'ui.tool.tipped.icon',
		'user.account.menu',
		'user.monogram',
		// 'users.table',
		'web.dropdownMenu',
		'web.qrcode',
		'widgets.grade.levels',
		'widgets.group.settings',
		'widgets.group.students.list',
		'widgets.organization.groups.list',
		'widgets.organization.organizations.list',
		'widgets.organization.settings',
		'widgets.student.settings',
		'widgets.user.groups.list',
		'widgets.user.settings'
	]);
})();
