(function() {
	angular.module('il.web.legacy', [
		'common.localization',
		'web.templates',
		'application.header',
		'application.service',
		'global.search',
		'route.helpers',
		'user.account.menu',
		'manager.modal.factory',
		'widgets.user.settings',
		'il.web.filters',
		'test.enablement',
		'test.enablement.organization',
		'test.ranges',
		'test.window.notification',
		'print.events'
	]);
})();
