(function() {
	angular
		.module('test.enablement')
		.controller('TestEnablementController', controller)
		.component('testEnablement', {
			bindings: {
				organization: '<',
				test: '@'
			},
			controller: 'TestEnablementController',
			templateUrl: 'components/test.enablement/component.html'
		});

	controller.$inject = [
		'ngDialog',
		'Organization',
		'NotificationFactory',
		'CurrentDate',
		'TestRanges',
		'testEnablementTestTypes',
		'testEnablementTestWindows',
		'ApplicationUserService'
	];

	function controller(
		ngDialog,
		Organization,
		NotificationFactory,
		CurrentDate,
		TestRanges,
		testEnablementTestTypes,
		testEnablementTestWindows,
		ApplicationUserService
	) {
		var month = new Date().getMonth(),
			ctrl = this;
		ctrl.busy = false;
		ctrl.error = false;
		ctrl.testWindows = testEnablementTestWindows;
		ctrl.testTypes = testEnablementTestTypes;
		ctrl.testSettings = undefined;
		var currentRange = TestRanges.closestTestRange(CurrentDate.get()).replace('OfYear', '');
		ctrl.testWindow = currentRange && currentRange.length ? currentRange : ctrl.testWindows.beginning;

		ctrl.user = ApplicationUserService.getUser();
		ctrl.$onInit = onInit;
		ctrl.$onChanges = onChanges;
		ctrl.edit = edit;
		ctrl.windowEditable = windowEditable;

		function preWindow(testWindow) {
			var result = false;
			if (testWindow === ctrl.testWindows.beginning && month === 6) {
				result = true;
			}
			if (testWindow === ctrl.testWindows.middle && month === 10) {
				result = true;
			}
			if (testWindow === ctrl.testWindows.end && month === 2) {
				result = true;
			}
			return result;
		}

		function windowEditable(testWindow) {
			return ctrl.testWindow === testWindow || preWindow(testWindow);
		}

		function onChanges(changesObj) {
			if (changesObj.organization && changesObj.organization.currentValue) {
				onInit();
			}
		}

		function edit(testWindow) {
			if (!testWindow || ctrl.testWindow !== testWindow) {
				return;
			}

			ngDialog.open({
				className: 'ngdialog-theme-default',
				template: 'components/test.enablement/modal.html',
				controller: 'TestEnablementModalController',
				controllerAs: 'ctrl',
				data: {
					id: ctrl.organization.id,
					test: ctrl.test,
					testWindow: testWindow,
					testSettings: ctrl.testSettings,
					callback: onInit
				}
			});
		}

		function onInit() {
			if (ctrl.busy || !ctrl.organization || !ctrl.organization.id) {
				return;
			}

			ctrl.busy = true;
			var promise;
			if (ctrl.test === ctrl.testTypes.benchmark) {
				promise = Organization.getBenchmarkSettings(ctrl.organization.id);
			} else {
				promise = Organization.getRLASettings(ctrl.organization.id);
			}

			promise
				.then(function(results) {
					ctrl.testSettings = results;
				})
				.catch(function(error) {
					ctrl.error = NotificationFactory.error(error);
				})
				.finally(function() {
					ctrl.busy = false;
				});
		}
	}
})();
