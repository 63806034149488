(function() {
	angular.module('internal.reports.modal').controller('InternalReportsModalController', controller);

	controller.$inject = ['$scope', 'NotificationFactory', 'GeneratePdfModal', 'ReportPrinting', 'schoolYear', 'CurrentDate'];

	function controller($scope, NotificationFactory, GeneratePdfModal, ReportPrinting, schoolYear, CurrentDate) {
		let ctrl = this;

		ctrl.reportNames = {
			partnership: 'Partnership Report',
			activityBrief: 'School Gains Summary Report'
		};

		ctrl.organization = $scope.ngDialogData.organization;
		ctrl.reportPeriod = $scope.ngDialogData.reportPeriod;
		ctrl.reportPeriodString = ctrl.reportPeriod.toLowerCase() === 'middle' ? 'Mid-Year' : 'End-of-Year';
		ctrl.reportName =
			ctrl.organization.organizationType.toLowerCase() === 'district' ? ctrl.reportNames.partnership : ctrl.reportNames.activityBrief;

		ctrl.generate = generate;
		ctrl.printSchoolGainsSummaryReport = printSchoolGainsSummaryReport;
		ctrl.printPartnershipReport = printPartnershipReport;

		function generate() {
			let period = ctrl.reportPeriod.toLowerCase() === 'middle' ? 'qualifiedTestsBM' : 'qualifiedTestsBME';
			schoolYear
				.get(ctrl.organization.id)
				.then(dates => {
					$scope.closeThisDialog();
					ctrl.startDate = dates.start;
					ctrl.endDate = dates.end && dates.end.getTime() > CurrentDate.get().getTime() ? CurrentDate.get() : dates.end;
					ctrl.reportName === ctrl.reportNames.partnership
						? printPartnershipReport(period)
						: printSchoolGainsSummaryReport(period);
				})
				.catch(error => {
					NotificationFactory.error(error);
				});
		}

		function printSchoolGainsSummaryReport(period) {
			GeneratePdfModal.generate(
				ReportPrinting.internalReports.activityBrief({
					orgId: ctrl.organization.id,
					startDate: ctrl.startDate,
					endDate: ctrl.endDate,
					dataFields: [period]
				}),
				'Generate School Gains Summary Report',
				undefined,
				[ctrl.organization.name, ctrl.reportPeriodString, 'School Gains Summary Report.pdf'].join(' - ')
			);
		}

		function printPartnershipReport(period) {
			GeneratePdfModal.generate(
				ReportPrinting.internalReports.partnership({
					orgId: ctrl.organization.id,
					startDate: ctrl.startDate,
					endDate: ctrl.endDate,
					dataFields: [period]
				}),
				'Generate Partnership Report',
				undefined,
				[ctrl.organization.name, ctrl.reportPeriodString, 'Partnership Report.pdf'].join(' - ')
			);
		}
	}
})();
