(function() {
	angular.module('kpi.organization.licenses').config(config);
	config.$inject = ['LocalizeProvider'];

	function config(LocalizeProvider) {
		LocalizeProvider.translations({
			en: {
				licenses: 'Licenses',
				licensing: 'Licensing'
			}
		});
	}
})();
