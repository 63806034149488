(function() {
	angular.module('growth.view.filter').controller('GrowthViewFilterController', controller);

	controller.$inject = ['$timeout', '$state', 'GrowthViewFilter'];

	function controller($timeout, $state, GrowthViewFilter) {
		var ctrl = this;
		ctrl.setView = setView;

		ctrl.$onInit = onInit;
		ctrl.$onChanges = onChanges;

		function onInit() {
			ctrl.views = GrowthViewFilter.getViews();
			$timeout(function() {
				if (ctrl.view && ctrl.view.length) {
					GrowthViewFilter.setCurrentView(ctrl.view);
				} else {
					ctrl.view = GrowthViewFilter.getCurrentView();
				}
				_route();
			});
		}

		function _route() {
			var assessment, targetRoute;
			assessment = $state.current.name.match(/dashboard.organization.assessments.benchmark/gi) ? 'benchmark' : 'lexile';
			targetRoute = 'dashboard.organization.assessments.' + assessment + '.' + ctrl.view;
			$state.go(targetRoute, $state.params, { notify: true, location: 'replace' });
		}

		function onChanges(changesObj) {
			if (changesObj.defaultValue && changesObj.defaultValue.currentValue) {
				onInit();
			}
		}

		function setView(view) {
			if (view && view !== '') {
				GrowthViewFilter.setCurrentView(view);
				ctrl.view = view;
			} else {
				ctrl.view = GrowthViewFilter.getCurrentView();
			}
			_route();
		}
	}
})();
