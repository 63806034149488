(function() {
	angular.module('il.web', [
		'common.localization',
		'web.templates',
		'il.web.components',
		'il.web.features',
		'il.web.filters',
		'react.components'
	]);
})();
