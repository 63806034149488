(function() {
	angular.module('students.edit').controller('StudentsEditModalController', controller);

	controller.$inject = ['$scope', 'GradeLevel', 'NotificationFactory', 'ManagerBulkOperations'];

	function controller($scope, GradeLevel, NotificationFactory, ManagerBulkOperations) {
		var ctrl = this;
		ctrl.callback = $scope.ngDialogData.callback;
		ctrl.students = $scope.ngDialogData.students;
		ctrl.titleText = $scope.ngDialogData.titleText;

		ctrl.differentGradesDetected = false;
		ctrl.syncRecordsDetected = false;

		ctrl.edits = undefined;
		ctrl.gradeLevels = undefined;
		ctrl.report = undefined;

		ctrl.submittable = false;

		ctrl.onInit = onInit;

		ctrl.bulkUpdateStudents = bulkUpdateStudents;
		ctrl.exit = exit;
		ctrl.onFormChange = onFormChange;
		ctrl.removeStudent = removeStudent;
		ctrl.updateGroupsTooltipData = updateGroupsTooltipData;

		ctrl.onInit();

		function onInit(showSelectedStudents) {
			ctrl.report = {};
			ctrl.edits = {
				gradeLevel: '',
				sessionTime: ''
			};
			ctrl.showSelectedStudents = showSelectedStudents !== undefined ? showSelectedStudents : false;
			ctrl.submittable = false;

			initSyncRecordsCheck();
			initGradeLevels();
		}

		function bulkUpdateStudents() {
			var makeEdits = false,
				studentsBatch = _.map(ctrl.students, function(student) {
					return { id: student.id };
				});

			// bulk update student session time
			if (ctrl.edits.sessionTime && ctrl.edits.sessionTime !== '') {
				studentsBatch = _.map(studentsBatch, function(student) {
					student.sessionTime = ctrl.edits.sessionTime;
					return student;
				});
				makeEdits = true;
			}

			// bulk update student grade
			if (ctrl.edits.gradeLevel && ctrl.edits.gradeLevel !== '' && ctrl.edits.gradeLevel !== 'none') {
				studentsBatch = _.map(studentsBatch, function(student) {
					student.gradeLevel = ctrl.edits.gradeLevel;
					return student;
				});
				makeEdits = true;
			}

			if (makeEdits) {
				ManagerBulkOperations.patch
					.students(studentsBatch)
					.then(function(response) {
						NotificationFactory.success({
							heading: 'Student Updates Made',
							content: [response.results.length, 'student records updated successfully'].join(' '),
							closeAfter: 5
						});
						ctrl.exit(true);
					})
					.catch(function(error) {
						NotificationFactory.error(error);
					});
			}
		}

		function exit(triggerCallback) {
			if (!!triggerCallback) {
				ctrl.callback();
			}
			$scope.closeThisDialog();
		}

		function onFormChange() {
			var form = $scope.editForm,
				submittable = {
					sessionTime: false,
					gradeLevel: false
				};

			if (ctrl.edits.sessionTime || ctrl.edits.sessionTime === null) {
				if (parseInt(ctrl.edits.sessionTime) < 10 || parseInt(ctrl.edits.sessionTime) > 120) {
					form.sessionTime.$setValidity('outOfRange', false);
				} else {
					form.sessionTime.$setValidity('outOfRange', true);
				}
				submittable.sessionTime = ctrl.edits.sessionTime !== null && ctrl.edits.sessionTime !== '' && form.sessionTime.$valid;
			}

			if (ctrl.edits.gradeLevel || ctrl.edits.gradeLevel === null) {
				submittable.gradeLevel = ctrl.edits.gradeLevel !== null && ctrl.edits.gradeLevel !== '' && ctrl.edits.gradeLevel !== 'none';
			}

			ctrl.submittable = submittable.sessionTime || submittable.gradeLevel;
		}

		function initGradeLevels() {
			ctrl.gradeLevels = _.map(GradeLevel.getList(), function(grade) {
				return {
					key: GradeLevel.getLongGradeLevelText(grade),
					value: grade
				};
			});
			ctrl.gradeLevels.push({
				key: 'None',
				value: 'none'
			});
			ctrl.differentGradesDetected = false;
			if (ctrl.students && ctrl.students.length) {
				var gradeLevel = [];
				_.forEach(ctrl.students, function(s) {
					if (gradeLevel.indexOf(s.gradeLevel) === -1) {
						gradeLevel.push(s.gradeLevel);
					}
				});
				ctrl.differentGradesDetected = gradeLevel.length > 1;
				if (!ctrl.differentGradesDetected) {
					ctrl.edits.gradeLevel = gradeLevel[0];
				}
			}
		}

		function initSyncRecordsCheck() {
			ctrl.syncRecordsDetected = false;
			if (ctrl.students && ctrl.students.length) {
				_.forEach(ctrl.students, function(student) {
					if (student && student.syncId && student.syncId.length) {
						ctrl.syncRecordsDetected = true;
						return false;
					}
				});
			}
		}

		function removeStudent(student) {
			ctrl.students = _.filter(ctrl.students, function(stu) {
				return stu.id !== student.id;
			});
			ctrl.onInit(true);
			if (ctrl.students.length <= 0) {
				ctrl.exit(true);
			}
		}

		function updateGroupsTooltipData(student) {
			ctrl.groupsTooltipData = {
				name: [student.firstName, student.lastName].join(' '),
				groups: student.groups
			};
		}
	}
})();
