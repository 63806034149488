(function() {
	angular.module('global.search.html.filter', ['global.search.service']).filter('globalSearchHTMLFilter', filter);

	filter.$inject = ['$sce', 'GlobalSearchService'];

	function filter($sce, GlobalSearchService) {
		return function(text) {
			if (text && GlobalSearchService.query) {
				var queries =
					GlobalSearchService.query && GlobalSearchService.query.length ? GlobalSearchService.query.split(' ') : undefined;
				if (queries && queries.length) {
					_.forEach(queries, function(query) {
						// replacing multiple strings posed an interesting problem,
						// 2nd+ replace() included the previously added highlighting markup :O
						// text = text.replace(new RegExp('('+query+')', 'gim'), '<span class="highlighted">$1</span>');

						// avoiding false matches in highlighting mark up using non-alphanumeric chars as placeholders...
						text = text.replace(new RegExp('(' + query + ')', 'gim'), '____<$1>____');
					});
					text = text
						.replace(new RegExp('____<', 'gim'), '<span class="highlighted">')
						.replace(new RegExp('>____', 'gim'), '</span>');
				} else {
					text = text.replace(new RegExp('(' + GlobalSearchService.query + ')', 'gim'), '<span class="highlighted">$1</span>');
				}
			}
			return $sce.trustAsHtml(text);
		};
	}
})();
