(function() {
	var root = this;
	var translations = {
		en: {
			create_groups_modal: {
				titleText: 'Create Groups',
				description: 'Create as many groups as desired. Groups may be created to represent classroom or instructional groupings.',
				groupExistsInListError: 'A group with this name already exists in the list. Please choose another name.',
				groupExistsInOrganizationError: 'A group with this name already exists in this school. Please choose another name.'
			}
		}
	};

	if (root.angular) {
		root.angular.module('create.groups.modal').config(config);
	} else if (typeof module !== 'undefined' && module.exports) {
		module.exports = translations;
	}

	config.$inject = ['LocalizeProvider'];

	function config(LocalizeProvider) {
		LocalizeProvider.translations(translations);
	}
})();
