(function() {
	var root = this;
	var translations = {
		en: {
			unassigned_students: {
				unassignedStudents: 'Unassigned Students',
				name: 'Name',
				gradeLevel: 'Grade Level',
				assignStudentsToGroups: 'Assign Students to Groups',

				tooManySelected: 'Maximum selection count is 200 students.'
			}
		}
	};

	if (root.angular) {
		root.angular.module('unassigned.students.manager').config(config);
	} else if (typeof module !== 'undefined' && module.exports) {
		// Node.js
		module.exports = translations;
	}

	config.$inject = ['LocalizeProvider'];

	function config(LocalizeProvider) {
		LocalizeProvider.translations(translations);
	}
})();
