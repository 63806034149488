(function() {
	angular.module('generate.pdf.modal').factory('GeneratePdfModal', factory);

	factory.$inject = ['CustomModal'];

	function factory(CustomModal) {
		var printReportModalFactory = {};
		printReportModalFactory.generate = generate;

		function generate(resource, displayText, completedText, fileName) {
			var templateUrl = 'components/generate.pdf.modal/template.html';
			var options = {};
			options.displayText = displayText;
			options.completedText = completedText;
			options.overlayClose = false;
			options.fileName = fileName;
			options.promise = resource && resource.$promise ? resource.$promise : resource;
			options.resource = resource && resource.$promise ? resource : undefined;
			CustomModal.show(templateUrl, options);
		}

		return printReportModalFactory;
	}
})();
