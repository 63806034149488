(function() {
	angular.module('ui.breadcrumbs').config(config);
	config.$inject = ['LocalizeProvider'];

	function config(LocalizeProvider) {
		LocalizeProvider.translations({
			en: {}
		});
	}
})();
