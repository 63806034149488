(function() {
	angular.module('route.helpers', ['ui.router']).service('RouteHelpers', service);

	service.$inject = ['$state'];

	function service($state) {
		this.isCurrent = isCurrent;
		this.inDashboards = inDashboards;
		this.inEspanol = inEspanol;
		this.inActivityMenu = inActivityMenu;
		this.inOriginalReports = inOriginalReports;

		function isCurrent(name) {
			var matcher = new RegExp('\\b' + name + '\\b', 'gi');
			if ($state.$current.url && $state.$current.url.prefix && $state.$current.url.prefix.length > 1) {
				var x = $state.$current.url.prefix.match(matcher);
				return x && x.length > 0 ? true : false;
			}
			return false;
		}

		function inDashboards() {
			var matcher = new RegExp(/^\/dashboard\//gi);
			if ($state.$current.url && $state.$current.url.prefix && $state.$current.url.prefix.length > 1) {
				var x = $state.$current.url.prefix.match(matcher);
				return x && x.length > 0 ? true : false;
			}
			return false;
		}

		function inEspanol() {
			var matcher = new RegExp(/^\/espanol\//gi);
			if ($state.$current.url && $state.$current.url.prefix && $state.$current.url.prefix.length > 1) {
				var x = $state.$current.url.prefix.match(matcher);
				return x && x.length > 0 ? true : false;
			}
			return false;
		}

		function inActivityMenu() {
			var matcher = new RegExp(/^\/activities\//gi);
			if ($state.$current.url && $state.$current.url.prefix && $state.$current.url.prefix.length > 1) {
				var x = $state.$current.url.prefix.match(matcher);
				return x && x.length > 0 ? true : false;
			}
			return false;
		}

		function inOriginalReports() {
			var matcher = new RegExp(/^(\/progress\/|\/usage\/|\/growth\/|\/growth|\/portfolio\/)/gi);
			if ($state.$current.url && $state.$current.url.prefix && $state.$current.url.prefix.length > 1) {
				var x = $state.$current.url.prefix.match(matcher);
				return x && x.length > 0 ? true : false;
			}
			return false;
		}
	}
})();
