(function() {
	angular.module('d.paginator').directive('dPaginator', directive);

	directive.$inject = [];

	function directive() {
		return {
			restrict: 'A',
			replace: true,
			bindToController: true,
			controller: 'dPaginatorController',
			controllerAs: 'ctrl',
			scope: {
				config: '=paginationConfig',
				refreshCallback: '&'
			},
			templateUrl: 'components/d.paginator/template.html'
		};
	}
})();
