(function() {
	angular
		.module('test.enablement', [
			'ngDialog',
			'ui.notifications',
			'utils.localization',
			'test.ranges',
			'api.manager',
			'ui.modal.custom',
			'utils.date',
			'utils.featureFlipper'
		])
		.constant('testEnablementTestTypes', {
			benchmark: 'benchmark',
			lexile: 'lexile'
		})
		.constant('testEnablementTestWindows', {
			beginning: 'beginning',
			middle: 'middle',
			end: 'end'
		});
})();
