(function() {
	angular.module('growth.grade.filter').controller('GrowthGradeFilterController', controller);

	controller.$inject = ['$scope', '$state', '$timeout', '$q', '$filter', 'NotificationFactory', 'GrowthGradeFilter'];

	function controller($scope, $state, $timeout, $q, $filter, NotificationFactory, GrowthGradeFilter) {
		var ctrl = this;

		ctrl.busy = false;
		ctrl.hide = false;
		ctrl.grades = [];

		ctrl.$onInit = onInit;
		ctrl.$onChanges = onChanges;
		ctrl.onGradeChange = onGradeChange;

		function onGradeChange() {
			if (ctrl.grade && ctrl.grade !== '') {
				GrowthGradeFilter.setCurrentGrade(ctrl.grade);
			} else {
				ctrl.grade = GrowthGradeFilter.getCurrentGrade();
			}
			_route();
		}

		function onChanges(changesObj) {
			if (changesObj && changesObj.grade && changesObj.grade.currentValue) {
				onGradeChange();
			} else {
				ctrl.grade = GrowthGradeFilter.getCurrentGrade();
			}
		}

		function onInit() {
			ctrl.busy = true;
			GrowthGradeFilter.setContext(ctrl.context);
			GrowthGradeFilter.getGrades(ctrl.context, ctrl.type, ctrl.organizationId, ctrl.modelId)
				.then(function(grades) {
					if (grades === 'no_grades_found') {
						// hide the filter if no grades found
						ctrl.hide = true;
					} else {
						ctrl.hide = false;
						// map grades
						ctrl.grades = _.map(grades, function(item) {
							return {
								label: $filter('gradeLevel')(item),
								value: item
							};
						});
					}
					return $q.when();
				})
				.then(function() {
					ctrl.grade = GrowthGradeFilter.getCurrentGrade();
					_route();
					return $q.when();
				})
				.catch(function(error) {
					ctrl.error = NotificationFactory.error(error);
				})
				.finally(function() {
					ctrl.busy = false;
				});
		}

		function _route() {
			$state.params.grade = ctrl.grade;
			$state.go($state.current.name, $state.params, { notify: true, location: 'replace' });
		}
	}
})();
