(function() {
	angular.module('resources.links', []).directive('resourcesLinks', directive);

	directive.$inject = [];

	function directive() {
		return {
			restrict: 'EA',
			templateUrl: 'components/resources.links/template.html',
			replace: true,
			link: function(scope, element, attrs) {}
		};
	}
})();
