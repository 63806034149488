(function() {
	angular.module('assign.groups.modal').controller('AssignGroupsModalController', controller);

	controller.$inject = ['$scope', 'ngDialog', 'NotificationFactory', 'ManagerAPI', 'ManagerBulkOperations', 'TimedCache', 'Localize'];

	function controller($scope, ngDialog, NotificationFactory, ManagerAPI, ManagerBulkOperations, TimedCache, Localize) {
		// props
		var ctrl = this;
		ctrl.busy = false;

		// methods
		ctrl.addStudentsToGroups = addStudentsToGroups;
		ctrl.newGroupsAdded = newGroupsAdded;
		ctrl.organizationsChanged = organizationsChanged;
		ctrl.init = init;

		function newGroupsAdded(ids) {
			// ctrl.selectedGroups = [];
			ctrl.orgIds = ctrl.school && ctrl.school.id ? [ctrl.school.id] : [];
			ctrl.groupIds = _.concat(ctrl.groupIds, ids);
		}

		function organizationsChanged() {
			ctrl.selectedGroups = [];
			ctrl.groupIds = ctrl.group && ctrl.group.id ? [ctrl.group.id] : [];
			ctrl.orgIds = ctrl.school && ctrl.school.id ? [ctrl.school.id] : [];

			ctrl.group = undefined;
		}

		function addStudentsToGroups() {
			var items = [];

			angular.forEach(ctrl.studentIds, function(id) {
				items.push({
					Id: id,
					groupIds: ctrl.groupIds,
					organizationIds: ctrl.orgIds
				});
			});

			TimedCache.removeAll();
			ManagerBulkOperations.patchOrgsAndGroups
				.students(items)
				.then(function(results) {
					var groupNames = _.map(ctrl.selectedGroups, 'name').join(', ');
					NotificationFactory.success({
						heading: 'Group assignment complete',
						content: [
							ctrl.studentIds.length,
							ctrl.studentIds.length === 1 ? 'student' : 'students',
							'added to ',
							ctrl.groupIds.length === 1 ? 'group: ' : 'groups: ',
							groupNames
						].join(' '),
						closeAfter: 5
					});
					ctrl.studentIds = [];
				})
				.catch(function(error) {
					NotificationFactory.error(error);
				})
				.finally(function(error) {
					$scope.closeThisDialog();
					if (ctrl.callback) {
						ctrl.callback();
					}
				});
		}

		function init() {
			ctrl.titleText = $scope.ngDialogData.titleText || Localize.translateInstant('assign_groups_modal.assignStudentsToGroups');
			ctrl.organization = $scope.ngDialogData.organization;
			ctrl.group = $scope.ngDialogData.group;
			ctrl.students = $scope.ngDialogData.students;
			ctrl.callback = $scope.ngDialogData.callback;

			if (ctrl.group && ctrl.group.organizationId) {
				if (!ctrl.organization || !ctrl.organization.id) {
					ctrl.organization = {};
					ctrl.organization.id = ctrl.group.organizationId;
				}
			}
			ctrl.studentIds = _.map(ctrl.students, 'id');

			ctrl.busy = true;
			var id = _.get(ctrl, 'organization.id');
			if (id && id.length) {
				ManagerAPI.Organization.get(id)
					.then(function(result) {
						ctrl.organization = result;
						if (result && result.organizationType !== 'School') {
							ManagerAPI.Organization.children(result.id, { limit: -1 }).then(function(results) {
								ctrl.schools =
									results && results.items && results.items.length
										? _.filter(results.items, function(organization) {
												return organization.organizationType.toLowerCase() === 'school';
										  })
										: [];
								ctrl.schools = _.orderBy(ctrl.schools, ['name', 'asc']);
								ctrl.school = ctrl.schools && ctrl.schools.length === 1 ? ctrl.schools[0] : undefined;
								organizationsChanged();
							});
						} else {
							ctrl.school = result;
							ctrl.schools = [ctrl.school];
							organizationsChanged();
						}
					})
					.finally(function() {
						ctrl.busy = false;
					});
			} else {
				ManagerAPI.Organization.quickList()
					.then(function(results) {
						ctrl.schools =
							results && results.length
								? _.filter(results, function(organization) {
										return organization.organizationType.toLowerCase() === 'school';
								  })
								: [];
						ctrl.schools = _.orderBy(ctrl.schools, ['name', 'asc']);
						ctrl.school = ctrl.schools && ctrl.schools.length === 1 ? ctrl.schools[0] : undefined;
						organizationsChanged();
					})
					.finally(function() {
						ctrl.busy = false;
					});
			}
		}
		init();
	}
})();
