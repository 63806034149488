angular.module('assistModeErrorModal').service('AssistModeErrorModal', AssistModeErrorModalService);

AssistModeErrorModalService.$inject = ['ngDialog'];
function AssistModeErrorModalService(ngDialog) {
	const service = this;

	service.open = open;

	////////////////////

	function open(error, userName) {
		return ngDialog.open({
			className: 'ngdialog-theme-default assist-mode-error-modal',
			template: 'components/assist.mode.error.modal/assist-mode-error-modal.html',
			controller: 'AssistModeErrorModalController',
			controllerAs: '$ctrl',
			data: {
				error,
				userName
			}
		}).closePromise;
	}
}
