angular.module('assistModeErrorModal').controller('AssistModeErrorModalController', AssistModeErrorModalController);

AssistModeErrorModalController.$inject = ['$scope'];
function AssistModeErrorModalController($scope) {
	const $ctrl = this;

	// Model
	$ctrl.error = undefined;
	$ctrl.userName = undefined;

	activate();

	////////////////////

	function activate() {
		$ctrl.error = _.get($scope, 'ngDialogData.error.data.error');
		$ctrl.userName = _.get($scope, 'ngDialogData.userName');
	}
}
