(function() {
	'use strict';

	angular.module('web.dropdownMenu').directive('ilDropdownMenuContainer', ilDropdownMenuContainer);

	ilDropdownMenuContainer.$inject = [];
	function ilDropdownMenuContainer() {
		// Usage:
		//
		// Creates:
		//
		var directive = {
			bindToController: true,
			controller: DropdownMenuContainerController,
			controllerAs: '$ctrl',
			link: link,
			restrict: 'A',
			scope: {}
		};
		return directive;

		function link(scope, element, attrs) {
			element.addClass('dropdown-menu');
		}
	}
	/* @ngInject */
	function DropdownMenuContainerController() {
		var $ctrl = this,
			menus = {};

		$ctrl.register = register;
		$ctrl.unregister = unregister;
		$ctrl.isVisible = isVisible;
		$ctrl.setVisible = setVisible;
		$ctrl.toggleVisible = toggleVisible;
		$ctrl.hideAllOthers = hideAllOthers;

		function register(id) {
			menus[id] = false;
		}

		function unregister(id) {
			delete menus[id];
		}

		function isVisible(id) {
			return !!menus[id];
		}

		function setVisible(id, visible) {
			menus[id] = visible;
			if (visible) {
				hideAllOthers(id);
			}
		}

		function toggleVisible(id) {
			menus[id] = !menus[id];
			if (menus[id]) {
				hideAllOthers(id);
			}
		}

		function hideAllOthers(id) {
			for (var key in menus) {
				if (key !== id && menus[id]) {
					menus[id] = false;
				}
			}
		}
	}
})();
