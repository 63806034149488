(function() {
	angular
		.module('widgets.user.groups.list', [
			'lists.common.localization',
			'ui.notifications',
			'api.manager',
			'api.reporting',
			'paged.list',
			'utils.date',
			'il.web.components',
			'helpers.percentages',
			'usage.helper.service'
		])
		.directive('userGroupsListWidget', directive)
		.controller('UserGroupsListWidgetCtrl', controller);

	directive.$inject = [];

	function directive() {
		return {
			restrict: 'A',
			scope: {
				userId: '=userGroupsListWidget',
				organizationId: '=?',
				dateRange: '=?',
				widgetTitle: '@'
			},
			controller: controller,
			controllerAs: 'model',
			templateUrl: 'components/user.groups.list/user.groups.list.html'
		};
	}

	controller.$inject = [
		'$scope',
		'$q',
		'NotificationFactory',
		'PagedList',
		'ReportingAPI',
		'CurrentDate',
		'DateUtils',
		'UsageHelpers',
		'PercentageHelpers',
		'DateRangeService',
		'ManagerAPI'
	];

	function controller(
		$scope,
		$q,
		NotificationFactory,
		PagedList,
		ReportingAPI,
		CurrentDate,
		DateUtils,
		UsageHelpers,
		PercentageHelpers,
		DateRangeService,
		ManagerAPI
	) {
		var model = this;
		model.PercentageHelpers = PercentageHelpers;

		//exposed for export
		model.reportParams = {};
		model.dataFields = [];

		model.initialized = false;
		model.busy = false;
		model.error = false;
		model.groupList = new PagedList(ReportingAPI.user.groups);
		model.managementTooltipProps = undefined;

		model.refreshModel = refreshModel;
		model.getAverageUsageMinutes = getAverageUsageMinutes;
		model.getAvgLessonsCompleted = getAvgLessonsCompleted;
		model.getAvgLessonsPassed = getAvgLessonsPassed;
		model.groupCreated = groupCreated;
		model.createGroupsClicked = createGroupsClicked;

		function createGroupsClicked() {
			const createGroupsButton = document.querySelector('[data-id="CreateGroupsButton_noResults"]');
			model.managementTooltipProps = {
				anchorObject: createGroupsButton,
				isOpen: true,
				onClose: () => {
					model.managementTooltipProps = undefined;
				}
			};
		}

		$scope.$watch(
			'userId',
			function(value) {
				if (value && value.length) {
					refreshModel();
				}
			},
			true
		);

		$scope.$watch(
			'dateRange',
			function(value) {
				if (value && $scope.userId && $scope.userId.length) {
					refreshModel();
				}
			},
			true
		);

		function getAverageUsageMinutes(group) {
			return UsageHelpers.getRangeUsageAverageMinutes(group, model.reportDivisor);
		}

		function getOrganization(organizationId) {
			if (!model.organization || model.organization.id !== organizationId) {
				ManagerAPI.Organization.get(organizationId).then(function(result) {
					model.organization = result;
				});
			}
		}

		function refreshModel() {
			if (model.busy) {
				return;
			}

			getOrganization($scope.organizationId);

			model.busy = true;
			model.error = false;
			model.dataFields = ['counts', 'progress', 'rangeUsage'];

			if (!$scope.dateRange || !($scope.dateRange.start && angular.isDate($scope.dateRange.start))) {
				$scope.dateRange = DateRangeService.last4Weeks();
			}

			var reportRange = DateUtils.daysInRange($scope.dateRange.start, $scope.dateRange.end),
				usageReportType = reportRange > 15 ? 'weeklyUsage' : 'dailyUsage';

			model.usageMetric = 'rangeUsage';
			model.reportDivisor =
				usageReportType === 'dailyUsage'
					? DateUtils.weekdaysInRange($scope.dateRange.start, $scope.dateRange.end)
					: Math.ceil(reportRange / 7);

			model.reportParams = {
				id: $scope.userId,
				orgId: $scope.organizationId,
				startDate: $scope.dateRange.start,
				endDate:
					$scope.dateRange.end && $scope.dateRange.end.getTime() > CurrentDate.get().getTime()
						? CurrentDate.get()
						: $scope.dateRange.end,
				sortby: 'name',
				dataFields: model.dataFields
			};

			model.groupList.params.id = model.reportParams.id;
			model.groupList.params.orgId = model.reportParams.orgId;
			model.groupList.params.startDate = model.reportParams.startDate;
			model.groupList.params.endDate = model.reportParams.endDate;
			model.groupList.params.sortby = 'name';
			model.groupList.params.dataFields = model.dataFields;

			model.groupList.load();
			model.initialized = true;
			model.busy = false;
		}

		function getAvgLessonsCompleted(item) {
			var completed = _.get(item, 'progress.completed', 0);
			var skipped = _.get(item, 'progress.skipped', 0);
			var totalActiveStudents = _.get(item, 'totalActiveStudents', 0);

			if (totalActiveStudents === 0) {
				return '--';
			} else {
				return (completed / totalActiveStudents).toFixed(0);
			}
		}

		function getAvgLessonsPassed(item) {
			var passed = _.get(item, 'progress.passed', 0);
			var totalActiveStudents = _.get(item, 'totalActiveStudents', 0);

			if (totalActiveStudents === 0) {
				return '--';
			} else {
				return (passed / totalActiveStudents).toFixed(0);
			}
		}

		function groupCreated() {
			model.refreshModel();
		}
	}
})();
