(function() {
	angular.module('manager.list').directive('ilManagerList', directive);

	function directive() {
		return {
			restrict: 'EA',
			template: '<div class="manager_list {{cssClass}}" ng-include="templatePath"></div>',
			replace: true,
			scope: {
				headerText: '=',
				cssClass: '@',
				managerList: '=ilManagerList',
				templatePath: '@',
				type: '@',
				product: '@',
				managementTooltipProps: '@'
			},
			link: function(scope, element, attrs) {
				scope.cssClass = scope.cssClass || '';
				scope.templatePath =
					scope.templatePath && scope.templatePath.length
						? scope.templatePath
						: 'components/manager.list/partials/' + scope.type + '.html';
				scope.createGroupsClicked = isNoResults => {
					const createGroupsButton = document.querySelector(
						isNoResults ? '[data-id="CreateGroupsButton_noResults"]' : '[data-id="CreateGroupsButton"]'
					);

					scope.managementTooltipProps = {
						anchorObject: createGroupsButton,
						isOpen: true,
						onClose: () => {
							scope.managementTooltipProps = undefined;
						}
					};
				};
			}
		};
	}
})();
