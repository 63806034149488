(function() {
	angular
		.module('group.student.navigation')
		.directive('groupStudentNavigationButtons', directive)
		.controller('GroupStudentNavigationButtonsCtrl', controller);

	controller.$inject = ['GroupStudentNavigation'];

	function controller(GroupStudentNavigation) {
		var ctrl = this;
		ctrl.navigator = GroupStudentNavigation;
	}

	function directive() {
		return {
			restrict: 'A',
			templateUrl: 'components/group.student.navigation/buttons.html',
			replace: true,
			controller: 'GroupStudentNavigationButtonsCtrl',
			controllerAs: 'ctrl',
			bindToController: true,
			link: function(scope, element, attrs, ctrl) {}
		};
	}
})();
