(function() {
	angular
		.module('widgets.group.settings', [
			'settings.common.localization',
			'ui.notifications',
			'ui.manager.multiple.select',
			'api.manager',
			'api.application.user',
			'entity.view.model'
		])
		.directive('groupSettingsWidget', directive)
		.controller('GroupSettingsWidgetController', controller);

	directive.$inject = [];

	function directive() {
		return {
			restrict: 'A',
			scope: {
				id: '=groupSettingsWidget',
				mode: '@',
				close: '&',
				callback: '&',
				sections: '=',
				closeDialog: '&'
			},
			controller: controller,
			controllerAs: 'model',
			templateUrl: 'components/group.settings/group.settings.html',
			link: function(scope, element, attrs) {
				scope.model.mode = scope.mode !== undefined && scope.mode.length ? scope.mode : scope.model.mode;
			}
		};
	}

	controller.$inject = ['$scope', '$q', 'NotificationFactory', 'managerAPIHelpers', 'Class', 'EntityViewModel', 'ApplicationUserService'];

	function controller($scope, $q, NotificationFactory, managerAPIHelpers, Class, EntityViewModel, ApplicationUserService) {
		var model = this;
		model.details = undefined;

		model.staff = undefined;
		model.staffIds = undefined;
		model.staffMembers = [];
		model.organization = undefined;
		model.organizationIds = undefined;

		model.mode = 'edit'; // default to edit

		model.busy = true;
		model.error = false;
		model.synced = false; // is this record updated by a scheduled sync
		model.initialized = false;
		model.teacherWarning = false;

		model.group = undefined;

		// public methods
		model.close = close;
		model.saveAll = saveAll;
		model.isInvalid = isInvalid;
		model.isDirty = isDirty;
		model.updateEntity = updateEntity;
		model.refreshModel = refreshModel;

		function doCallback() {
			if ($scope.callback) {
				$scope.callback();
			}
			$scope.$emit('dashboard.reload');
		}

		function close() {
			if ($scope.close) {
				$scope.close();
			}
		}

		$scope.$watch(
			'id',
			function(value) {
				if (value) {
					refreshModel(value);
				}
			},
			true
		);

		$scope.$watchGroup(['model.detailsForm.$invalid', 'model.staffForm.$invalid'], function() {
			model.formInvalid = model.isInvalid();
		});

		function isInvalid() {
			return (model.detailsForm ? model.detailsForm.$invalid : false) || (model.staffForm ? model.staffForm.$invalid : false);
		}

		function isDirty() {
			return (
				(model.details && model.details.dirty ? model.details.dirty() : false) ||
				(model.staff && model.staff.dirty ? model.staff.dirty() : false)
			);
		}

		function refreshModel(id) {
			model.error = model.initialized = false;
			Class.get(id, { additionalFields: ['users'] })
				.then(function(results) {
					model.group = results;
					model.staffIds = _.map(model.group.users, 'id');
					model.group.userIds = _.map(model.group.users, 'id');
					model.organizationIds = [model.group.organizationId];

					// is org on a sync schedule
					model.synced = managerAPIHelpers.isEntitySynced(model.group);

					model.details = new EntityViewModel(model.group, ['name', 'organizationId'], {
						update: Class.update,
						callback: doCallback,
						dirty: function() {
							var orig = model.details.base.name,
								vm = model.details.viewModel.name;
							return (
								!angular.equals(model.details.base.organizationId, model.details.viewModel.organizationId) ||
								!angular.equals(orig, vm)
							);
						}
					});

					model.staff = new EntityViewModel(model.group, ['userIds'], {
						update: Class.update,
						callback: doCallback,
						dirty: function() {
							var orig =
									model.staff.base.userIds && model.staff.base.userIds.length
										? model.staff.base.userIds.sort()
										: model.staff.base.userIds,
								vm =
									model.staff.viewModel.userIds && model.staff.viewModel.userIds.length
										? model.staff.viewModel.userIds.sort()
										: model.staff.viewModel.userIds;
							return !angular.equals(model.staff.base.id, model.staff.viewModel.id) || !angular.equals(orig, vm);
						}
					});

					// we need to watch selected groups to be able to warn the current user that removing themself will leave them locked out of the class
					if (
						model.staff.base.userIds &&
						model.staff.base.userIds.length &&
						_.indexOf(model.staff.base.userIds, ApplicationUserService.getUser().id) !== -1
					) {
						$scope.$watch(
							function() {
								return model.staff && model.staff.viewModel ? model.staff.viewModel.userIds : undefined;
							},
							function(newValue) {
								if (_.indexOf(newValue, ApplicationUserService.getUser().id) === -1) {
									model.teacherWarning = true;
								} else {
									model.teacherWarning = false;
								}
							},
							true
						);
					}

					model.details.refresh();
					model.staff.refresh();
				})
				.catch(function(error) {
					model.group = undefined;
					model.error = NotificationFactory.error(error);
				})
				.finally(function() {
					model.busy = false;
					model.initialized = true;
				});
		}

		function updateEntity(entity, force) {
			if (entity && (entity.dirty() || force)) {
				return entity.update();
			}

			return $q.when();
		}

		function saveAll() {
			model.busy = true;
			$q.when()
				.then(function() {
					return model.updateEntity(model.details);
				})
				.then(function() {
					return model.updateEntity(model.staff);
				})
				.catch(function(error) {
					model.error = NotificationFactory.error(error);
				})
				.finally(function() {
					if ($scope.closeDialog) {
						$scope.closeDialog();
					}
					model.busy = false;
				});
		}
	}
})();
