(function() {
	angular.module('unassigned.students.manager').controller('AssignStudentsModalController', controller);

	controller.$inject = ['$scope', 'ngDialog', 'NotificationFactory', 'ManagerAPI', 'ManagerBulkOperations', 'TimedCache'];

	function controller($scope, ngDialog, NotificationFactory, ManagerAPI, ManagerBulkOperations, TimedCache) {
		// props
		var ctrl = this,
			maxBatchSize = 200;
		ctrl.busy = false;

		ctrl.studentIds = [];
		ctrl.selectedGroups = [];
		ctrl.groupIds = [];
		// ctrl.selectedOrgs = [];
		ctrl.orgIds = [];

		// methods
		ctrl.addStudentsToGroups = addStudentsToGroups;
		ctrl.organizationsChanged = organizationsChanged;
		ctrl.init = init;

		function organizationsChanged() {
			ctrl.selectedGroups = [];
			ctrl.groupIds = [];
			ctrl.orgIds = ctrl.school && ctrl.school.id ? [ctrl.school.id] : [];
		}

		function addStudentsToGroups() {
			var items = [];

			angular.forEach(ctrl.studentIds, function(id) {
				items.push({
					Id: id,
					groupIds: ctrl.groupIds,
					organizationIds: ctrl.orgIds
				});
			});

			TimedCache.removeAll();
			ManagerBulkOperations.patchOrgsAndGroups
				.students(items)
				.then(function(results) {
					var groupNames = _.map(ctrl.selectedGroups, 'name').join(', ');
					NotificationFactory.success({
						heading: 'Group assignment complete',
						content: [
							ctrl.studentIds.length,
							ctrl.studentIds.length === 1 ? 'student' : 'students',
							'added to ',
							ctrl.groupIds.length === 1 ? 'group: ' : 'groups: ',
							groupNames
						].join(' '),
						closeAfter: 5
					});
					ctrl.studentIds = [];
					$scope.closeThisDialog();
					if (ctrl.callback) {
						ctrl.callback();
					}
				})
				.catch(function(error) {
					// ctrl.studentList.error = NotificationFactory.error(error);
					NotificationFactory.error(error);
				});
		}

		function init() {
			ctrl.studentIds = angular.copy($scope.ngDialogData.studentIds);
			// ctrl.orgId = $scope.ngDialogData.organizationId;
			ctrl.orgIds = [$scope.ngDialogData.organizationId];
			ctrl.callback = $scope.ngDialogData.callback;

			ctrl.busy = true;
			if ($scope.ngDialogData.organizationId && $scope.ngDialogData.organizationId.length) {
				ManagerAPI.Organization.get($scope.ngDialogData.organizationId)
					.then(function(result) {
						if (result && result.organizationType !== 'School') {
							ManagerAPI.Organization.children(result.id, { limit: -1 }).then(function(results) {
								ctrl.schools =
									results && results.length
										? _.filter(results, function(organization) {
												return organization.organizationType.toLowerCase() === 'school';
										  })
										: [];
								ctrl.schools = _.orderBy(ctrl.schools, ['name', 'asc']);
								ctrl.school = ctrl.schools && ctrl.schools.length === 1 ? ctrl.schools[0] : undefined;
								organizationsChanged();
							});
						} else {
							ctrl.schools = [ctrl.school];
							ctrl.school = result;
							organizationsChanged();
						}
					})
					.finally(function() {
						ctrl.busy = false;
					});
			} else {
				ManagerAPI.Organization.quickList()
					.then(function(results) {
						ctrl.schools =
							results && results.length
								? _.filter(results, function(organization) {
										return organization.organizationType.toLowerCase() === 'school';
								  })
								: [];
						ctrl.schools = _.orderBy(ctrl.schools, ['name', 'asc']);
						ctrl.school = ctrl.schools && ctrl.schools.length === 1 ? ctrl.schools[0] : undefined;
						organizationsChanged();
					})
					.finally(function() {
						ctrl.busy = false;
					});
			}
		}
		init();
	}
})();
