// This module contains all dependencies for React component imports
import { CacheService } from '@imaginelearning/web-api-core';

(function() {
	angular
		.module('react.dependencies', ['api.application.user', 'config'])
		.value('CacheService', CacheService)
		.service('ReactDependenciesService', service);

	service.$inject = ['ENV', 'ApplicationUserService', 'CacheService'];

	function service(ENV, ApplicationUserService, CacheService) {
		this.apiConfig = apiConfig;

		this.cacheService = new CacheService();

		function apiConfig() {
			const config = {
				accessToken: ApplicationUserService.getToken,
				environment: ENV.name,
				requestCache: this.cacheService
			};
			return config;
		}
	}
})();
