(function() {
	angular.module('assign.product.modal').directive('assignProductModalLink', directive);

	directive.$inject = ['ngDialog'];

	function directive(ngDialog) {
		return {
			scope: {
				titleText: '@',
				students: '=',
				callback: '&'
			},
			restrict: 'A',
			link: function(scope, element) {
				function show() {
					ngDialog.open({
						className: 'ngdialog-theme-default',
						template: 'components/assign.product.modal/template.html',
						controller: 'AssignProductModalController',
						controllerAs: '$ctrl',
						data: {
							titleText: scope.titleText,
							students: scope.students,
							callback: scope.callback
						}
					});
				}

				element.on('click', show);

				element.on('$destroy', function() {
					element.off('click');
				});
			}
		};
	}
})();
