(function() {
	angular.module('grade.level.drop.down').controller('GradeLevelDropDownController', controller);

	controller.$inject = ['ReportingAPI', 'GradeLevel', 'NotificationFactory'];

	function controller(ReportingAPI, GradeLevel, NotificationFactory) {
		var ctrl = this,
			all = 'all';

		ctrl.busy = false;

		// methods
		ctrl.$onInit = onInit;
		ctrl.$onChanges = onChanges;

		function onChanges(changes) {
			if ((changes.id && changes.id.currentValue) || (changes.dateRange && changes.dateRange.currentValue)) {
				onInit();
			}
		}

		function onInit() {
			if (ctrl.busy || !ctrl.id || !ctrl.type || !ctrl.dateRange) {
				return;
			}

			ctrl.busy = true;

			ReportingAPI[ctrl.type]
				.get({
					id: ctrl.id,
					product: ctrl.product === 'espanol' || ctrl.product === 'Spanish' ? 'Spanish' : 'ILE',
					startDate: ctrl.dateRange.start,
					endDate: ctrl.dateRange.end,
					dataFields: ['gradeLevels']
				})
				.then(function(results) {
					ctrl.grades = _.map(results.gradeLevels, function(gradeLevel) {
						return {
							label: [
								GradeLevel.getGradeLevelText(gradeLevel.grade),
								'  (',
								gradeLevel.activeStudents,
								' active students)'
							].join(''),
							value: gradeLevel.grade
						};
					});

					ctrl.grades = _.orderBy(ctrl.grades, function(grade) {
						return GradeLevel.getGradeLevelNumber(grade.value);
					});

					ctrl.grades.push({
						label: ['All  (', _.sum(_.map(results.gradeLevels, 'activeStudents')), ' active students)'].join(''),
						value: all
					});

					if (!ctrl.grade || !_.sum(_.map(results.gradeLevels, 'activeStudents'))) {
						ctrl.grade = all;
					}
				})
				.catch(function(error) {
					ctrl.error = NotificationFactory.error(error);
					ctrl.grades = [
						{
							label: 'All',
							value: all
						}
					];
					ctrl.grade = all;
				})
				.finally(function() {
					ctrl.busy = false;
				});
		}
	}
})();
