(function() {
	angular.module('test.enablement').controller('TestEnablementModalController', controller);

	controller.$inject = ['$scope', '$rootScope', '$filter', '$timeout', 'Organization', 'NotificationFactory', 'testEnablementTestTypes'];

	function controller($scope, $rootScope, $filter, $timeout, Organization, NotificationFactory, testEnablementTestTypes) {
		// props
		var ctrl = this;
		ctrl.busy = false;
		ctrl.data = undefined;
		ctrl.cached = undefined;

		// methods
		ctrl.update = update;
		ctrl.isDirty = isDirty;
		ctrl.init = init;

		function update() {
			if (ctrl.busy || !ctrl.data.id || !ctrl.data.id.length) {
				return;
			}

			ctrl.busy = true;
			var promise;
			if (ctrl.data.test === testEnablementTestTypes.benchmark) {
				promise = Organization.updateBenchmarkSettings(ctrl.data.id, ctrl.data.testSettings);
			} else {
				promise = Organization.updateRLASettings(ctrl.data.id, ctrl.data.testSettings);
			}

			promise
				.then(function(results) {
					ctrl.data.callback();
					$rootScope.$broadcast('OrganizationAssessmentStatusRefresh');
					$scope.closeThisDialog();
				})
				.catch(function(error) {
					ctrl.error = NotificationFactory.error(error);
				})
				.finally(function() {
					ctrl.busy = false;
				});
		}

		function isDirty() {
			return !angular.equals(ctrl.cached, ctrl.data.testSettings);
		}

		function init() {
			ctrl.data = angular.copy($scope.ngDialogData);
			ctrl.cached = angular.copy(ctrl.data.testSettings);
			ctrl.headerText =
				ctrl.data.test === testEnablementTestTypes.benchmark
					? $filter('translate')('test_enablement.labels.benchmark')
					: $filter('translate')('test_enablement.labels.readingLevel');
			// remove focus from 1st radio button, doesn't look nice
			$timeout(function() {
				angular.element('input.test_enablement_radio_button').blur();
			}, 10);
		}
		init();
	}
})();
