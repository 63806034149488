(function() {
	angular.module('students.edit').directive('studentsEditModalLink', directive);

	directive.$inject = ['ngDialog'];

	function directive(ngDialog) {
		return {
			scope: {
				titleText: '@',
				students: '=',
				callback: '&'
			},
			restrict: 'A',
			link: function(scope, element) {
				scope.show = show;

				function show() {
					ngDialog.open({
						className: 'ngdialog-theme-default',
						template: 'components/students.edit/modal.html',
						controller: 'StudentsEditModalController',
						controllerAs: '$ctrl',
						closeByNavigation: true,
						closeByDocument: false,
						data: {
							titleText: scope.titleText,
							students: scope.students,
							callback: scope.callback
						}
					});
				}

				element.on('click', scope.show);

				element.on('$destroy', function() {
					element.off('click');
				});
			}
		};
	}
})();
