(function() {
	angular.module('growth.qualifier.filter').controller('GrowthQualifierFilterController', controller);

	controller.$inject = ['GrowthQualifierFilter', '$scope', '$state'];

	function controller(GrowthQualifierFilter, $scope, $state) {
		var ctrl = this;
		ctrl.qualifiers = GrowthQualifierFilter.qualifiers;

		ctrl.$onInit = onInit;
		ctrl.$onChanges = onChanges;

		function onInit() {
			if (ctrl.qualifier && ctrl.qualifier.length) {
				GrowthQualifierFilter.setCurrentQualifier(ctrl.qualifier);
			} else {
				ctrl.qualifier = GrowthQualifierFilter.getCurrentQualifier();
			}
			$state.params.qualifier = ctrl.qualifier;
			$state.go($state.current.name, $state.params, { notify: true, location: 'replace' });
		}

		function onChanges(changesObj) {
			if (changesObj.qualifier && changesObj.qualifier.currentValue) {
				onInit();
			}
		}
	}
})();
