(function() {
	var root = this;
	var translations = {
		en: {
			// create_students_modal: {
			// 	titleText: 'Create Students',
			// 	description: 'Create as many students as desired. You will have the chance to review your work before submitting.',
			// 	studentExistsInListError: 'A student with this name already exists in the list. Please choose another name.',
			// 	studentExistsInOrganizationError: 'A student with this name already exists in this school. Please choose another name.',
			// 	requiredField: 'This field is required',
			// 	length1to64: 'This field must be between 3 and 64 characters in length.',
			// 	length3to128: 'This field must be between 3 and 128 characters in length.',
			// 	length4to256: 'This field must be between 4 and 256 characters in length.',
			// }
		}
	};

	if (root.angular) {
		root.angular.module('add.demographics.modal').config(config);
	} else if (typeof module !== 'undefined' && module.exports) {
		module.exports = translations;
	}

	config.$inject = ['LocalizeProvider'];

	function config(LocalizeProvider) {
		LocalizeProvider.translations(translations);
	}
})();
