(function() {
	angular
		.module('ui.breadcrumbs', [
			'ui.router',
			'ngStorage',
			'ui.notifications',
			'ui.directives.disableClick',
			'ui.directives.backButton',
			'utils.gradeLevel',
			'api.application.user',
			'api.manager'
		])
		.service('BreadcrumbsService', service)
		.directive('ilBreadcrumbs', directive)
		.controller('BreadcrumbsController', controller);

	function service() {
		this.breadcrumbs = [];
		this.organizations = [];
		this.rootOrganization = [];
	}

	function directive() {
		return {
			restrict: 'A',
			scope: {
				home: '&?'
			},
			controller: controller,
			controllerAs: 'model',
			templateUrl: 'components/breadcrumbs/breadcrumbs.html',
			link: function(scope, element, attrs) {
				scope.model.managerApi = 'managerApi' in attrs;
				scope.model.root = 'root' in attrs && attrs['root'].length ? attrs['root'] : null;
				scope.model.homeText = 'homeText' in attrs && attrs['homeText'].length ? attrs['homeText'] : scope.model.homeText;
				scope.home = !('home' in attrs) ? undefined : scope.home;
			}
		};
	}

	controller.$inject = [
		'$scope',
		'$q',
		'$filter',
		'ManagerAPI',
		'managerAPIHelpers',
		'NotificationFactory',
		'$state',
		'$localStorage',
		'ApplicationUserService',
		'UserRole',
		'BreadcrumbsService'
	];

	function controller(
		$scope,
		$q,
		$filter,
		ManagerAPI,
		managerAPIHelpers,
		NotificationFactory,
		$state,
		$localStorage,
		ApplicationUserService,
		UserRole,
		BreadcrumbsService
	) {
		var model = this,
			currentText;

		// props
		model.root = '';
		model.state = $state;
		model.initialized = false;
		model.busy = false;
		model.error = false;
		model.homeText = undefined;
		model.breadcrumbs = BreadcrumbsService.breadcrumbs;
		model.hideBreadcrumbs = false;

		// methods
		model.current = current;
		model.stateChangeSuccess = stateChangeSuccess;
		model.goHome = goHome;

		$scope.$on('$stateChangeSuccess', stateChangeSuccess);

		function fetchCrumbs() {
			var userId = ApplicationUserService.getUser().id;
			return $localStorage[model.root + '_breadcrumbs_' + userId];
		}

		function storeCrumbs(crumbs) {
			var userId = ApplicationUserService.getUser().id;
			return ($localStorage[model.root + '_breadcrumbs_' + userId] = crumbs);
		}

		function goHome() {
			if ($scope.home && angular.isFunction($scope.home)) {
				$scope.home();
			} else {
				$state.go('dashboard.user', { id: ApplicationUserService.getUser().id }, { notify: true });
			}
		}

		function current() {
			if (!model.busy) {
				var stateNameSplit = $state.current.name.split('.');
				if (stateNameSplit && stateNameSplit.length > 2 && stateNameSplit[0] === model.root) {
					if (model.breadcrumbs.length === 0 && $state.current.name && $state.params.id) {
						var savedCrumbs = fetchCrumbs();
						if (ApplicationUserService.getUser().id && savedCrumbs && savedCrumbs.length) {
							model.breadcrumbs = fetchCrumbs();
						}
						model.stateChangeSuccess(undefined, { name: $state.current.name }, $state.params);
					}

					currentText = stateNameSplit[2] && stateNameSplit[2].length ? stateNameSplit[2] : '';
				}
				// if (stateNameSplit && stateNameSplit.length === 2 && stateNameSplit[0] === model.root && stateNameSplit[0] === 'search') {
				// 	currentText = 'Results';
				// 	// currentText = decodeURIComponent($state.params.query);
				// }
				else {
					currentText = '';
				}
			}
			return currentText;
		}

		function stateChangeSuccess(event, toState, toParams, fromState, fromParams) {
			var crumb;
			// eg: dashboard.organization.overview
			var stateNameSplit = toState.name.split('.');
			var userId = ApplicationUserService.getUser().id;
			if (stateNameSplit[0] === model.root && userId && userId.length) {
				if (model.busy) {
					return;
				}

				model.homeText = model.homeText || ApplicationUserService.getUser().displayName;

				if (toState.name.match(/dashboard.user/gi)) {
					// Do not show breadcrumbs on the user dashboard
					model.hideBreadcrumbs = true;
				} else {
					model.hideBreadcrumbs = false;
				}

				var index =
					toParams.id && toParams.id.length
						? _.findIndex(model.breadcrumbs, function(crumb) {
								return crumb.id === toParams.id && crumb.state.name.match([stateNameSplit[0], stateNameSplit[1]].join('.'));
						  })
						: -1;

				if (toParams.id && index === -1) {
					const userRole = ApplicationUserService.getUserRole();
					model.busy = true;

					var promise, ancestorPromise;
					if (model.managerApi && toParams.id && toParams.id.length) {
						switch (stateNameSplit[1]) {
							case 'organization':
								// teachers and parents should not have any org crumbs to click on
								if (userRole !== UserRole.teacher && userRole !== UserRole.parent) {
									promise = ManagerAPI.Organization.get(toParams.id);
									ancestorPromise = ManagerAPI.Organization.ancestors(toParams.id);
								} else {
									promise = $q.when(null);
									ancestorPromise = $q.when(null);
								}
								break;

							case 'grade':
								promise = ManagerAPI.Organization.get(toParams.id);
								// teachers and parents should not have any org crumbs to click on
								if (userRole !== UserRole.teacher && userRole !== UserRole.parent) {
									ancestorPromise = ManagerAPI.Organization.ancestors(toParams.id);
								} else {
									ancestorPromise = $q.when(null);
								}
								break;

							case 'group':
								promise = ManagerAPI.Group.get(toParams.id);
								// teachers and parents should not have any org crumbs to click on
								if (userRole !== UserRole.teacher && userRole !== UserRole.parent) {
									ancestorPromise = ManagerAPI.Group.ancestors(toParams.id);
								} else {
									ancestorPromise = $q.when([]);
								}
								break;

							case 'student':
								promise = ManagerAPI.Student.get(toParams.id, {});
								ancestorPromise = ManagerAPI.Student.ancestors(toParams.id);
								break;

							case 'user':
								promise = ManagerAPI.User.get(toParams.id);
								if (userId === toParams.id) {
									model.breadcrumbs = [];
									ancestorPromise = $q.when([]);
								} else {
									ancestorPromise = ManagerAPI.User.ancestors(toParams.id);
								}
								break;

							// case 'search':
							// 	// model.breadcrumbs.push({
							// 	// 	'state': toState,
							// 	// 	'params': toParams,
							// 	// 	'id': 'search',
							// 	// 	'text': 'Results'
							// 	// });
							// 	promise = $q.when({ 'name': 'Search' });
							// 	ancestorPromise = $q.when([]);
							// 	break;
						}
					} else {
						promise = $q.when({
							id: managerAPIHelpers.getUID(),
							name: stateNameSplit[1]
						});
					}

					promise
						.then(function(result) {
							if (result) {
								var text;
								if (userId === result.id) {
									text = ApplicationUserService.getUser().displayName;
								} else {
									text =
										result.lastName && result.lastName.length
											? [result.lastName, result.firstName].join(', ')
											: stateNameSplit[1] === 'grade'
											? result.name + ' (' + $filter('gradeLevel')(toParams.gradeLevel) + ')'
											: result.name || stateNameSplit[1];
								}
								crumb = {
									state: toState,
									params: toParams,
									id: result.id,
									text: text
								};
							}

							// we are navigating - use routes to build crumbs
							if (model.breadcrumbs && model.breadcrumbs.length) {
								var last = model.breadcrumbs.length - 1;
								// we are navigating through a group's students
								if (
									toParams.navGroupId &&
									(model.breadcrumbs[last].state.name.match(/dashboard.student/gi) ||
										model.breadcrumbs[last].state.name.match(/espanol.student/gi))
								) {
									model.breadcrumbs.splice(last, 1);
								}
								// navigation within the last crumb route - update so we return to the last visited route - not the first
								else if (fromParams && fromParams.id && fromParams.id === model.breadcrumbs[last].id) {
									model.breadcrumbs[last] = {
										state: fromState,
										params: fromParams,
										id: model.breadcrumbs[last].id,
										text: model.breadcrumbs[last].text
									};
								}
								model.breadcrumbs.push(crumb);
								return $q.when(null);
							}
							// we arrived here from a bookmark or pasted url - rely on API to build crumbs
							else {
								return ancestorPromise;
							}
						})
						.then(function(results) {
							if (results !== null) {
								var crumbs = results || [];
								crumbs.forEach(function(item) {
									item.text = item.name;
									item.state = {
										name: [model.root, item.type.toLowerCase()].join('.')
									};
									item.params = {
										id: item.id
									};
								});
								crumbs.push(crumb);
								model.breadcrumbs = crumbs;
							}
						})
						.catch(function(error) {
							model.error = NotificationFactory.error(error);
						})
						.finally(function() {
							model.busy = false;
							model.initialized = true;
							if (userId && userId.length) {
								storeCrumbs(model.breadcrumbs);
							}
						});
				} else {
					//hit a current crumb ancestor, jump to
					if (index !== model.breadcrumbs.length - 1) {
						model.breadcrumbs.splice(
							index + 1 < model.breadcrumbs.length ? index + 1 : index,
							model.breadcrumbs.length - index
						);
					}
					model.busy = false;
					model.initialized = true;
					if (userId && userId.length) {
						storeCrumbs(model.breadcrumbs);
					}
				}
			} else {
				model.breadcrumbs = [];
				model.busy = false;
				model.error = false;
			}
		}
	}
})();
