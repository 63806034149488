(function() {
	angular.module('date.range.picker').config(config);

	config.$inject = ['LocalizeProvider'];

	function config(LocalizeProvider) {
		LocalizeProvider.translations({
			en: {
				start: 'Start',
				end: 'End',
				startDate: 'Start Date',
				endDate: 'End Date',
				header: 'Select a date range.',
				cancel: 'Cancel',
				ok: 'OK'
			}
		});
	}
})();
