(function() {
	angular.module('filter.pills').controller('FilterPillsController', controller);

	controller.$inject = ['$scope', '$timeout'];

	function controller($scope, $timeout) {
		var ctrl = this;

		// properties
		ctrl.filters = [];

		// methods
		ctrl.remove = remove;

		// watch our filterString
		$scope.$watch(
			function() {
				return ctrl.filterString;
			},
			function(value, last) {
				// when this is updated we need to create our array of filters
				if (value && !angular.equals(value, last)) {
					ctrl.filters = value.split(' ');
					ctrl.filterString = undefined;
				}
			},
			true
		);

		// remove a filter
		function remove(filter) {
			var index = _.indexOf(ctrl.filters, filter);
			if (index !== -1) {
				// remove filter from list
				ctrl.filters.splice(index, 1);
				// update the search term
				ctrl.searchString = ctrl.filters.join(' ');
				// fire off update method if present
				if (ctrl.onUpdate && angular.isFunction(ctrl.onUpdate)) {
					$timeout(function() {
						ctrl.onUpdate();
					}, 0);
				}
			}
		}
	}
})();
