(function() {
	angular.module('create.users.modal').directive('createUsersModalLink', directive);

	directive.$inject = ['ngDialog'];

	function directive(ngDialog) {
		return {
			scope: {
				titleText: '@',
				organization: '=',
				callback: '&'
			},
			restrict: 'A',
			link: function(scope, element) {
				function show() {
					ngDialog.open({
						width: '100%',
						height: '100%',
						className: 'ngdialog-theme-default full',
						template: 'components/create.users.modal/template.html',
						controller: 'CreateUsersModalController',
						controllerAs: '$ctrl',
						closeByNavigation: true,
						closeByDocument: false,
						data: {
							titleText: scope.titleText,
							organization: scope.organization,
							callback: scope.callback
						}
					});
				}

				element.on('click', show);

				element.on('$destroy', function() {
					element.off('click');
				});
			}
		};
	}
})();
