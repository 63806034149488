(function() {
	angular.module('httpBusy.service', []).service('HttpBusyService', service);

	service.$inject = ['$http'];

	function service($http) {
		this.isBusy = isBusy;

		function isBusy() {
			return $http.pendingRequests.length > 0;
		}
	}
})();
