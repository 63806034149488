(function() {
	angular.module('paged.list', [
		'ui.directives.selectBlur',
		'utils.localization',
		'terms.common.localization',
		'api.manager',
		'api.reporting',
		'ui.notifications'
	]);
})();
