(function() {
	angular.module('assign.groups.modal', [
		'ngDialog',
		'utils.localization',
		'ui.notifications',
		'ui.manager.multiselect',
		'api.manager',
		'api.manager.bulk.operations',
		'api.reporting',
		'api.schoolYear',
		'api.application.user',
		'utils.date',
		'utils.timed_cache',
		'create.groups.modal'
	]);
})();
