(function() {
	angular.module('application.header').component('applicationHeader', {
		bindings: {
			product: '@',
			showPortalNavigation: '<',
			showSearchBar: '<',
			searchState: '=',
			showUserAccountMenu: '<',
			user: '<'
		},
		controller: 'ApplicationHeaderCtrl',
		templateUrl: 'components/application.header/template.html'
	});
})();
