(function() {
	angular.module('group.student.navigation').factory('GroupStudentNavigation', factory);

	factory.$inject = ['$q', '$state', 'Class', 'NotificationFactory'];

	function factory($q, $state, Class, NotificationFactory) {
		var navigator = {};

		// properties
		function clear() {
			navigator.busy = false;
			navigator.error = false;
			navigator.students = undefined;
			navigator.navGroup = undefined;
			navigator.navGroupId = undefined;
			navigator.currentIndex = undefined;
			navigator.currentStudent = undefined;
		}

		clear();

		// methods
		navigator.selectStudent = selectStudent;
		navigator.refresh = refresh;
		navigator.navigate = navigate;
		navigator.forward = forward;
		navigator.backward = backward;
		navigator.clear = clear;

		function selectStudent(id) {
			navigator.currentIndex = _.findIndex(navigator.students, function(item) {
				return item.id === id;
			});
			navigator.currentStudent = navigator.currentIndex !== -1 ? navigator.students[navigator.currentIndex] : undefined;
		}

		function refresh(navGroupId, studentId, gradeLevel) {
			if (!navigator.busy) {
				if (!navGroupId) {
					clear();
					return $q.when();
				}

				if (navigator.navGroupId === navGroupId && (!navigator.currentStudent || navigator.currentStudent.id !== studentId)) {
					selectStudent(studentId);
					return $q.when();
				} else {
					navigator.busy = true;
					navigator.error = false;
					navigator.navGroupId = navGroupId;

					return Class.get(navigator.navGroupId, { additionalFields: ['studentCount'] })
						.then(function(result) {
							navigator.navGroup = result;
							return Class.students(navigator.navGroupId, {
								offset: 0,
								limit: navigator.navGroup.studentCount,
								sortby: 'lastName,firstName'
							});
						})
						.then(function(results) {
							navigator.students = results.items && results.items.length ? results.items : undefined;
							if (gradeLevel && gradeLevel.length) {
								navigator.students = _.filter(navigator.students, function(student) {
									return student.gradeLevel.toLowerCase() === gradeLevel.toLowerCase();
								});
							}
							if (navigator.students) {
								selectStudent(studentId);
							}
							// else {
							// 	navigator.currentIndex =  undefined;
							// 	navigator.currentStudent =  undefined;
							// }
						})
						.catch(function(error) {
							navigator.error = NotificationFactory.error(error);
						})
						.finally(function() {
							navigator.busy = false;
						});
				}
			}
		}

		function navigate() {
			var params = angular.copy($state.params);
			params.id = navigator.students[navigator.currentIndex].id;
			params.navGroupId = navigator.navGroupId;
			$state.go($state.current.name, params, { notify: true });
		}

		function forward() {
			navigator.currentIndex = navigator.currentIndex < navigator.students.length - 1 ? ++navigator.currentIndex : 0;
			navigate();
		}

		function backward() {
			navigator.currentIndex = navigator.currentIndex > 0 ? --navigator.currentIndex : navigator.students.length - 1;
			navigate();
		}

		return navigator;
	}
})();
