(function() {
	'use strict';

	angular.module('web.dropdownMenu').directive('ilDropdownMenu', dropdownMenu);

	dropdownMenu.$inject = [];
	function dropdownMenu() {
		// Usage:
		//
		// Creates:
		//
		var directive = {
			link: link,
			require: '^ilDropdownMenuContainer',
			restrict: 'A'
		};
		return directive;

		function link(scope, element, attrs, ctrl) {
			element.addClass('dropdown');
		}
	}
})();
