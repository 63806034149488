(function() {
	angular.module('test.enablement.organization').controller('TestEnablementOrganizationModalController', controller);

	controller.$inject = ['$scope', '$filter', 'ManagerBulkOperations', 'NotificationFactory'];

	function controller($scope, $filter, ManagerBulkOperations, NotificationFactory) {
		// props
		var ctrl = this;
		ctrl.busy = false;
		ctrl.data = undefined;

		// methods
		ctrl.update = update;
		ctrl.init = init;

		function update() {
			if (ctrl.busy || !ctrl.data.organizations || !ctrl.data.organizations.length) {
				return;
			}

			ctrl.busy = true;

			var items = _.map(ctrl.data.organizations, function(org) {
				var item = {};
				item.Id = org.id;
				item[ctrl.data.setting] = {
					optIn: ctrl.data.enabled
				};
				return item;
			});

			ManagerBulkOperations.patch
				.organizations(items)
				.then(function(results) {
					// var orgNames = _.map(ctrl.data.organizations, 'name').join(', ');
					NotificationFactory.success({
						heading: 'Test setting updated',
						content: [
							$filter('translate')('test_enablement_organization.labels.' + ctrl.data.testWindow),
							ctrl.data.enabled ? 'Enabled' : 'Disabled',
							'for ',
							ctrl.data.organizations.length,
							ctrl.data.organizations.length === 1 ? 'organization' : 'organizations'
							// orgNames
						].join(' '),
						closeAfter: 0
					});
					if (ctrl.data.callback) {
						ctrl.data.callback();
					}
					$scope.closeThisDialog();
				})
				.catch(function(error) {
					ctrl.error = NotificationFactory.error(error);
				})
				.finally(function() {
					ctrl.busy = false;
				});
		}

		function init() {
			ctrl.data = angular.copy($scope.ngDialogData);
			ctrl.headerText =
				ctrl.data.test === 'growth'
					? $filter('translate')('test_enablement.labels.benchmark')
					: $filter('translate')('test_enablement.labels.readingLevel');
		}
		init();
	}
})();
