(function() {
	angular.module('unassigned.students.manager').controller('UnassignedStudentsManagerCtrl', controller);

	controller.$inject = [
		'$q',
		'ngDialog',
		'NotificationFactory',
		'ReportingAPI',
		'ManagerBulkOperations',
		'ApplicationUserService',
		'CurrentDate',
		'PagedList'
	];

	function controller(
		$q,
		ngDialog,
		NotificationFactory,
		ReportingAPI,
		ManagerBulkOperations,
		ApplicationUserService,
		CurrentDate,
		PagedList
	) {
		var $ctrl = this,
			today = CurrentDate.get(),
			yesterday = CurrentDate.get();
		yesterday = new Date(yesterday.setDate(yesterday.getDate() - 1));

		$ctrl.initialized = false;
		$ctrl.allSelected = false;
		$ctrl.studentIds = [];
		$ctrl.maxBatchSize = 200;
		$ctrl.perPage = 20;

		//
		$ctrl.$onInit = onInit;
		$ctrl.$onChanges = onChanges;
		$ctrl.$onDestroy = onDestroy;

		$ctrl.toggleStudent = toggleStudent;
		$ctrl.selectAll = selectAll;
		$ctrl.isSelected = isSelected;
		$ctrl.showModal = showModal;

		function showModal() {
			ngDialog.open({
				className: 'ngdialog-theme-default',
				template: 'features/unassigned.students/unassigned.students.manager/partials/modal.html',
				controller: 'AssignStudentsModalController',
				controllerAs: '$ctrl',
				data: {
					organizationId: $ctrl.organization.id,
					studentIds: $ctrl.studentIds,
					callback: onInit
				}
			});
		}

		function onInit() {
			if (($ctrl.studentList && $ctrl.studentList.busy) || !$ctrl.organization || !$ctrl.organization.id) {
				return;
			}

			$ctrl.initialized = false;
			$ctrl.allSelected = false;
			$ctrl.studentIds = [];

			$ctrl.perPage = $ctrl.studentList && $ctrl.studentList.perPage ? $ctrl.studentList.perPage : $ctrl.perPage;
			var params = {
				id: $ctrl.organization.id,
				startDate: yesterday,
				endDate: today,
				dataFields: ['counts'],
				perPage: $ctrl.perPage,
				clearCache: true
			};

			$ctrl.studentList = new PagedList(function() {
				$ctrl.resource = ReportingAPI.organization.unassignedStudents($ctrl.studentList.params);
				return $ctrl.resource.$promise;
			});
			$ctrl.studentList.params = params;
			$ctrl.studentList.perPage = $ctrl.perPage;
			$ctrl.studentList.callBack = updateSelected;
			$ctrl.studentList.load();

			$ctrl.initialized = true;
			$ctrl.allSelected = false;
		}

		function onChanges(changesObj) {
			if (changesObj.organization && changesObj.organization.currentValue) {
				onInit();
			}
		}

		function onDestroy() {
			if ($ctrl.resource && $ctrl.resource.$cancelRequest) {
				$ctrl.resource.$cancelRequest();
			}
		}

		function isSelected(student) {
			return _.indexOf($ctrl.studentIds, student.id) !== -1;
		}

		function updateSelected() {
			$ctrl.allSelected =
				$ctrl.studentIds &&
				$ctrl.studentIds.length &&
				$ctrl.studentIds.length === ($ctrl.studentList && $ctrl.studentList.items ? $ctrl.studentList.items.length : 0);
		}

		function toggleStudent(student) {
			var index = _.indexOf($ctrl.studentIds, student.id);
			if (index === -1) {
				$ctrl.studentIds.push(student.id);
			} else {
				$ctrl.studentIds.splice(index, 1);
			}
			updateSelected();
		}

		function selectAll() {
			var currentPageIds = _.map($ctrl.studentList.items, 'id'),
				selectedStudents = _.filter($ctrl.studentList.items, function(item) {
					return _.indexOf($ctrl.studentIds, item.id) !== -1;
				});
			if (!selectedStudents.length || selectedStudents.length < currentPageIds.length) {
				$ctrl.studentIds = _.uniq(_.concat($ctrl.studentIds, currentPageIds));
			} else {
				$ctrl.studentIds = _.difference($ctrl.studentIds, currentPageIds);
			}
			updateSelected();
		}
	}
})();
