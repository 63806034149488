(function() {
	const env = window.IL_PORTAL_ENV;

	angular.module('config', []).constant('ENV', env);

	// Check that we don't have a version mis-match
	checkVersion();

	function checkVersion() {
		const REFRESH_STORAGE_KEY = '__IL.Cloud.Portal.Version.LastRefresh';

		// Loop through DOM nodes to find header comment.
		for (let i = 0; i < window.document.childNodes.length; i++) {
			const node = window.document.childNodes[i];
			// Check version from page header comment against version number in config.
			// nodeType of 8 === comment
			if (node?.nodeType === 8 && node?.data) {
				const versionMatch = node.data.match(/portal - v((?:[0-9]+.)\S+)/);
				if (versionMatch) {
					const version = versionMatch[1];
					console.info(`Current application version: v${version}`);
					// If there's a mis-match, refresh the page.
					if (version !== env.version) {
						// Make sure we didn't refresh in the last 30 seconds
						const refreshTime = localStorage.getItem(REFRESH_STORAGE_KEY);
						const lastRefresh = new Date(parseInt(refreshTime || 0)).getTime();
						const currentTime = new Date().getTime();

						if (!lastRefresh || currentTime - lastRefresh > 30000) {
							console.info(`New application version detected: v${env.version}`);
							console.info(`Updating application from version v${version} to ${env.version}`);
							localStorage.setItem(REFRESH_STORAGE_KEY, currentTime);
							window.document.location.reload(true);
						}
					}
					break;
				}
			}
		}
	}
})();
