(function() {
	angular.module('test.window.notification').controller('TestWindowNotificationCtrl', controller);

	controller.$inject = ['$localStorage', 'TestRanges', 'ApplicationUserService', 'Noty', 'NotyThemes', 'CurrentDate'];

	function controller($localStorage, TestRanges, ApplicationUserService, Noty, NotyThemes, CurrentDate) {
		// properties
		this.active = false;
		var id,
			template = [
				'<div class="info-card"><div class="message-icon info"><i class="icon-info-circle"></i></div>',
				'<span class="noty_message">',
				'<div class="title"><strong>You are in an active testing period.</strong></div>',
				// '<span>Note that some figures may appear inaccurate until the testing period has ended.</span><br/>',
				'<label class="right">Always hide this message&nbsp;&nbsp;<input type="checkbox" id="never_show_again"/></label>',
				'</span></div>'
			].join('');

		// lifecycle events
		this.$onInit = onInit;

		function onInit() {
			id = ['test.window.notification.dismiss', this.test, ApplicationUserService.getUser().id].join('_');
			const currentTestWindow = TestRanges.currentTestRange(CurrentDate.get());
			let isTestEnabled = false;
			if (this.test === 'benchmark') {
				switch (currentTestWindow) {
					case 'beginningOfYear':
						isTestEnabled = this.entity?.growthSettingsBoy?.optIn;
						break;
					case 'middleOfYear':
						isTestEnabled = this.entity?.growthSettingsMoy?.optIn;
						break;
					case 'endOfYear':
						isTestEnabled = this.entity?.growthSettingsEoy?.optIn;
						break;
					default:
						break;
				}
			} else {
				switch (currentTestWindow) {
					case 'beginningOfYear':
						isTestEnabled = this.entity?.lexileSettingsBoy?.optIn;
						break;
					case 'middleOfYear':
						isTestEnabled = this.entity?.lexileSettingsMoy?.optIn;
						break;
					case 'endOfYear':
						isTestEnabled = this.entity?.lexileSettingsEoy?.optIn;
						break;
					default:
						break;
				}
			}
			if ($localStorage[id] !== true && isTestEnabled) {
				this.active = true;
				this.noty = new Noty({
					theme: NotyThemes.Information,
					queue: 'test.window.notification.queue',
					container: '.test_window_notification',
					closeWith: ['button'],
					animation: {
						open: 'animated fadeInLeft',
						close: 'animated fadeOutLeft'
					},
					timeout: false,
					type: 'alert',
					text: template
				})
					.on('onClose', function() {
						var checked = angular
							.element(this.barDom)
							.find('input#never_show_again')
							.prop('checked');
						if (checked === true) {
							$localStorage[id] = true;
						}
						this.active = false;
					})
					.show();
			}
		}
	}
})();
