angular
	.module('logout.redirect', ['api.authentication', 'ui.notifications', 'ui.logoutNotifier', 'api.authentication.oidcUserManager'])
	.service('logoutRedirect', service);

service.$inject = ['$rootScope', '$state', '$location', 'AuthenticationService', 'logoutNotifier', 'OidcUserManager'];

function service($rootScope, $state, $location, AuthenticationService, logoutNotifier, OidcUserManager) {
	var svc = this;
	svc.url = undefined;

	svc.onUserAuthenticatedChanged = onUserAuthenticatedChanged;
	svc.stateChangeStart = stateChangeStart;
	svc.init = init;

	function getReturnUrl() {
		return ($location.url() || svc.url).replace('#!', '');
	}

	function startLogin() {
		AuthenticationService.startLogin(getReturnUrl());
	}

	function onUserAuthenticatedChanged(current, last) {
		if (current !== last && current === false) {
			if (!AuthenticationService.isAuthenticated() && !$rootScope.authenticating) {
				logoutNotifier.stop();
				startLogin();
			}
		}
	}

	function stateChangeStart(event, toState, toParams, fromState, fromParams) {
		if (!AuthenticationService.isAuthenticated() && !/signin/.test(toState.name)) {
			event.preventDefault();
			startLogin();
		} else {
			// keep ILAdmins out of reporting
			if (AuthenticationService.isILAdmin() && toState.name) {
				if (
					!toState.name.match(/^ildashboard/gi) && // audit history
					!toState.name.match(/^dashboard.search/gi) && // global search results
					!toState.name.match(/^manager/gi) && // manager drill down views
					!toState.name.match(/^help/gi) &&
					!toState.name.match(/^home/gi)
				) {
					// manager home views
					event.preventDefault();
					$state.go('home.organizations', {}, { notify: true });
				}
			}
		}
	}

	function init(defaultUrl) {
		svc.url = defaultUrl;
		if (AuthenticationService.isAuthenticated()) {
			logoutNotifier.start();
		}
		OidcUserManager.initialize();

		$rootScope.$watch(
			function() {
				return AuthenticationService.isAuthenticated();
			},
			onUserAuthenticatedChanged,
			true
		);

		// user has reached the default url and is not logged in
		$rootScope.$on('$stateChangeStart', stateChangeStart);
	}
}
