(function() {
	angular.module('csv.download.modal').controller('CsvDownloadModalController', controller);

	controller.$inject = ['$log', '$scope', '$rootScope', '$state', 'DateUtils', 'NotificationFactory', 'ReportQueue', 'PendoService'];

	function controller($log, $scope, $rootScope, $state, DateUtils, NotificationFactory, ReportQueue, PendoService) {
		var ctrl = this;
		ctrl.busy = false;
		ctrl.error = false;
		ctrl.submitted = false;
		ctrl.title = _.get($scope, 'ngDialogData.displayText', 'CSV Export');

		ctrl.available = false;
		ctrl.warning = '';
		ctrl.errorMessage = undefined;

		// methods
		ctrl.begin = begin;
		ctrl.done = done;

		function done() {
			$rootScope.$broadcast('csvDownloadDone');
		}

		function begin() {
			ctrl.busy = true;
			var promise;
			if ($scope.ngDialogData.id && $scope.ngDialogData.email) {
				promise = ReportQueue.csvExport.start({
					id: $scope.ngDialogData.id,
					email: $scope.ngDialogData.email
				});
			}

			if (promise) {
				promise
					.then(function(response) {
						if (response && response.errorMessage) {
							ctrl.errorMessage = response.errorMessage;
						}
						ctrl.busy = false;
						ctrl.submitted = true;
						ctrl.title =
							$scope.ngDialogData.completedText && $scope.ngDialogData.completedText.length
								? $scope.ngDialogData.completedText
								: ctrl.title;
						ctrl.errorMessage = response.errorMessage;
					})
					.catch(function(error) {
						$log.error(error);
						ctrl.error = NotificationFactory.error(error);
					})
					.finally(function() {
						ctrl.busy = false;
					});
			} else {
				ctrl.busy = false;
			}
		}
	}
})();
