(function() {
	angular.module('user.account.menu').controller('UserAccountMenuCtrl', controller);

	controller.$inject = [
		'AuthenticationService',
		'ApplicationUserService',
		'NotificationFactory',
		'FormManager',
		'ManagerModalFactory',
		'ModalManager',
		'logoutNotifier',
		'Organization',
		'ILAdmin',
		'User',
		'UserRole',
		'$q',
		'$filter'
	];

	function controller(
		AuthenticationService,
		ApplicationUserService,
		NotificationFactory,
		FormManager,
		ManagerModalFactory,
		ModalManager,
		logoutNotifier,
		Organization,
		ILAdmin,
		User,
		UserRole,
		$q,
		$filter
	) {
		// properties
		var $ctrl = this;

		// lifecycle events
		$ctrl.$onInit = onInit;
		$ctrl.$onChanges = onChanges;

		$ctrl.logOff = logOff;
		$ctrl.editUser = editUser;
		$ctrl.resetPasswordForm = resetPasswordForm;
		$ctrl.saveNewPassword = saveNewPassword;
		$ctrl.close = close;
		$ctrl.showManagementTooltip = showManagementTooltip;
		$ctrl.closeManagementTooltip = closeManagementTooltip;
		$ctrl.managementTooltipProps = undefined;

		function resetScope() {
			$ctrl.changePassword = {
				currentPassword: '',
				password: '',
				confirmPassword: ''
			};

			$ctrl.errorMessage = false;
		}

		resetScope();

		function logOff() {
			logoutNotifier.stop();
			AuthenticationService.logOff();
		}

		function editUser() {
			if ($ctrl.user && $ctrl.user.id) {
				ManagerModalFactory.edit('user', $ctrl.user.id);
			}
		}

		function onChanges(changesObj) {
			if (changesObj.user && changesObj.user.currentValue) {
				onInit();
			}
		}

		function resetPasswordForm(form) {
			FormManager.setPristine(form);
		}

		function close() {
			resetScope();
			ModalManager.hideAll();
		}

		function closeManagementTooltip() {
			const myAccountElement = document.querySelector('[id="my-account-menu-item"]');
			myAccountElement.classList.remove('hover-state');

			$ctrl.managementTooltipProps = undefined;
		}

		function showManagementTooltip() {
			const myAccountElement = document.querySelector('[id="my-account-menu-item"]');
			myAccountElement.classList.add('hover-state');

			$ctrl.managementTooltipProps = {
				anchorObject: myAccountElement,
				isOpen: true,
				onClose: $ctrl.closeManagementTooltip
			};
		}

		function saveNewPassword() {
			var user = ApplicationUserService.getUser();
			if ($ctrl.user.id === user.id) {
				// this just seemed prudent
				AuthenticationService.authenticate(user.username, $ctrl.changePassword.currentPassword).then(function(result) {
					if (result.success) {
						var newUserDetails = {
							id: user.id,
							password: $ctrl.changePassword.password
						};

						if (user.role === UserRole.ilAdmin || user.role === UserRole.ilSiteAdmin) {
							ILAdmin.patch(newUserDetails)
								.then(function() {
									$ctrl.close();
									NotificationFactory.success({
										heading: $filter('translate')('user_account_menu.resetPasswordSuccessHeader'),
										content: $filter('translateFormatString')(
											'user_account_menu.resetPasswordSuccessContent',
											user.username
										),
										closeAfter: 5
									});
								})
								.catch(function(error) {
									NotificationFactory.error(error);
								});
						} else {
							User.patch(newUserDetails)
								.then(function() {
									$ctrl.close();
									NotificationFactory.success({
										heading: $filter('translate')('user_account_menu.resetPasswordSuccessHeader'),
										content: $filter('translateFormatString')(
											'user_account_menu.resetPasswordSuccessContent',
											user.username
										),
										closeAfter: 5
									});
								})
								.catch(function(error) {
									NotificationFactory.error(error);
								});
						}
					} else {
						$ctrl.errorMessage = $filter('translate')('user_account_menu.currentPassIncorrect');
					}
				});
			}
		}

		function onInit() {
			if (!$ctrl.user) {
				return;
			}

			$ctrl.userIsImpersonating = ApplicationUserService.impersonating();
			var userInitials = $ctrl.user.displayName.replace(/\W*(\w)\w*/g, '$1').toUpperCase();
			if (userInitials.length > 2) {
				userInitials = userInitials.substr(0, 1) + userInitials.substr(-1); // _try_ to get first and last initial
			}
			$ctrl.userInitials = $ctrl.userIsImpersonating ? 'IL' : userInitials;
			$ctrl.userInitial = userInitials.substr(0, 1); // get first initial

			//Get a (comma separated) list of the site codes the user is in
			$ctrl.userSiteCodes = '';
			if ($ctrl.user.details && $ctrl.user.details.organizations) {
				var codePromises = [];
				for (var i = 0; i < $ctrl.user.details.organizations.length; i++) {
					codePromises.push(Organization.siteCode($ctrl.user.details.organizations[i].id));
				}

				$q.all(codePromises).then(
					function(siteCodes) {
						$ctrl.userSiteCodes = _.uniq(siteCodes).join(', ');
					},
					function(error) {
						if (error.status !== 404) {
							NotificationFactory.error(error);
						}
					}
				);
			} else {
				$ctrl.userSiteCodes = $ctrl.user.siteCode;
			}
		}
	}
})();
