(function() {
	angular.module('qualifier.filter.visibility', ['utils.date']).service('QualifierFilterVisibilityService', service);

	service.$inject = ['CurrentDate'];

	function service(CurrentDate) {
		var svc = this;

		svc.show = show;
		svc.hide = hide;
		svc.init = init;

		svc.schoolYear = {
			start: 'Aug 1',
			end: 'Jul 31'
		};

		svc.visibilityDates = {
			B: {
				start: 'Aug 1',
				end: 'Jul 31'
			},
			M: {
				start: 'Dec 1',
				end: 'Jul 31'
			},
			E: {
				start: 'Apr 1',
				end: 'Jul 31'
			},
			BM: {
				start: 'Dec 1',
				end: 'Jul 31'
			},
			BME: {
				start: 'Apr 1',
				end: 'Jul 31'
			},
			BE: {
				start: 'Apr 1',
				end: 'Jul 31'
			},
			ME: {
				start: 'Apr 1',
				end: 'Jul 31'
			}
		};

		function init() {
			var currentDate = CurrentDate.get(),
				start,
				end,
				period,
				schoolYear = _getSchoolYear();

			// setup school year

			for (period in svc.visibilityDates) {
				start = new Date(svc.visibilityDates[period]['start'] + ' ' + currentDate.getFullYear());
				end = new Date(svc.visibilityDates[period]['end'] + ' ' + currentDate.getFullYear());

				// setup dates to be properly set to start < end
				if (start.getTime() > end.getTime()) {
					// increment end year
					end = new Date(svc.visibilityDates[period]['end'] + ' ' + (currentDate.getFullYear() + 1));
				}

				// set date to be within schoolYear
				if (start.getTime() < schoolYear.start.getTime()) {
					// bump year up one
					start.setFullYear(start.getFullYear() + 1);
					end.setFullYear(end.getFullYear() + 1);
				}

				if (end.getTime() > schoolYear.end.getTime()) {
					// bump year down one
					start.setFullYear(start.getFullYear() - 1);
					end.setFullYear(end.getFullYear() - 1);
				}

				end.setHours(23, 59, 59);
				svc.visibilityDates[period]['s'] = start;
				svc.visibilityDates[period]['e'] = end;
				svc.visibilityDates[period]['visibility'] = currentDate >= start && currentDate <= end;
			}
		}

		function _getSchoolYear() {
			var currentDate = CurrentDate.get();
			svc.schoolYear.start = new Date(svc.schoolYear.start + ' ' + currentDate.getFullYear());
			svc.schoolYear.end = new Date(svc.schoolYear.end + ' ' + currentDate.getFullYear());

			if (svc.schoolYear.start.getTime() > svc.schoolYear.end.getTime()) {
				// increment end year
				svc.schoolYear.end.setFullYear(svc.schoolYear.end.getFullYear() + 1);
			}

			if (currentDate.getTime() < svc.schoolYear.start.getTime()) {
				// shift back on year
				svc.schoolYear.start.setFullYear(svc.schoolYear.start.getFullYear() - 1);
				svc.schoolYear.end.setFullYear(svc.schoolYear.end.getFullYear() - 1);
			}

			svc.schoolYear.end.setHours(23, 59, 59);
			return svc.schoolYear;
		}

		function show(period) {
			svc.init();
			return svc.visibilityDates[period]['visibility'];
		}

		function hide(period) {
			svc.init();
			return !svc.visibilityDates[period]['visibility'];
		}
	}
})();
