(function() {
	angular
		.module('widgets.group.achievements.list', [
			'lists.common.localization',
			'ui.notifications',
			'ui.router',
			'api.manager',
			'api.reporting',
			'generate.pdf.modal',
			'paged.list',
			'utils.date',
			'date.range.service',
			'helpers.percentages'
		])
		.directive('groupAchievementsListWidget', directive)
		.controller('GroupAchievementsListWidgetCtrl', controller);

	directive.$inject = [];

	function directive() {
		return {
			restrict: 'A',
			scope: {
				group: '=groupAchievementsListWidget',
				widgetTitle: '@',
				dateRange: '=?'
			},
			controller: controller,
			controllerAs: 'model',
			templateUrl: 'features/progress/achievements/group.achievements.list.widget/group.achievements.list.html'
		};
	}

	controller.$inject = [
		'$scope',
		'$state',
		'NotificationFactory',
		'ReportPrinting',
		'schoolYear',
		'DateRangeService',
		'GeneratePdfModal',
		'ReactDependenciesService'
	];

	function controller(
		$scope,
		$state,
		NotificationFactory,
		ReportPrinting,
		schoolYear,
		DateRangeService,
		GeneratePdfModal,
		ReactDependenciesService
	) {
		var model = this;
		model.id = $scope.$id;
		model.initialized = false;
		model.busy = false;
		model.error = false;

		model.student = {};
		model.session = undefined;
		model.dateRange = undefined;

		// methods
		model.refreshModel = refreshModel;
		model.rangeChanged = rangeChanged;
		model.getParam = getParam;
		model.printStudentCertificate = printStudentCertificate;
		model.printGroupCertificates = printGroupCertificates;

		function groupAchievementsDateRangeChanged(dateRange) {
			model.dateRanges = dateRange;
			DateRangeService.updateRangeParams(model.dateRanges, $state);
		}

		function printStudentCertificate(student) {
			GeneratePdfModal.generate(
				ReportPrinting.achievementCertificates.student({
					id: student.id,
					startDate: model.dateRange.start,
					endDate: model.dateRange.end
				}),
				'Generate Certificate',
				undefined,
				[student.firstName, student.lastName, 'Achievements.pdf'].join(' ')
			);
		}

		function printGroupCertificates() {
			GeneratePdfModal.generate(
				ReportPrinting.achievementCertificates.group({
					id: $scope.group.id,
					startDate: model.dateRange.start,
					endDate: model.dateRange.end
				}),
				'Generate Certificates',
				undefined,
				$scope.group.name + ' Achievements.pdf'
			);
		}

		$scope.$watch(
			'group',
			function(value, lastValue) {
				if (value && value.id) {
					refreshModel();
				}
			},
			true
		);

		function getParam(date) {
			return DateRangeService.getDateString(date);
		}

		function rangeChanged() {
			if (model.dateRange && !model.busy && $scope.group && $scope.group.id) {
				refreshModel(model.dateRange);
			}
		}

		function refreshModel(dateRange) {
			if (model.busy) {
				return;
			}

			model.busy = true;
			model.showForm = false;
			model.error = false;
			model.initialized = false;

			schoolYear
				.get($scope.group.organizationId)
				.then(function(dates) {
					model.session = dates;
					$scope.dateRange = dateRange || $scope.dateRange;
					model.last4Weeks = DateRangeService.last4Weeks();
					model.yearToDate = DateRangeService.yearToDate(model.session);
					model.dateRange = $scope.dateRange || model.dateRange || model.yearToDate;
				})
				.catch(function(error) {
					model.error = NotificationFactory.error(error);
				})
				.finally(function() {
					model.initialized = true;
					model.busy = false;
					DateRangeService.updateRangeParams(model.dateRange);
					model.GroupAchievementsProps = {
						config: ReactDependenciesService.apiConfig(),
						id: $scope.group.id,
						onClick: nameClicked,
						onDateRangeChanged: groupAchievementsDateRangeChanged,
						session: model.session,
						dateRange: model.dateRange || $scope.dateRange
					};
				});
		}

		function nameClicked(id) {
			$state.go(
				'dashboard.student.progress.achievements',
				{ id: id, start: model.getParam(model.dateRange.start), end: model.getParam(model.dateRange.end) },
				{ notify: true }
			);
		}
	}
})();
