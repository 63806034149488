(function() {
	angular
		.module('widgets.group.students.list', [
			'lists.common.localization',
			'ui.notifications',
			'ui.testStatuses',
			'api.manager',
			'api.reporting',
			'paged.list',
			'utils.date',
			'utils.gradeLevel',
			'usage.helper.service',
			'helpers.percentages',
			'utils.decamelize'
		])
		.directive('groupStudentsListWidget', directive)
		.controller('GroupStudentsListWidgetCtrl', controller);

	directive.$inject = [];

	function directive() {
		return {
			restrict: 'A',
			scope: {
				group: '=groupStudentsListWidget',
				widgetTitle: '@',
				gradeLevel: '=?',
				dateRange: '=',
				createCallback: '&'
			},
			controller: controller,
			controllerAs: 'model',
			templateUrl: 'components/group.students.list/group.students.list.html',
			link: function(scope, element, attrs) {
				scope.model.espanolUsageView = 'espanolUsageView' in attrs;
				scope.model.annualGrowthView = 'annualGrowthView' in attrs;
				scope.model.growthView = 'growthView' in attrs;
				scope.model.usageView = 'usageView' in attrs;
				scope.model.predictiveUsageView = 'predictiveUsageView' in attrs;
				scope.model.lexileView = 'lexileView' in attrs;
				scope.model.lexileStatusView = 'lexileStatusView' in attrs;
				scope.model.gradeLevelMaterialView = 'gradeLevelMaterialView' in attrs;
				scope.model.progressByLessonView = 'progressByLessonView' in attrs;
				scope.model.kinderReadyView = 'kinderReadyView' in attrs;
				scope.model.portfolioView = 'portfolioView' in attrs;
				scope.model.dashboardView = 'dashboardView' in attrs;
				scope.model.espanolSkillsView = 'espanolSkillsView' in attrs;
			}
		};
	}

	controller.$inject = [
		'$scope',
		'$q',
		'$sce',
		'$filter',
		'NotificationFactory',
		'ReportingAPI',
		'Usage',
		'Class',
		'PagedList',
		'schoolYear',
		'DateUtils',
		'CurrentDate',
		'UsageHelpers',
		'PercentageHelpers',
		'GradeLevel',
		'TestStatusService'
	];

	function controller(
		$scope,
		$q,
		$sce,
		$filter,
		NotificationFactory,
		ReportingAPI,
		Usage,
		Class,
		PagedList,
		schoolYear,
		DateUtils,
		CurrentDate,
		UsageHelpers,
		PercentageHelpers,
		GradeLevel,
		TestStatusService
	) {
		var model = this;
		model.id = $scope.$id;
		model.PercentageHelpers = PercentageHelpers;
		model.reportParams = undefined;
		model.initialized = false;
		model.error = false;

		model.startDateString = '';
		model.endDateString = '';
		model.reviewLesson = {};

		model.student = {};
		model.statuses = TestStatusService.getStatuses();

		//methods
		model.refreshModel = refreshModel;
		model.getFieldName = getFieldName;
		model.absoluteValue = absoluteValue;
		model.isLexileAge = isLexileAge;
		model.exportUrl = exportUrl;
		model.decimalToPercent = decimalToPercent;
		model.getAverageUsageGoal = getAverageUsageGoal;
		model.getAverageUsageMinutes = getAverageUsageMinutes;
		model.getUsageMinutes = getUsageMinutes;
		model.getUsageDataInstanceCount = getUsageDataInstanceCount;
		model.averageOfValues = averageOfValues;
		model.sumOfValues = sumOfValues;
		model.getLessonsCompleted = getLessonsCompleted;
		model.getLessonsPassed = getLessonsPassed;

		function averageOfValues(valueArray, valueName) {
			if (!valueArray || !valueArray.length) {
				return 0;
			}

			var values = _.map(valueArray, valueName);
			var sum = _.sum(values);
			return sum ? sum / valueArray.length : 0;
		}

		function sumOfValues(valueArray, valueName) {
			if (!valueArray || !valueArray.length) {
				return 0;
			}

			var values = _.map(valueArray, valueName);
			var sum = _.sum(values);
			return sum ? sum : 0;
		}

		function getUsageMinutes(student) {
			var tmp,
				values,
				sum,
				minutes = 0;
			if (model.espanolUsageView) {
				minutes = _.get(student, 'usage.totalUsage');
				minutes = minutes > 0 ? minutes / 60 : 0;
			} else {
				tmp =
					model.usageMetric === 'dailyUsage'
						? _.get(student, 'usage.dailyUsage.days')
						: _.get(student, 'usage.weeklyUsage.weeks');

				if (tmp) {
					values = _.map(tmp, 'seconds');
					sum = _.sum(values);
					minutes = sum ? sum / 60 : 0;
				}
			}
			return Math.round(minutes);
		}

		function getUsageDataInstanceCount(student) {
			if (model.espanolUsageView) {
				return _.get(student, 'usage.activePeriods', 0);
			} else {
				return UsageHelpers.getUsageDataInstanceCount(student, model.usageMetric);
			}
		}

		function getAverageUsageMinutes(student) {
			if (model.espanolUsageView) {
				var seconds = _.get(student, 'usage.averageUsage', 0);
				return seconds ? seconds / 60 : 0;
			} else {
				return UsageHelpers.getStudentAverageUsageMinutes(student, model.usageMetric, model.reportDivisor);
			}
		}

		function getAverageUsageGoal(student) {
			return UsageHelpers.getAverageUsageGoal(student, model.usageMetric);
		}

		function exportUrl() {
			if (_.isEmpty(model.reportParams)) {
				return '';
			}
			return $sce.trustAsResourceUrl(ReportingAPI.exportUrls.group.students(model.reportParams));
		}

		function isLexileAge(student) {
			return GradeLevel.getGradeLevelNumber(student && student.gradeLevel ? student.gradeLevel : 'Other') > 1;
		}

		function absoluteValue(value) {
			return value && angular.isString(value) ? Math.abs(Number(value)) : value && angular.isNumber(value) ? Math.abs(value) : '--';
		}

		var resource;
		model.studentList = new PagedList(function() {
			if (model.espanolUsageView) {
				resource = Usage.groupStudents(model.studentList.params, true);
			} else {
				resource = ReportingAPI.group.students;
			}
			return resource.$promise;
		});

		$scope.$watch(
			'group',
			function(value, oldValue) {
				if (value && value.id) {
					refreshModel();
				}
			},
			true
		);

		$scope.$watch(
			'dateRange',
			function(value) {
				if (value && $scope.group && $scope.group.id) {
					refreshModel();
				}
			},
			true
		);

		$scope.$watch(
			'gradeLevel',
			function(value, last) {
				if (value) {
					refreshModel();
				}
			},
			true
		);

		function getFieldName(text) {
			return $filter('translate')(text);
		}

		function decimalToPercent(value) {
			if (!angular.isNumber(value)) {
				return '--';
			}
			return Math.round(value * 100) + '%';
		}

		function refreshModel() {
			model.error = false;

			var promise;

			if ($scope.dateRange && $scope.dateRange.start && angular.isDate($scope.dateRange.start)) {
				promise = $q.when($scope.dateRange);
			} else {
				promise = schoolYear.get($scope.group.organizationId);
			}

			if (model.lexileView) {
				model.dataFields = ['lexile'];
			} else if (model.lexileStatusView) {
				model.dataFields = ['lexileTestStatuses'];
			} else if (model.growthView) {
				model.dataFields = ['performance'];
			} else if (model.annualGrowthView) {
				model.dataFields = ['benchmarkTests'];
			} else if (model.usageView) {
				model.dataFields = ['counts'];
			} else if (model.predictiveUsageView) {
				model.dataFields = ['counts'];
			} else if (model.gradeLevelMaterialView) {
				model.dataFields = ['gradeLevelMaterial'];
			} else if (model.progressByLessonView) {
				model.dataFields = ['gradeLevelMaterial'];
			} else if (model.kinderReadyView) {
				model.dataFields = ['kindergartenReady'];
			} else if (model.portfolioView) {
				model.additionalFields = ['portalArtifacts'];
			} else if (model.dashboardView) {
				model.dataFields = ['progress'];
			} else if (model.espanolSkillsView) {
				model.dataFields = ['progress'];
			}

			promise
				.then(function(dates) {
					var reportRange = DateUtils.daysInRange(dates.start, dates.end);

					model.usageMetric = model.predictiveUsageView
						? 'yearToDateUsage'
						: model.usageView || model.espanolUsageView
						? reportRange > 15
							? 'weeklyUsage'
							: 'dailyUsage'
						: model.dashboardView
						? 'dailyUsage'
						: undefined;

					model.reportDivisor =
						model.usageMetric === 'dailyUsage' ? DateUtils.weekdaysInRange(dates.start, dates.end) : Math.ceil(reportRange / 7);

					if (model.espanolUsageView) {
						model.reportParams = {
							id: $scope.group.id,
							grade:
								!$scope.gradeLevel || ($scope.gradeLevel && $scope.gradeLevel.toLowerCase() === 'all')
									? undefined
									: $scope.gradeLevel,
							start: dates.start,
							end: dates.end && dates.end.getTime() > CurrentDate.get().getTime() ? CurrentDate.get() : dates.end,
							sortby: 'lastName,firstName',
							intervalSize: model.usageMetric
						};

						model.studentList.params.id = model.reportParams.id;
						model.studentList.params.grade = model.reportParams.grade;
						model.studentList.params.start = model.reportParams.start;
						model.studentList.params.end = model.reportParams.end;
						model.studentList.params.intervalSize = model.reportParams.intervalSize;
					} else {
						if (model.usageMetric) {
							model.dataFields.push(model.usageMetric);
						}
						if (model.dashboardView || model.usageView) {
							model.dataFields.push('reviewLesson');
						}

						model.reportParams = {
							grade: model.kinderReadyView
								? 'PreK'
								: !$scope.gradeLevel || ($scope.gradeLevel && $scope.gradeLevel.toLowerCase() === 'all')
								? undefined
								: $scope.gradeLevel,
							id: $scope.group.id,
							startDate: dates.start,
							endDate: dates.end && dates.end.getTime() > CurrentDate.get().getTime() ? CurrentDate.get() : dates.end,
							sortby: 'lastName,firstName',
							dataFields: model.dataFields,
							additionalFields: model.additionalFields
						};

						if (model.portfolioView) {
							model.studentList = new PagedList(Class.students, model.reportParams.id);
							model.studentList.params.sortby = model.reportParams.sortby;
							model.studentList.params.additionalFields = model.reportParams.additionalFields || undefined;
						} else {
							model.studentList = new PagedList(ReportingAPI.group.students);
							model.studentList.params.id = model.reportParams.id;
							model.studentList.params.grade = model.reportParams.grade;
							model.studentList.params.startDate = model.reportParams.startDate;
							model.studentList.params.endDate = model.reportParams.endDate;
							model.studentList.params.sortby = model.reportParams.sortby;
							model.studentList.params.dataFields = model.reportParams.dataFields;
						}
					}

					model.startDateString = $filter('date')(model.reportParams.startDate, 'MMMM d, y');
					model.endDateString = $filter('date')(model.reportParams.endDate, 'MMMM d, y');

					model.studentList.callBack = onStudentListLoaded;
					model.studentList.perPage = model.gradeLevelMaterialView && $scope.group.studentCount ? $scope.group.studentCount : 30;
					model.studentList.load();
				})
				['catch'](function(error) {
					model.error = NotificationFactory.error(error);
				})
				['finally'](function() {
					model.initialized = true;
					model.busy = false;
				});
		}

		function getLessonsCompleted(item) {
			return _.get(item, 'progress.completed', 0);
		}

		function getLessonsPassed(item) {
			return _.get(item, 'progress.passed', 0);
		}

		function onStudentListLoaded() {
			var showGuidedReview = false;
			if (model.studentList.items && model.studentList.items.length > 0) {
				showGuidedReview = model.studentList.items.some(function(item) {
					return !!item.reviewLesson && item.reviewLesson.remainingSeconds > 0;
				});
				if (!!showGuidedReview) {
					model.studentList.items = model.studentList.items.map(function(item) {
						if (!!item.reviewLesson) {
							item.reviewLesson.remainingSeconds = !!item.reviewLesson.remainingSeconds
								? item.reviewLesson.remainingSeconds / 60
								: 0;
						}
						return item;
					});

					model.studentList.items = model.studentList.items.map(function(item) {
						if (!!item.reviewLesson) {
							item.reviewLesson.estimatedTotalSeconds = !!item.reviewLesson.estimatedTotalSeconds
								? item.reviewLesson.estimatedTotalSeconds / 60
								: 0;
						}
						return item;
					});
				}
			}
			!!showGuidedReview ? $scope.createCallback({ showGuidedReview }) : angular.noop();
		}
	}
})();
