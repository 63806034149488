(function() {
	angular
		.module('kpi.organization.assessment.status', ['ui.notifications', 'utils.featureFlipper', 'manager.modal.factory', 'api.manager'])
		.directive('organizationAssessmentStatusKpi', directive)
		.controller(controller);

	directive.$inject = [];

	function directive() {
		return {
			restrict: 'A',
			scope: {
				id: '=organizationAssessmentStatusKpi',
				displayText: '@',
				widgetTitle: '@'
			},
			controller: controller,
			controllerAs: 'model',
			templateUrl: 'components/organization.assessment.status.kpi/organization.assessment.status.html',
			link: function(scope, element, attrs) {}
		};
	}

	controller.$inject = ['$scope', 'NotificationFactory', 'Organization', 'ManagerModalFactory'];

	function controller($scope, NotificationFactory, Organization, ManagerModalFactory) {
		var model = this;
		model.initialized = false;
		model.organization = {};
		model.busy = true;
		model.error = false;

		model.refreshModel = refreshModel;
		model.edit = edit;

		function edit() {
			ManagerModalFactory.edit('organization', model.organization.id, ['assessments']);
		}

		$scope.$watch(
			'id',
			function(value, oldValue) {
				if (value) {
					refreshModel(value);
				} else {
					model.organization = undefined;
				}
			},
			true
		);

		function refreshModel(id) {
			model.busy = true;
			model.error = false;
			model.initialized = false;
			Organization.get(id, {
				additionalFields: [
					'lexileSettingsBoy',
					'lexileSettingsMoy',
					'lexileSettingsEoy',
					'growthSettingsBoy',
					'growthSettingsMoy',
					'growthSettingsEoy'
				]
			})
				.then(function(result) {
					model.organization = result || {};
				})
				['catch'](function(error) {
					model.error = NotificationFactory.error(error);
				})
				['finally'](function() {
					model.busy = false;
					model.initialized = true;
				});
		}

		$scope.$on('OrganizationAssessmentStatusRefresh', function() {
			refreshModel($scope.id);
		});
	}
})();
