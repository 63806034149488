(function() {
	angular.module('entity.view.model', ['api.manager', 'ui.notifications']).factory('EntityViewModel', factory);

	factory.$inject = ['NotificationFactory', 'Organization', 'Class', 'User', 'Student'];

	function factory(NotificationFactory, Organization, Class, User, Student) {
		// param: overrides - object containing override methods for
		// prototype functions: dirty, valid, refresh, update, cancel
		var EntityViewModel = function(entity, keys, overrides) {
			this.entity = entity;
			this.keys = keys;
			this.overrides = overrides;

			this.base = undefined;
			this.viewModel = undefined;

			this.busy = false;
			this.error = false;

			this.refresh();
		};

		EntityViewModel.prototype = {
			dirty: function() {
				if (this.overrides.dirty && angular.isFunction(this.overrides.dirty)) {
					return this.overrides.dirty();
				} else {
					return !angular.equals(this.base, this.viewModel);
				}
			},

			// valid only has value as an override, there is no way
			// to make valid a generic state to cover any model value
			valid: function() {
				if (this.overrides.valid && angular.isFunction(this.overrides.valid)) {
					return this.overrides.valid();
				}
			},

			refresh: function() {
				var entityViewModel = this;

				if (entityViewModel.overrides.refresh && angular.isFunction(entityViewModel.overrides.refresh)) {
					entityViewModel.overrides.refresh();
				} else {
					if (entityViewModel.entity && entityViewModel.keys) {
						entityViewModel.base = {};
						entityViewModel.viewModel = {};

						entityViewModel.base.id = entityViewModel.viewModel.id = entityViewModel.entity.id;
						_.map(entityViewModel.keys, function(key) {
							entityViewModel.base[key] = entityViewModel.entity[key];
						});

						entityViewModel.viewModel = angular.copy(entityViewModel.base);
					} else if (entityViewModel.entity && !entityViewModel.keys) {
						entityViewModel.base = entityViewModel.viewModel = entityViewModel.entity;
					}
				}
			},

			update: function() {
				var entityViewModel = this;
				entityViewModel.error = false;
				entityViewModel.busy = true;

				if (entityViewModel.overrides.preupdate && angular.isFunction(entityViewModel.overrides.preupdate)) {
					entityViewModel.overrides.preupdate();
				}

				if (entityViewModel.overrides.update && angular.isFunction(entityViewModel.overrides.update)) {
					return entityViewModel.overrides
						.update(entityViewModel.viewModel)
						.then(function() {
							_.map(entityViewModel.keys, function(key) {
								entityViewModel.entity[key] = entityViewModel.viewModel[key];
							});
							entityViewModel.base = angular.copy(entityViewModel.viewModel);

							if (entityViewModel.overrides.success && angular.isFunction(entityViewModel.overrides.success)) {
								entityViewModel.overrides.success();
							}
						})
						['catch'](function(error) {
							entityViewModel.error = NotificationFactory.error(error);
						})
						['finally'](function() {
							entityViewModel.busy = false;

							if (entityViewModel.overrides.callback && angular.isFunction(entityViewModel.overrides.callback)) {
								entityViewModel.overrides.callback();
							}
						});
				}
			},

			cancel: function() {
				if (this.overrides.cancel && angular.isFunction(this.overrides.cancel)) {
					this.overrides.cancel();
				} else {
					this.refresh();
				}
			}
		};

		return EntityViewModel;
	}
})();
