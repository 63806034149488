(function() {
	angular
		.module('widgets.user.settings', [
			'settings.common.localization',
			'ui.notifications',
			'ui.manager.multiselect',
			'api.manager',
			'entity.view.model',
			'utils.date',
			'api.application.user'
		])
		.directive('userSettingsWidget', directive)
		.controller('UserSettingsWidgetController', controller);

	directive.$inject = [];

	function directive() {
		return {
			restrict: 'A',
			scope: {
				id: '=userSettingsWidget',
				mode: '@',
				callback: '&',
				sections: '=',
				closeDialog: '&'
			},
			controller: controller,
			controllerAs: 'model',
			templateUrl: 'components/user.settings/user.settings.html',
			link: function(scope, element, attrs) {}
		};
	}

	controller.$inject = [
		'$scope',
		'$filter',
		'NotificationFactory',
		'User',
		'Class',
		'EntityViewModel',
		'managerAPIHelpers',
		'ApplicationUserService',
		'UsernameService'
	];

	function controller(
		$scope,
		$filter,
		NotificationFactory,
		User,
		Class,
		EntityViewModel,
		managerAPIHelpers,
		ApplicationUserService,
		UsernameService
	) {
		var model = this;
		model.sections = $scope.sections;
		model.fullUserEntity = undefined;

		model.busy = false;
		model.error = false;
		model.synced = false; // is this record updated by a scheduled sync
		model.initialized = false;
		model.formInvalid = false;

		model.userTypes = [
			{
				text: $filter('translate')('forms_common.teacher'),
				value: 'Teacher'
			},
			{
				text: $filter('translate')('forms_common.administrator'),
				value: 'Admin'
			}
		];

		model.isAdmin = ApplicationUserService.isAdmin();
		model.user = undefined;

		// public methods
		model.refreshModel = refreshModel;
		model.isDirty = isDirty;
		model.isInvalid = isInvalid;
		model.saveAll = saveAll;
		model.validateUsername = validateUsername;

		$scope.$watchGroup(
			[
				'model.detailsForm.$invalid',
				'model.credentialsForm.$invalid',
				'model.organizationsForm.$invalid',
				'model.groupsForm.$invalid',
				'model.userTypeForm.$invalid'
			],
			function(newValue, oldValue) {
				model.formInvalid = model.isInvalid();
			}
		);

		function doCallback() {
			if ($scope.callback) {
				$scope.callback();
			}
			$scope.$emit('dashboard.reload');
		}

		$scope.$watch(
			'id',
			function(value, oldValue) {
				if (value) {
					refreshModel(value);
				}
			},
			true
		);

		function refreshModel(id) {
			if (model.busy) {
				return;
			}

			model.busy = true;
			model.error = model.initialized = false;

			User.get(id)
				.then(function(results) {
					model.user = results;
					model.user.password = '';
					model.synced = managerAPIHelpers.isEntitySynced(model.user);

					model.fullUserEntity = new EntityViewModel(
						model.user,
						['firstName', 'lastName', 'username', 'password', 'userType', 'organizationIds', 'groupIds'],
						{
							preupdate: function() {
								if (!model.fullUserEntity.viewModel.password || !model.fullUserEntity.viewModel.password.length) {
									delete model.fullUserEntity.viewModel.password;
								}
							},
							update: User.update,
							postupdate: function() {
								if (!model.fullUserEntity.viewModel.password) {
									model.fullUserEntity.viewModel.password = '';
								}
							},
							callback: function() {
								delete model.fullUserEntity.viewModel.groupIds;
								doCallback();
							},
							cancel: function() {
								model.organizations.refresh();
								model.groups.refresh();
							},
							dirty: function() {
								var orig = model.fullUserEntity.base;
								var vm = model.fullUserEntity.viewModel;

								if (angular.equals(orig, vm)) {
									return false;
								}

								if (!angular.equals(orig.id, vm.id)) {
									return true;
								}

								orig.groupIds = orig.groupIds ? orig.groupIds.sort() : [];
								orig.organizationIds = orig.organizationIds ? orig.organizationIds.sort() : [];

								vm.groupIds = vm.groupIds ? vm.groupIds.sort() : [];
								vm.organizationIds = vm.organizationIds ? vm.organizationIds.sort() : [];

								if (!angular.equals(orig, vm)) {
									return true;
								}

								return false;
							}
						}
					);

					model.fullUserEntity.refresh();

					return Class.classesById(model.user.groupIds);
				})
				.then(function(results) {
					if (results && results.items && results.items.length) {
						model.user.groups = results.items;
					}
				})
				['catch'](function(error) {
					model.user = undefined;
					model.error = NotificationFactory.error(error);
				})
				['finally'](function() {
					model.busy = false;
					model.initialized = true;
				});
		}

		function saveAll() {
			model.fullUserEntity
				.update()
				.catch(function(error) {
					model.error = NotificationFactory.error(error);
				})
				.finally(function() {
					if ($scope.closeDialog) {
						$scope.closeDialog();
					}
				});
		}

		function isInvalid() {
			return (
				(model.detailsForm ? model.detailsForm.$invalid : false) ||
				(model.credentialsForm ? model.credentialsForm.$invalid : false) ||
				(model.organizationsForm ? model.organizationsForm.$invalid : false) ||
				(model.groupsForm ? model.groupsForm.$invalid : false) ||
				(model.userTypeForm ? model.userTypeForm.$invalid : false)
			);
		}

		function isDirty() {
			return model.fullUserEntity && model.fullUserEntity.dirty ? model.fullUserEntity.dirty() : false;
		}

		function validateUsername() {
			if (
				model.credentialsForm &&
				model.fullUserEntity.viewModel.username &&
				model.user.username &&
				model.user.username.toLowerCase() !== model.fullUserEntity.viewModel.username.toLowerCase()
			) {
				UsernameService.checkUserAvailability(model.fullUserEntity.viewModel.username).then(
					function(result) {
						model.credentialsForm.username.$setValidity(
							'unique',
							result.usernames.indexOf(model.fullUserEntity.viewModel.username.toLowerCase()) < 0
						);
					},
					function(error) {
						NotificationFactory.error(error);
					}
				);
			}
		}
	}
})();
