(function() {
	var root = this;
	var translations = {
		en: {
			create_student_choice_modal: {
				stepByStepHeader: 'Create Students',
				stepByStepDescription: 'Select this option to manually create students.',
				importHeader: 'Import Students',
				importDescription:
					"Select this option if you're comfortable uploading and manually manipulating rows in a comma separated value (CSV) file to accurately represent information. You will be guided step-by-step throughout the process. "
			}
		}
	};

	if (root.angular) {
		root.angular.module('create.student.choice.modal').config(config);
	} else if (typeof module !== 'undefined' && module.exports) {
		module.exports = translations;
	}

	config.$inject = ['LocalizeProvider'];

	function config(LocalizeProvider) {
		LocalizeProvider.translations(translations);
	}
})();
