(function() {
	angular.module('create.students.modal').controller('CreateStudentsModalController', controller);

	controller.$inject = [
		'$scope',
		'$window',
		'$q',
		'GUID',
		'Localize',
		'ManagerAPI',
		'GradeLevel',
		'ManagerBulkOperations',
		'NotificationFactory',
		'ApplicationUserService'
	];

	function controller(
		$scope,
		$window,
		$q,
		GUID,
		Localize,
		ManagerAPI,
		GradeLevel,
		ManagerBulkOperations,
		NotificationFactory,
		ApplicationUserService
	) {
		var ctrl = this,
			defaultLanguage = 'English',
			uniqueFields = { STUDENT_ID: 'tag', USER_NAME: 'username' };
		ctrl.titleText = $scope.ngDialogData.titleText || Localize.translateInstant('create_students_modal.titleText');
		ctrl.organization = $scope.ngDialogData.organization;
		ctrl.group = $scope.ngDialogData.group;
		ctrl.callback = $scope.ngDialogData.callback;
		ctrl.gradeLevels = GradeLevel.getList();

		ctrl.students = [];
		ctrl.showDemographics = false;
		ctrl.usernameTracker = {};

		//
		ctrl.init = init;
		ctrl.isValid = isValid;
		ctrl.addNewStudent = addNewStudent;
		ctrl.deleteStudent = deleteStudent;
		ctrl.updateSchoolDetails = updateSchoolDetails;
		ctrl.createStudents = createStudents;
		ctrl.isUnique = isUnique;
		ctrl.getSchool = getSchool;
		ctrl.getPortalSettings = getPortalSettings;
		ctrl.showDemographicsButton = showDemographicsButton;
		ctrl.onAUniqueFieldChanged = onAUniqueFieldChanged;

		function onAUniqueFieldChanged(index, field) {
			if (field) {
				isValid(field);
			}
		}

		function isUnique(student, fieldName) {
			let valArray = Object.values(ctrl.usernameTracker).filter(
				tracker => student[fieldName] !== undefined && tracker.targetUsername === student[fieldName]
			);
			return valArray.length === 1 ? true : false;
		}

		function verifyUsernameIsUniqInList(student, index, fieldName) {
			if (ctrl.students.length > 1) {
				var existing = _.map(ctrl.students, fieldName).map(str => (str !== undefined ? str : undefined)),
					existingInList = _.filter(existing, function(n) {
						return n && n.length && n === ctrl.usernameTracker[student.id].targetUsername;
					});

				if (existingInList && existingInList.length > 1) {
					student[`${fieldName}_error`] = Localize.translateInstant(`create_students_modal.${fieldName}ExistsInListError`);
					ctrl.usernameTracker[student.id].isUniq = !(existingInList && existingInList.length > 1);
				} else {
					ctrl.usernameTracker[student.id].isUniq = true;
				}

				if (ctrl['create_student_form'] && ctrl['create_student_form'][`${fieldName}_${index}`]) {
					ctrl['create_student_form'][`${fieldName}_${index}`].$setValidity(
						`${fieldName}Uniq`,
						ctrl.usernameTracker[student.id].isUniq
					);
				}
			} else {
				ctrl.usernameTracker[student.id].isUniq = true;
			}
		}

		function verifyUsernameIsUniqInOrganization(student, index, fieldName) {
			if (ctrl.usernameTracker[student.id].isUniq) {
				if (fieldName === uniqueFields.USER_NAME) {
					ApplicationUserService.getUser().organizationIds.forEach(function(org) {
						ManagerAPI.Student.checkAvailability(org, [ctrl.usernameTracker[student.id].targetUsername]).then(function(
							response
						) {
							ctrl.usernameTracker[student.id].isUniq = !(response && response.length && response[0].id !== undefined);
							showError(student, fieldName, index);
						});
					});
				} else if (fieldName === uniqueFields.STUDENT_ID) {
					let usrSearchOptions = new Object();
					usrSearchOptions[fieldName] = ctrl.usernameTracker[student.id].targetUsername;

					ManagerAPI.Student.search(usrSearchOptions).then(function(response) {
						ctrl.usernameTracker[student.id].isUniq = !(response.items && response.items.length);
						showError(student, fieldName, index);
					});
				}
			}
		}

		function showError(student, fieldName, index) {
			if (!ctrl.usernameTracker[student.id].isUniq) {
				student[`${fieldName}_error`] = Localize.translateInstant(`create_students_modal.${fieldName}ExistsInOrganizationError`);
			}

			if (ctrl['create_student_form'] && ctrl['create_student_form'][`${fieldName}_${index}`]) {
				ctrl['create_student_form'][`${fieldName}_${index}`].$setValidity(
					`${fieldName}Uniq`,
					ctrl.usernameTracker[student.id].isUniq
				);
			}
		}

		function verifyUsernameIsUniq(student, index, fieldName) {
			if (!student || !student[fieldName] || !student[fieldName].length) {
				return true;
			}

			ctrl.usernameTracker[student.id] = ctrl.usernameTracker[student.id]
				? ctrl.usernameTracker[student.id]
				: {
						targetUsername: '',
						isUniq: true
				  };

			ctrl.usernameTracker[student.id].targetUsername = student[fieldName];

			verifyUsernameIsUniqInList(student, index, fieldName);

			verifyUsernameIsUniqInOrganization(student, index, fieldName);
		}

		function isValid(fieldName) {
			var valid = true;
			var errors = null;

			_.forEach(ctrl.students, function(student, index) {
				delete student[`${fieldName}_error`];

				verifyUsernameIsUniq(student, index, fieldName);

				if (
					!ctrl.isUnique(student, fieldName) ||
					!student[fieldName] ||
					!student[fieldName].length ||
					!student.school ||
					!student.school.id
				) {
					valid = false;
				}
			});

			return valid;
		}

		function updateSchoolDetails(student) {
			student.schoolIds = [student.school.id];
			student.groupIds = [];
		}

		function addNewStudent() {
			if (ctrl.students.length > 199) {
				return;
			}

			var school =
				ctrl.school !== undefined
					? ctrl.school
					: ctrl.students.length
					? _.get(ctrl.students[ctrl.students.length - 1], 'school')
					: undefined;
			var group =
				ctrl.group !== undefined
					? ctrl.group
					: ctrl.students.length
					? _.get(ctrl.students[ctrl.students.length - 1], 'group')
					: undefined;
			var gradeLevel = ctrl.students.length ? _.get(ctrl.students[ctrl.students.length - 1], 'gradeLevel') : undefined;
			var student = {
				id: GUID.generate(),
				firstName: '',
				lastName: '',
				username: '',
				tag: '',
				alternateStudentNumber: '',
				school: school,
				gradeLevel: gradeLevel || '',
				password: '',
				schoolIds: school ? [school.id] : [],
				groups: group ? [group] : [],
				groupIds: group ? [group.id] : []
			};

			ctrl.usernameTracker[student.id] = {
				targetUsername: '',
				isUniq: true
			};

			ctrl.students.push(student);
		}

		function deleteStudent(student) {
			var index = _.findIndex(ctrl.students, function(g) {
				return g.id === student.id;
			});
			if (index !== -1) {
				delete ctrl.usernameTracker[ctrl.students[index].id];
				ctrl.students.splice(index, 1);
			}

			Object.values(uniqueFields).forEach(field => isValid(field));
		}

		function updateCreatedStudentDetails(students) {
			ctrl.busy = true;
			var ids = _.map(students, 'id');
			return ManagerAPI.Student.studentsById(ids, { additionalFields: ['tag', 'alternateStudentNumber', 'groups', 'demographics'] })
				.then(function(results) {
					ctrl.createdStudents = results && results.items ? results.items : [];
					var orgIds = _(ctrl.createdStudents)
						.flatMap('organizationIds')
						.uniq()
						.value();
					return ManagerAPI.Organization.orgsById(orgIds);
				})
				.then(function(organizations) {
					_.forEach(ctrl.createdStudents, function(student) {
						student.organization = _.find(organizations && organizations.items ? organizations.items : [], function(
							organization
						) {
							return _.indexOf(student.organizationIds, organization.id) !== -1;
						});
						student.groups =
							_.find(ctrl.students, function(s) {
								return s.id === student.id;
							}).groups || [];
					});
				})
				.finally(function() {
					ctrl.busy = false;
				});
		}

		function updateFailedStudentDetails(students) {
			var ids = _.map(students, 'id');

			ctrl.failedStudents = _.filter(ctrl.students, function(student) {
				return _.indexOf(ids, student.id) !== -1;
			});

			ctrl.failedStudents = _.map(ctrl.failedStudents, function(student) {
				var failedStudent = _.find(students, function(failed) {
					return failed.id === student.id;
				});
				student.error = failedStudent.error;
				student.status = failedStudent.status;

				return student;
			});
		}

		function createStudents() {
			ctrl.busy = true;
			var newStudents = [];
			_.forEach(ctrl.students, function(student) {
				newStudents.push({
					Id: student.id,
					firstName: student.firstName,
					lastName: student.lastName,
					gradeLevel: student.gradeLevel,
					password: student.password,
					username: student.username,
					tag: student.tag && !_.isEmpty(student.tag) ? student.tag : undefined,
					alternateStudentNumber:
						student.alternateStudentNumber && !_.isEmpty(student.alternateStudentNumber)
							? student.alternateStudentNumber
							: undefined,
					sessionTime: 20,
					firstLanguage: defaultLanguage,
					writtenLanguage: defaultLanguage,
					organizationIds: [student.school.id],
					groupIds: student.groupIds.length ? student.groupIds : undefined,
					demographics: student.demographics || []
				});
			});

			ManagerBulkOperations.createOrUpdate
				.students(newStudents)
				.then(function(response) {
					return response && response.results && response.results.length
						? updateCreatedStudentDetails(response.results)
						: $q.when();
				})
				.catch(function(response) {
					var bulkJobResult = _.get(response, 'data.results', undefined),
						created = [],
						failed = [];

					if (bulkJobResult) {
						_.forEach(bulkJobResult, function(result) {
							var status = _.get(result, 'status', undefined);
							if (status && status.match(/failed/i)) {
								failed.push(result);
							}
							if (status && status.match(/created/i)) {
								created.push(result);
							}
						});

						if (failed.length) {
							updateFailedStudentDetails(failed);
						}

						if (created.length) {
							updateCreatedStudentDetails(created);
						}
					} else {
						NotificationFactory.error(error);
					}
				})
				.finally(function() {
					ctrl.busy = false;

					if (ctrl.callback) {
						ctrl.callback();
					}
				});
		}

		function getSchool() {
			if (ctrl.organization && ctrl.organization.id) {
				return ManagerAPI.Organization.get(ctrl.organization.id)
					.then(function(result) {
						ctrl.school = result;
					})
					.finally(function() {
						addNewStudent();
					});
			} else {
				return ManagerAPI.Organization.quickList()
					.then(function(results) {
						ctrl.schools =
							results && results.length
								? _.filter(results, function(organization) {
										return organization.organizationType.toLowerCase() === 'school';
								  })
								: [];
						ctrl.schools = _.orderBy(ctrl.schools, ['name', 'asc']);
						ctrl.school = ctrl.schools && ctrl.schools.length === 1 ? ctrl.schools[0] : undefined;
					})
					.finally(function() {
						addNewStudent();
					});
			}
		}

		function getPortalSettings() {
			if (ctrl.organization && ctrl.organization.id) {
				return ManagerAPI.Organization.getPortalSettings(ctrl.organization.id).then(function(results) {
					ctrl.showDemographics = results.showDemographics;
				});
			} else {
				var organizationIds = ApplicationUserService.getUser().organizationIds;

				//TODO: do we need to check all the orgIds for showDemographics?
				return ManagerAPI.Organization.getPortalSettings(organizationIds[0]).then(function(results) {
					ctrl.showDemographics = results.showDemographics;
				});
			}
		}

		function showDemographicsButton() {
			if (ApplicationUserService.isAdmin()) {
				return ctrl.showDemographics;
			} else {
				return false;
			}
		}

		function init() {
			ctrl.busy = true;

			$q.all([ctrl.getSchool(), ctrl.getPortalSettings()]).then(function(result) {
				ctrl.busy = false;
			});
		}

		init();
	}
})();
