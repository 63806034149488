(function() {
	var root = this;
	var translations = {
		en: {
			terms_common: {}
		}
	};

	if (root.angular) {
		root.angular.module('settings.common.localization', ['utils.localization']).config(config);
	} else if (typeof module !== 'undefined' && module.exports) {
		// Node.js
		module.exports = translations;
	}

	config.$inject = ['LocalizeProvider'];

	function config(LocalizeProvider) {
		LocalizeProvider.translations(translations);
	}
})();
