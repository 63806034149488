(function() {
	angular.module('paged.list').directive('ilPagedListControls', directive);

	directive.$inject = ['$anchorScroll'];

	function directive($anchorScroll) {
		return {
			restrict: 'A',
			templateUrl: 'components/paged.list/controls.html',
			replace: true,
			scope: {
				language: '=?',
				pagedList: '=ilPagedListControls',
				perPage: '=?',
				autoScroll: '='
			},
			link: function(scope, elem, attrs) {
				scope.lite = 'lite' in attrs;

				scope.scroll = scroll;

				scope.$watch(
					function() {
						return scope.language;
					},
					function(value, last) {
						if (value /* !== last*/) {
							scope.pagedList.language = value;
							scope.pagedList.updatePageDisplayText();
						}
					},
					true
				);

				scope.$watch(
					function() {
						return scope.pagedList && scope.pagedList.perPage ? scope.pagedList.perPage : -1;
					},
					function(value) {
						if (value !== -1) {
							scope.perPage = value;
						}
					},
					true
				);

				function scroll() {
					if (scope.autoScroll) {
						$anchorScroll();
					}
				}
			}
		};
	}
})();
