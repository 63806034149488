/*
	pill style list of applied filters to accompany a search/filter input

sample usage:

 <input type="search"
	 ng-model="ctrl.searchTerm"
	 ng-enter="ctrl.filterString = ctrl.searchTerm; ctrl.executeSearchNow();"
	 placeholder="This is an example"/>

 <div il-filter-pills
	 filter-string="ctrl.filterString"
	 search-string="ctrl.searchTerm"
	 on-update="ctrl.executeSearchNow();">
 </div>

*/

(function() {
	angular.module('filter.pills').directive('ilFilterPills', directive);

	directive.$inject = [];

	function directive() {
		return {
			restrict: 'A',
			replace: true,
			templateUrl: 'components/filter.pills/template.html',
			controller: 'FilterPillsController',
			controllerAs: 'ctrl',
			bindToController: true,
			scope: {
				onUpdate: '&', // if needed, bind to a search/filter update method. this is executed when filters are removed
				searchString: '=', // bind this value to a search/filter input ng-model value, it is updated when filers are removed
				filterString: '=' // this value populates the filter pills. update this value when performing a new search
			}
		};
	}
})();
