(function() {
	angular.module('growth.qualifier.filter').service('GrowthQualifierFilter', service);

	service.$inject = [
		'$q',
		'$cookieStore',
		'$filter',
		'CookieNames',
		'CurrentDate',
		'TestRanges',
		'QualifierFilterVisibilityService',
		'ApplicationUserService'
	];

	function service(
		$q,
		$cookieStore,
		$filter,
		CookieNames,
		CurrentDate,
		TestRanges,
		QualifierFilterVisibilityService,
		ApplicationUserService
	) {
		var vm = this,
			closestRange = TestRanges.closestTestRange(CurrentDate.get()),
			currentUser = ApplicationUserService.getUser();

		vm.nullValue = 'qualifiedTestsB';
		vm.currentQualifier = vm.nullValue;
		vm.qualifiers = [
			// {
			// 	'label': 'No Filter',
			// 	'value': 'None'
			// },
			{
				label: 'Beginning',
				value: 'qualifiedTestsB',
				isDisabled: !$filter('featureIsEnabled')('growth.filterVisibility') ? false : QualifierFilterVisibilityService.hide('B')
			},
			{
				label: 'Middle',
				value: 'qualifiedTestsM',
				isDisabled: !$filter('featureIsEnabled')('growth.filterVisibility')
					? closestRange === 'beginningOfYear'
					: QualifierFilterVisibilityService.hide('M')
			},
			// {
			// 	'label': 'End',
			// 	'value': 'qualifiedTestsE',
			// 	'isDisabled': closestRange === 'beginningOfYear' || closestRange === 'middleOfYear'
			// },
			{
				label: 'Beginning and Middle',
				value: 'qualifiedTestsBM',
				isDisabled: !$filter('featureIsEnabled')('growth.filterVisibility')
					? closestRange === 'beginningOfYear'
					: QualifierFilterVisibilityService.hide('BM')
			},
			{
				label: 'Beginning and End',
				value: 'qualifiedTestsBE',
				isDisabled: !$filter('featureIsEnabled')('growth.filterVisibility')
					? closestRange === 'beginningOfYear' || closestRange === 'middleOfYear'
					: QualifierFilterVisibilityService.hide('BE')
			},
			{
				label: 'Middle and End',
				value: 'qualifiedTestsME',
				isDisabled: !$filter('featureIsEnabled')('growth.filterVisibility')
					? closestRange === 'beginningOfYear' || closestRange === 'middleOfYear'
					: QualifierFilterVisibilityService.hide('ME')
			},
			{
				label: 'Beginning, Middle and End',
				value: 'qualifiedTestsBME',
				isDisabled: !$filter('featureIsEnabled')('growth.filterVisibility')
					? closestRange === 'beginningOfYear' || closestRange === 'middleOfYear'
					: QualifierFilterVisibilityService.hide('BME')
			}
		];

		vm.getCurrentQualifier = getCurrentQualifier;
		vm.setCurrentQualifier = setCurrentQualifier;

		function setCurrentQualifier(qualifier) {
			vm.currentQualifier = qualifier;
			$cookieStore.put(CookieNames.growth.filterQualifier + '.' + currentUser.id, qualifier);
		}

		function getCurrentQualifier() {
			var qualifier = currentUser ? $cookieStore.get(CookieNames.growth.filterQualifier + '.' + currentUser.id) : undefined;
			if (!qualifier || !qualifier.length) {
				setCurrentQualifier(vm.nullValue);
				qualifier = $cookieStore.get(CookieNames.growth.filterQualifier + '.' + currentUser.id);
			}
			vm.currentQualifier = qualifier;
			return $cookieStore.get(CookieNames.growth.filterQualifier + '.' + currentUser.id, qualifier);
		}
	}
})();
