(function() {
	angular
		.module('widgets.student.settings', [
			'settings.common.localization',
			'ui.notifications',
			'ui.manager.multiselect',
			'ui.manager.multiple.select',
			'api.manager',
			'api.oralLanguage',
			'utils.gradeLevel',
			'entity.view.model',
			'utils.date',
			'api.demographics',
			'api.application.user',
			'product.names'
		])
		.directive('studentSettingsWidget', directive)
		.controller('StudentSettingsWidgetController', controller);

	directive.$inject = [];

	function directive() {
		return {
			restrict: 'A',
			scope: {
				id: '=studentSettingsWidget',
				mode: '@',
				callback: '&',
				sections: '=',
				closeDialog: '&'
			},
			controller: controller,
			controllerAs: 'model',
			templateUrl: 'components/student.settings/student.settings.html',
			link: function(scope, element, attrs) {}
		};
	}

	controller.$inject = [
		'$scope',
		'OralLanguage',
		'NotificationFactory',
		'Student',
		'EntityViewModel',
		'managerAPIHelpers',
		'GradeLevel',
		'DemographicsSettings',
		'ApplicationUserService',
		'$q',
		'User',
		'Organization',
		'ILAdmin',
		'Class',
		'Localize',
		'UsernameService',
		'DistrictStudentIdService',
		'ApiProducts'
	];

	function controller(
		$scope,
		OralLanguage,
		NotificationFactory,
		Student,
		EntityViewModel,
		managerAPIHelpers,
		GradeLevel,
		DemographicsSettings,
		ApplicationUserService,
		$q,
		User,
		Organization,
		ILAdmin,
		Class,
		Localize,
		UsernameService,
		DistrictStudentIdService,
		ApiProducts
	) {
		var model = this;
		model.apiProducts = ApiProducts;
		model.sections = $scope.sections;
		model.selectedOrganizations = [];
		model.fullStudentEntity = undefined;
		model.tempPassword = '';
		model.selectedGroups = [];
		model.gradeLevels = GradeLevel.getList();

		model.languages = undefined;
		model.espanolSupportLanguages = [
			{ label: 'English', value: 'English' },
			{ label: 'Spanish (Default)', value: 'Spanish' }
		];
		model.audioSupportOptions = [
			{ value: false, label: 'No' },
			{ value: true, label: 'Yes' }
		];
		model.busy = false;
		model.error = false;
		model.synced = false; // is this record updated by a scheduled sync
		model.initialized = false;

		model.formInvalid = false;
		model.student = undefined;
		model.allowedDemographics = [];
		model.isTeacher = ApplicationUserService.getUser().userRole.toLowerCase() === 'teacher';
		model.forceUpdateGroups = false;
		model.outOfScopeGroupIds = [];
		model.outOfScopeOrgIds = [];
		model.hasProductSettings = false;

		// public methods
		model.refreshModel = refreshModel;
		model.saveAll = saveAll;
		model.isInvalid = isInvalid;
		model.isDirty = isDirty;
		model.toggleDemographic = toggleDemographic;
		model.showProductSettings = showProductSettings;
		model.setDefaultEspanolLanguageSupport = setDefaultEspanolLanguageSupport;
		model.validateUsernameInOrganziations = validateUsernameInOrganziations;
		model.validateStudentIdInOrganziations = validateStudentIdInOrganziations;

		model.uniqueFields = { STUDENT_ID: 'tag', USER_NAME: 'username' };

		$scope.$watch(
			'id',
			function(value, oldValue) {
				if (value) {
					refreshModel(value);
				}
			},
			true
		);

		$scope.$watchGroup(
			[
				'model.detailsForm.$invalid',
				'model.academicsForm.$invalid',
				'model.credentialsForm.$invalid',
				'model.organizationsForm.$invalid',
				'model.groupsForm.$invalid',
				'model.demographicsForm.$invalid',
				'model.productSettingsForm.$invalid'
			],
			function(newValue, oldValue) {
				model.formInvalid = model.isInvalid();
			}
		);

		$scope.$watchCollection('model.fullStudentEntity.viewModel.organizationIds', validateUniqueFields, true);

		function showProductSettings(product) {
			const user = ApplicationUserService.getUser();
			const userProducts = _.get(user, 'products', []);
			const userHasProduct = !!userProducts.includes(product);
			const studentProducts = _.get(model, 'fullStudentEntity.viewModel.products', []);
			const studentHasProduct = !!studentProducts.includes(product);
			return userHasProduct && studentHasProduct;
		}

		var usersOrgIds = [];

		function doCallback() {
			if ($scope.callback) {
				$scope.callback();
			}
			$scope.$emit('dashboard.reload');
		}

		function refreshModel(id) {
			if (model.busy) {
				return;
			}

			model.busy = true;
			model.error = model.initialized = false;
			OralLanguage.query()
				.then(function(results) {
					model.languages = _.sortBy(results, function(result) {
						return result === 'English' ? 0 : result;
					});

					// return User.organizations(ApplicationUserService.getUser().id);
					// PTL-3408
					return ApplicationUserService.isIlAdmin()
						? ILAdmin.organizations(ApplicationUserService.getUser().id)
						: User.organizations(ApplicationUserService.getUser().id);
				})
				.then(function(results) {
					// Get the user's orgs and descendant orgs so we can determine any "out of scope" orgs the student belongs to that need to be preserved.
					usersOrgIds = results && results.items ? _.map(results.items, 'id') : [];

					var childOrgPromises = [];
					angular.forEach(usersOrgIds, function(orgId) {
						childOrgPromises.push(Organization.children(orgId, { includeDescendants: true, limit: -1 }));
					});

					return $q.all(childOrgPromises);
				})
				.then(function(results) {
					angular.forEach(results, function(result) {
						if (result && result.items) {
							usersOrgIds = _.union(usersOrgIds, _.map(result.items, 'id'));
						}
					});

					return Student.get(id, {
						additionalFields: [
							'lexileSettings',
							'growthSettings',
							'groups',
							'products',
							'espanolLanguage',
							'demographics',
							'tags',
							'tag',
							'alternateStudentNumber',
							'audioSupport',
							'lecturaAudioSupport'
						]
					});
				})
				.then(function(results) {
					model.student = results;

					return getSiteCodes(results.organizationIds).then(function(siteCodes) {
						model.student.siteCodes = siteCodes;
						return $q.all(
							model.student.organizationIds.map(function(orgId) {
								return DemographicsSettings.get(orgId);
							})
						);
					});
				})
				.then(function(results) {
					for (var i = 0; i < results.length; i++) {
						model.allowedDemographics = _.union(model.allowedDemographics, results[i].allowedDemographics);
					}

					model.showDemographics = results
						.map(function(settingsResult) {
							return settingsResult.showDemographics;
						})
						.some(function(show) {
							return show;
						});

					model.student.demographics = model.student.demographics || [];

					return Class.classesById(model.student.groupIds);
				})
				.then(function(results) {
					// IL-15047 and CS-906
					// We can no longer trust the difference between student.groupIds and student.groups to determine out of scope group ids
					if (results && results.items) {
						var inScopeGroupIds = _.map(results.items, 'id');
						model.outOfScopeGroupIds = _.difference(model.student.groupIds, inScopeGroupIds);
					}

					model.outOfScopeOrgIds = _.filter(model.student.organizationIds, function(id) {
						return !_.includes(usersOrgIds, id);
					});

					setDefaultEspanolLanguageSupport();

					// is org on a sync schedule
					model.synced = managerAPIHelpers.isEntitySynced(model.student);
					model.fullStudentEntity = new EntityViewModel(
						model.student,
						[
							'firstName',
							'lastName',
							'tag',
							'gradeLevel',
							'tag',
							'alternateStudentNumber',
							'username',
							'password',
							'organizationIds',
							'groupIds',
							'sessionTime',
							'firstLanguage',
							'espanolLanguage',
							'products',
							'demographics',
							'audioSupport',
							'lecturaAudioSupport'
						],
						{
							dirty: function() {
								if (model.tempPassword) {
									return true;
								}

								if (angular.equals(model.fullStudentEntity.base, model.fullStudentEntity.viewModel)) {
									return false;
								}

								var orig = model.fullStudentEntity.base;
								var vm = model.fullStudentEntity.viewModel;

								if (!angular.equals(orig.id, vm.id)) {
									return true;
								}

								// Compare all but org ids and group ids
								var baseCopy = angular.copy(model.fullStudentEntity.base);
								var vmCopy = angular.copy(model.fullStudentEntity.viewModel);
								baseCopy.organizationIds = [];
								baseCopy.groupIds = [];
								vmCopy.organizationIds = [];
								vmCopy.groupIds = [];

								if (!angular.equals(baseCopy, vmCopy)) {
									return true;
								}

								// Compare orgs
								// Trying to remove an out of scope org id makes the request fail
								var origOrgs = orig.organizationIds ? orig.organizationIds.sort() : [];
								var vmOrgs = vm.organizationIds ? _.union(vm.organizationIds, model.outOfScopeOrgIds).sort() : [];

								if (!angular.equals(origOrgs, vmOrgs)) {
									return true;
								}

								// Compare groups
								var origGroups = orig.groupIds ? orig.groupIds.sort() : [];
								var vmGroups = vm.groupIds ? _.union(vm.groupIds, model.outOfScopeGroupIds).sort() : [];

								if (!angular.equals(origGroups, vmGroups)) {
									return true;
								}

								return false;
							},
							preupdate: function() {
								model.fullStudentEntity.viewModel.organizationIds = _.union(
									model.fullStudentEntity.viewModel.organizationIds,
									model.outOfScopeOrgIds
								);
								model.fullStudentEntity.viewModel.groupIds = _.union(
									model.fullStudentEntity.viewModel.groupIds,
									model.outOfScopeGroupIds
								);
								model.fullStudentEntity.viewModel.alternateStudentNumber =
									model.fullStudentEntity.viewModel.alternateStudentNumber === ''
										? null
										: model.fullStudentEntity.viewModel.alternateStudentNumber;
								model.fullStudentEntity.viewModel.password = model.tempPassword
									? model.tempPassword
									: model.fullStudentEntity.viewModel.password;
								if (model.fullStudentEntity.viewModel.password?.length === 0) {
									delete model.fullStudentEntity.viewModel.password;
								}
							},
							update: Student.update,
							cancel: function() {
								model.fullStudentEntity.refresh();
							},
							callback: doCallback
						}
					);

					model.fullStudentEntity.refresh();
				})
				['catch'](function(error) {
					model.student = undefined;
					model.error = NotificationFactory.error(error);
				})
				['finally'](function() {
					model.hasProductSettings =
						model.showProductSettings(model.apiProducts.ILE) ||
						model.showProductSettings(model.apiProducts.Spanish) ||
						model.showProductSettings(model.apiProducts.Reader) ||
						model.showProductSettings(model.apiProducts.Enyay) ||
						model.showProductSettings(model.apiProducts.Lectura);
					model.busy = false;
					model.initialized = true;
				});
		}

		function saveAll() {
			model.fullStudentEntity
				.update()
				.catch(function(error) {
					model.error = NotificationFactory.error(error);
				})
				.finally(function() {
					if ($scope.closeDialog) {
						$scope.closeDialog();
					}
				});
		}

		function isInvalid() {
			return (
				(model.detailsForm ? model.detailsForm.$invalid : false) ||
				(model.academicsForm ? model.academicsForm.$invalid : false) ||
				(model.credentialsForm ? model.credentialsForm.$invalid : false) ||
				(model.organizationsForm ? model.organizationsForm.$invalid : false) ||
				(model.groupsForm ? model.groupsForm.$invalid : false) ||
				(model.demographicsForm ? model.demographicsForm.$invalid : false) ||
				(model.productSettingsForm ? model.productSettingsForm.$invalid : false)
			);
		}

		function isDirty() {
			return model.fullStudentEntity && model.fullStudentEntity.dirty ? model.fullStudentEntity.dirty() : false;
		}

		function toggleDemographic(demographic) {
			var index = model.fullStudentEntity.viewModel.demographics.indexOf(demographic);
			if (index === -1) {
				model.fullStudentEntity.viewModel.demographics.push(demographic);
			} else {
				model.fullStudentEntity.viewModel.demographics.splice(index, 1);
			}
		}

		function setDefaultEspanolLanguageSupport() {
			if (
				model.student &&
				model.student.products &&
				model.student.products.indexOf('Spanish') !== -1 &&
				!model.student.espanolLanguage
			) {
				model.student.espanolLanguage = 'Spanish';
			}
		}

		function validateUniqueFields() {
			validateUsernameInOrganziations();
			validateStudentIdInOrganziations();
		}

		function validateUsernameInOrganziations() {
			if (model['credentialsForm'] && model['credentialsForm'][model.uniqueFields.USER_NAME]) {
				model['credentialsForm'][model.uniqueFields.USER_NAME].$setValidity(`${model.uniqueFields.USER_NAME}Uniq`, true);
			}
			if (model && model.fullStudentEntity) {
				model.fullStudentEntity.viewModel.username_error = undefined;
			}
			if (
				model.credentialsForm &&
				model.fullStudentEntity.viewModel.username &&
				model.student.username &&
				Array.isArray(model.fullStudentEntity.viewModel.organizationIds) &&
				(model.student.username.toLowerCase() !== model.fullStudentEntity.viewModel.username.toLowerCase() ||
					_.difference(model.fullStudentEntity.viewModel.organizationIds, model.student.organizationIds).length !== 0)
			) {
				model.busy = true;
				getSiteCodes(model.fullStudentEntity.viewModel.organizationIds).then(function(result) {
					siteCodes = result;
					if (model.student.username === model.fullStudentEntity.viewModel.username) {
						siteCodes = _.difference(result, model.student.siteCodes);
					}
					$q.all(
						siteCodes.map(function(siteCode) {
							return UsernameService.checkStudentAvailability(model.fullStudentEntity.viewModel.username, siteCode);
						})
					)
						.then(function(results) {
							var errorSiteCodes = [];
							results.forEach(function(result, index) {
								if (
									result.usernames.length > 0 &&
									result.usernames.indexOf(model.fullStudentEntity.viewModel.username.toLowerCase()) >= 0
								) {
									errorSiteCodes.push(siteCodes[index]);
								}
							});
							if (errorSiteCodes.length > 0) {
								model.fullStudentEntity.viewModel.isUniq = false;
								showFieldError(model.fullStudentEntity, model.uniqueFields.USER_NAME, 'credentialsForm');
							}
						})
						.catch(function(error) {
							NotificationFactory.error(error);
						})
						.finally(function() {
							model.busy = false;
						});
				});
			}
		}

		function validateStudentIdInOrganziations() {
			if (model['academicsForm'] && model['academicsForm'][model.uniqueFields.STUDENT_ID]) {
				model['academicsForm'][model.uniqueFields.STUDENT_ID].$setValidity(`${model.uniqueFields.STUDENT_ID}Uniq`, true);
			}
			if (model && model.fullStudentEntity) {
				model.fullStudentEntity.viewModel.tag_error = undefined;
			}
			if (
				model.academicsForm &&
				model.fullStudentEntity.viewModel.tag &&
				model.student.tag &&
				Array.isArray(model.fullStudentEntity.viewModel.organizationIds) &&
				(model.student.tag !== model.fullStudentEntity.viewModel.tag ||
					_.difference(model.fullStudentEntity.viewModel.organizationIds, model.student.organizationIds).length !== 0)
			) {
				model.busy = true;
				getSiteCodes(model.fullStudentEntity.viewModel.organizationIds).then(function(result) {
					siteCodes = result;
					if (model.student.tag === model.fullStudentEntity.viewModel.tag) {
						siteCodes = _.difference(result, model.student.siteCodes);
					}
					$q.all(
						siteCodes.map(function(siteCode) {
							return DistrictStudentIdService.checkAvailability(model.fullStudentEntity.viewModel.tag, siteCode);
						})
					)
						.then(function(results) {
							var errorSiteCodes = [];
							results.forEach(function(result, index) {
								if (result && result.length > 0) {
									errorSiteCodes.push(siteCodes[index]);
								}
							});
							if (errorSiteCodes.length > 0) {
								model.fullStudentEntity.viewModel.isUniq = false;
								showFieldError(model.fullStudentEntity, model.uniqueFields.STUDENT_ID, 'academicsForm');
							}
						})
						.catch(function(error) {
							NotificationFactory.error(error);
						})
						.finally(function() {
							model.busy = false;
						});
				});
			}
		}

		function showFieldError(student, fieldName, formName) {
			if (!student.viewModel.isUniq) {
				student.viewModel[`${fieldName}_error`] = Localize.translateInstant(
					`create_students_modal.${fieldName}ExistsInOrganizationError`
				);
			}

			if (model[formName] && model[formName][fieldName]) {
				model[formName][fieldName].$setValidity(`${fieldName}Uniq`, student.viewModel.isUniq);
			}
		}

		function getSiteCodes(organizationIds) {
			return $q.all(
				organizationIds.map(function(organization) {
					return Organization.siteCode(organization);
				})
			);
		}
	}
})();
