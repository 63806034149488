(function() {
	var root = this;
	var translations = {
		en: {
			legacy_browser: {
				unsupportedBrowser: 'Unsupported Browser',
				unsupportedBrowserMessage:
					'Activity Explorer is only available on Google Chrome. If you are using a different browser, please switch to Google Chrome to find and play activities.',
				unsupportedBrowserClose: 'Close'
			}
		}
	};

	if (root.angular) {
		root.angular.module('legacy.browser.modal').config(config);
	} else if (typeof module !== 'undefined' && module.exports) {
		module.exports = translations;
	}

	config.$inject = ['LocalizeProvider'];

	function config(LocalizeProvider) {
		LocalizeProvider.translations(translations);
	}
})();
