(function() {
	angular.module('growth.view.filter').service('GrowthViewFilter', service);

	service.$inject = ['$cookieStore', 'CookieNames', 'ApplicationUserService'];

	function service($cookieStore, CookieNames, ApplicationUserService) {
		var vm = this,
			currentUser = ApplicationUserService.getUser(),
			views = [
				{
					label: 'Status',
					value: 'status'
				},
				{
					label: 'Gains',
					value: 'gains'
				},
				{
					label: 'Performance',
					value: 'performance'
				}
			];

		vm.defaultValue = 'status';
		vm.currentView = vm.defaultValue; // default value
		vm.views = views;

		vm.setViews = setViews;
		vm.getViews = getViews;
		vm.getCurrentView = getCurrentView;
		vm.setCurrentView = setCurrentView;

		function setViews(views) {
			vm.views = views;
		}

		function setCurrentView(growthView) {
			vm.currentView = growthView;
			$cookieStore.put(CookieNames.growth.filterView + '.' + currentUser.id, growthView);
		}

		function getCurrentView() {
			var growthView = currentUser ? $cookieStore.get(CookieNames.growth.filterView + '.' + currentUser.id) : undefined;
			if (!growthView || growthView === '') {
				setCurrentView(vm.defaultValue);
				growthView = $cookieStore.get(CookieNames.growth.filterView + '.' + currentUser.id);
			}
			vm.currentView = growthView;
			return $cookieStore.get(CookieNames.growth.filterView + '.' + currentUser.id, growthView);
		}

		function getViews() {
			return vm.views;
		}
	}
})();
