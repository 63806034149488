import * as q from 'qrious';
window.QRious = q;

(function() {
	angular.module('web.qrcode', []).service('QRCode', service);

	service.$inject = [];

	function service() {
		var vm = this;

		vm.generate = generate;

		function generate(str) {
			if (typeof QRious === 'undefined') {
				throw new Error('QRious requires https://github.com/neocotic/qrious');
			}
			var qr = new QRious({
				value: str,
				size: 108
			});
			return qr.toDataURL('image/jpeg');
		}
	}
})();
